import { TauxFixeScheduleLine } from "Domain/report.tauxfixe";
import { TauxFixeScheduleLineDto } from "Domain/reportDto.tauxfixe";
import { toDateOnly, toJSDate } from "Utilities/date";


function mapEntityToDto(entity: TauxFixeScheduleLine): TauxFixeScheduleLineDto {
	const dto: TauxFixeScheduleLineDto = {
		periodNumber: entity.periodNumber,
		paymentDate: toDateOnly(entity.paymentDate),
		fixedCoupon: entity.fixedCoupon
	};
	return dto;
}
function mapDtoToEntity(dto: TauxFixeScheduleLineDto): TauxFixeScheduleLine {
	const entity: TauxFixeScheduleLine = {
		periodNumber: dto.periodNumber,
		paymentDate: toJSDate(dto.paymentDate)!,
		fixedCoupon: dto.fixedCoupon
	};
	return entity;
}
export const TauxFixeScheduleLineMappers = {
	mapDtoToEntity,
	mapEntityToDto,
};
