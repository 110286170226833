
import { reportStatusMapper } from './reportStatusMapper';
import { reportTypeMapper } from './reportTypeMapper';

import { validationResultMapper } from './validationResultMapper';
import { reportDocumentTypeMapper } from './reportDocumentTypeMapper';
import { DateTime } from 'luxon';
import { ReportInfoItem } from 'Domain/report';
import { ReportInfoItemDto } from 'Domain/reportDto';

function mapDtoToEntity(dto: ReportInfoItemDto): ReportInfoItem {
	return {
		id: dto.id,
		name: dto.name,
		isin: dto.isin,
		tradeReference: dto.tradeReference,
		type: reportTypeMapper.mapDtoToEntity(dto.type),
		creationDate: DateTime.fromISO(dto.creationDate).toJSDate(),
		processingDate: dto.processingEndDate ? DateTime.fromISO(dto.processingEndDate).toJSDate() : undefined,
		status: reportStatusMapper.mapDtoToEntity(dto.status),
		errors: validationResultMapper.mapDtosToEntities(dto.errors || []),
		availableDocuments: dto.availableDocuments.map(reportDocumentTypeMapper.mapDtoToEntity)
	};
}

function mapDtosToEntities(dtos: ReportInfoItemDto[]): ReportInfoItem[] {
	return dtos.map(mapDtoToEntity);
}

export const baseReportMapper = {
	mapDtoToEntity,
	mapDtosToEntities,
};
