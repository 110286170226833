import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	PhoenixBearishReportConfiguration,
	PhoenixBearishTransversalConfiguration,
} from "Domain/report.phoenixBearish";
import { assign, cloneDeep, groupBy, NumericDictionary, range, reduce } from "lodash";
import * as immer from "immer";
import { Schema } from "yup";
import { validateModel } from "Helpers/validationHelpers";
import { LoadingState } from "Models/LoadingState";
import { FieldConfigurationFromModel } from "components/Shared/FormControls/FieldConfiguration";
import { getPhoenixBearishFormFieldConfiguration } from "./getPhoenixBearishFormFieldConfiguration";
import { getPhoenixBearishValidationSchema } from "./getPhoenixBearishValidationSchema";
import { createDefaultPhoenixBearishReport } from "./createDefaultPhoenixBearishReport";
import { computeDuration } from "Mappers/PhoenixBearish/phoenixBearishTransversalConfigurationMapper";
import { ReportInfoItem } from "Domain/report";
import { PhoenixBearishScheduleLine } from "Domain/reportDto.phoenixBearish";

interface EditReportConfigurationValuePayload<
	TReportConfigKey extends keyof PhoenixBearishReportConfiguration,
	TFieldKey extends keyof PhoenixBearishReportConfiguration[TReportConfigKey],
> {
	contextKey: TReportConfigKey;
	field: TFieldKey;
	value: PhoenixBearishReportConfiguration[TReportConfigKey][TFieldKey];
}

export type PhoenixBearishState = {
	formState: PhoenixBearishFormState;
	initialReport: PhoenixBearishReportConfiguration;
	currentReport: PhoenixBearishReportConfiguration;
	brochureLoadingState: LoadingState;
	tradeReference: string | undefined;
};

export type PhoenixBearishReportValidationSchema = Schema<PhoenixBearishReportConfiguration>;

export type PhoenixBearishFormState = {
	fieldConfiguration: FieldConfigurationFromModel<PhoenixBearishReportConfiguration>;
	validationSchema: PhoenixBearishReportValidationSchema;
	validationState: Record<string, string>;
};

const defaultState: PhoenixBearishState = {
	formState: {
		fieldConfiguration: getPhoenixBearishFormFieldConfiguration(),
		validationSchema: getPhoenixBearishValidationSchema(),
		validationState: {},
	},
	initialReport: createDefaultPhoenixBearishReport(),
	currentReport: createDefaultPhoenixBearishReport(),
	brochureLoadingState: LoadingState.Idle,
	tradeReference: undefined,
};

export const editValueCreatorHelper = <
	TReportConfigKey extends keyof PhoenixBearishReportConfiguration,
	TFieldKey extends keyof PhoenixBearishReportConfiguration[TReportConfigKey],
>(
	key: TReportConfigKey,
	field: TFieldKey,
	value: PhoenixBearishReportConfiguration[TReportConfigKey][TFieldKey],
) => {
	const payload: EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey> = {
		contextKey: key,
		field,
		value,
	};
	return PhoenixBearishSlice.actions.editValue(payload as any);
};

const PhoenixBearishSlice = createSlice({
	initialState: defaultState,
	name: "phoenixBearishReport",
	reducers: {
		editValue: <
			TReportConfigKey extends keyof PhoenixBearishReportConfiguration,
			TFieldKey extends keyof PhoenixBearishReportConfiguration[TReportConfigKey],
		>(
			state: immer.Draft<PhoenixBearishState>,
			action: PayloadAction<EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey>>,
		) => {
			const { contextKey, field, value } = action.payload;

			if (state.currentReport) {
				state.currentReport[contextKey][field] = value;

				if (contextKey === "transversalConfiguration") {
					const typedField = field as keyof PhoenixBearishReportConfiguration["transversalConfiguration"];
					const durationToComputeKeys: Array<keyof PhoenixBearishTransversalConfiguration> = [
						"issueDate",
						"maturityDate",
					];

					if (durationToComputeKeys.includes(field as keyof PhoenixBearishTransversalConfiguration)) {
						state.currentReport.transversalConfiguration.productDuration = computeDuration(
							state.currentReport.transversalConfiguration.issueDate,
							state.currentReport.transversalConfiguration.maturityDate,
						);
					}

					const fieldsThatRecomputeSchedule: Array<keyof PhoenixBearishTransversalConfiguration> = [
						"autocallBarrier",
						"observationStartPeriod",
						"couponBarrier",
						"unconditionalCoupon",
						"conditionalCoupon",
						"isAutocallProgressive",
						"progressivityStep",
						"progressivityPeriod",
						"numberOfUnconditionalCoupons",
					];

					if (fieldsThatRecomputeSchedule.includes(typedField)) {
						state.currentReport.transversalConfiguration.scheduleLines = recomputeScheduleLines(
							immer.current(state.currentReport.transversalConfiguration),
						);
					}
				}
			}
		},
		initReport: (state) => {},
		initReportSuccess: (
			state,
			action: PayloadAction<{
				reportConfiguration: PhoenixBearishReportConfiguration;
				tradeReference: string | undefined;
			}>,
		) => {
			state.initialReport = cloneDeep(action.payload.reportConfiguration);
			state.currentReport = cloneDeep(action.payload.reportConfiguration);
			state.formState.validationState =
				validateModel(
					state.currentReport,
					state.formState.validationSchema as any as Schema<PhoenixBearishReportConfiguration>,
				) || {};
			state.tradeReference = action.payload.tradeReference;
		},
		generateBrochure: (state) => {
			const validationState = validateModel(
				state.currentReport,
				state.formState.validationSchema as any as Schema<PhoenixBearishReportConfiguration>,
			);
			if (validationState) {
				state.formState.validationState = validationState;
			} else {
				state.formState.validationState = {};
				state.brochureLoadingState = LoadingState.Pending;
			}
		},
		generateBrochureSuccess: (state) => {
			state.brochureLoadingState = LoadingState.Resolved;
		},
		generateBrochureError: (state) => {
			state.brochureLoadingState = LoadingState.Error;
		},
		resetReport: (state) => {
			state.currentReport = cloneDeep(state.initialReport);
			state.formState.validationState = {};
		},
		downloadBrochure: (
			_state,
			_action: PayloadAction<{ report: ReportInfoItem; success?: () => void; error?: () => void }>,
		) => {},
	},
});

function createAutocallBarrierSerie(
	totalPeriods: number,
	observationStartPeriod: number,
	autocallBarrier: number,
	progressivityStep: number | undefined,
	progressivityPeriodicity: number | undefined,
): NumericDictionary<number | undefined> {
	const autocalls = range(1, totalPeriods + 1).map((period) => {
		let autocallForPeriod: number | undefined = autocallBarrier;
		if (period < observationStartPeriod || period === totalPeriods) {
			autocallForPeriod = undefined;
		} else {
			if (progressivityStep && progressivityPeriodicity) {
				const a = period - observationStartPeriod;
				if (a > 0 && Math.floor(a / progressivityPeriodicity) > 0) {
					autocallForPeriod = autocallBarrier + Math.floor(a / progressivityPeriodicity) * progressivityStep;
				}
			} else {
				autocallForPeriod = autocallBarrier;
			}
		}
		return { period, autocall: autocallForPeriod };
	});

	const result: NumericDictionary<number | undefined> = {};

	return reduce(
		autocalls,
		(acc, val) => {
			acc[val.period] = val.autocall;
			return acc;
		},
		result,
	);
}

function createConditionalCouponSerie(
	totalPeriods: number,
	numberOfUnconditionalCoupons: number,
	conditionalCoupon: number,
): NumericDictionary<number | undefined> {
	const conditionalCoupons = range(1, totalPeriods + 1).map((period) => {
		let couponForPeriod: number | undefined = conditionalCoupon;
		if (period <= numberOfUnconditionalCoupons) {
			couponForPeriod = undefined;
		} else {
			couponForPeriod = conditionalCoupon;
		}
		return { period, coupon: couponForPeriod };
	});

	const result: NumericDictionary<number | undefined> = {};

	return reduce(
		conditionalCoupons,
		(acc, val) => {
			acc[val.period] = val.coupon;
			return acc;
		},
		result,
	);
}

function createCouponBarriersSerie(
	totalPeriods: number,
	numberOfUnconditionalCoupons: number,
	couponBarrier: number,
): NumericDictionary<number | undefined> {
	const couponBarriers = range(1, totalPeriods + 1).map((period) => {
		let couponForPeriod: number | undefined = couponBarrier;
		if (period <= numberOfUnconditionalCoupons) {
			couponForPeriod = undefined;
		} else {
			couponForPeriod = couponBarrier;
		}
		return { period, couponBarrier: couponForPeriod };
	});

	const result: NumericDictionary<number | undefined> = {};

	return reduce(
		couponBarriers,
		(acc, val) => {
			acc[val.period] = val.couponBarrier;
			return acc;
		},
		result,
	);
}

function createUnconditionalCouponSerie(
	totalPeriods: number,
	numberOfUnconditionalCoupons: number,
	unconditionalCoupon: number | undefined,
): NumericDictionary<number | undefined> {
	const unconditionalCoupons = range(1, totalPeriods + 1).map((period) => {
		let couponForPeriod: number | undefined = unconditionalCoupon;
		if (period <= numberOfUnconditionalCoupons) {
			couponForPeriod = unconditionalCoupon;
		} else {
			couponForPeriod = undefined;
		}
		return { period, coupon: couponForPeriod };
	});

	const result: NumericDictionary<number | undefined> = {};

	return reduce(
		unconditionalCoupons,
		(acc, val) => {
			acc[val.period] = val.coupon;
			return acc;
		},
		result,
	);
}

function recomputeScheduleLines(configuration: PhoenixBearishTransversalConfiguration): PhoenixBearishScheduleLine[] {
	const {
		totalPeriods,
		autocallBarrier,
		couponBarrier,
		scheduleLines,
		unconditionalCoupon,
		conditionalCoupon,
		observationStartPeriod,
		progressivityPeriod,
		progressivityStep,
		numberOfUnconditionalCoupons,
	} = configuration;
	const allAutocallBarriers = createAutocallBarrierSerie(
		totalPeriods,
		observationStartPeriod,
		autocallBarrier,
		progressivityStep,
		progressivityPeriod?.duration,
	);
	const allConditionalCoupons = createConditionalCouponSerie(
		totalPeriods,
		numberOfUnconditionalCoupons,
		conditionalCoupon,
	);
	const allUnconditionalCoupons = createUnconditionalCouponSerie(
		totalPeriods,
		numberOfUnconditionalCoupons,
		unconditionalCoupon,
	);
	const allCouponBarriers = createCouponBarriersSerie(totalPeriods, numberOfUnconditionalCoupons, couponBarrier);
	const oldLines = groupBy(scheduleLines, (sl) => sl.periodNumber);
	const newLines: Partial<PhoenixBearishScheduleLine>[] = range(1, totalPeriods + 1).map((periodNumber) => {
		const autocallBarrierI = allAutocallBarriers[periodNumber];
		const conditionalCouponI = allConditionalCoupons[periodNumber];
		const unconditionalCouponI = allUnconditionalCoupons[periodNumber];
		const newItem: Partial<PhoenixBearishScheduleLine> = {
			periodNumber,
			autocallBarrier: autocallBarrierI,
			isAutocallable: autocallBarrierI !== undefined && autocallBarrierI !== null,
			conditionalCoupon: conditionalCouponI,
			hasConditionalCoupon: conditionalCouponI !== undefined && conditionalCouponI !== null,
			unconditionalCoupon: unconditionalCouponI,
			hasUnconditionalCoupon: unconditionalCouponI !== undefined && unconditionalCouponI !== null,
			couponBarrier: allCouponBarriers[periodNumber],
		};
		if (oldLines[periodNumber] !== undefined) {
			return assign({}, oldLines[periodNumber][0], newItem);
		}
		return newItem;
	});

	return newLines as PhoenixBearishScheduleLine[];
}

export default PhoenixBearishSlice;
