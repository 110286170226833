import { Guid } from "Domain/report";

// Global reports
const reportsUrlPrefix = "/reports";
export const getAllReportsEndpoint = reportsUrlPrefix;
export const getReportStatusEndpoint = (brochureId: Guid) => `${reportsUrlPrefix}/${brochureId}/status`;
export const downloadReportEndpoint = (brochureId: Guid) => `${reportsUrlPrefix}/${brochureId}/file`;
export const getDeleteReportEndpoint = (brochureId: Guid) => `${reportsUrlPrefix}/${brochureId}`;
export const getReportConfigurationEndpoint = (brochureId: Guid) => `${reportsUrlPrefix}/${brochureId}`;
export const getInitFromXOneIdEndpoint = `${reportsUrlPrefix}/init`;
// Athena Endpoints
const athenaUrlPrefix = "/reports/athena";
export const createAthenaReportEndpoint = athenaUrlPrefix;
export const getAthenaUnderlyingsEndpoint = `${athenaUrlPrefix}/underlyings`;

// Tarn Endpoints
const tarnUrlPrefix = "/reports/tarn";
export const createTarnReportEndpoint = tarnUrlPrefix;

// Tarn Endpoints
const tauxFixeUrlPrefix = "/reports/tauxfixe";
export const createTauxFixeReportEndpoint = tauxFixeUrlPrefix;

// Phoenix Bearish Endpoints
const phoenixBearishUrlPrefix = "/reports/phoenix-bearish";
export const createPhoenixBearishReportEndpoint = phoenixBearishUrlPrefix;

// Pictures endpoints
const imageRoute = "pictures";
export const getAllImageEndpoint = `${imageRoute}`;
export const getImageEndpoint = (id: string) => `${imageRoute}/${id}/preview`;

// Resources - Disclaimers

const resourceBaseRoot = "/resources";
export const getAllDisclaimersEndpoint = `${resourceBaseRoot}/disclaimers`;
export const getAllUnderlyingsEndpoint = `${resourceBaseRoot}/underlyings`;
