import { ColDef } from "ag-grid-community";
import { isNil, isEmpty } from "lodash";
import React, { FC } from "react";
import { AthenaScheduleLine } from "Domain/report.athena";
import { EditableGrid } from "components/Shared/FormControls/EditableGrid";
import { editValueCreatorHelper } from "state/Reports/Athena/Athena.state";
import { useAthenaValidationStateForField } from "state/Reports/Athena/useAthenaValidationStateForField";
import { useDispatch, useSelector } from "react-redux";
import { BrochureGeneratorAppState } from "Domain/report";

export const AthenaScheduleLines: FC = () => {
	const dispatcher = useDispatch();
	const lines = useSelector(
		(s: BrochureGeneratorAppState) => s.athena.currentReport.redemptionConfiguration.scheduleLines,
	);
	const errorMessage: string | undefined = useAthenaValidationStateForField("redemptionConfiguration", "scheduleLines");
	const isValid = isNil(errorMessage) || isEmpty(errorMessage);

	const onLinesChange = (newLines: AthenaScheduleLine[]) => {
		dispatcher(editValueCreatorHelper("redemptionConfiguration", "scheduleLines", newLines));
	};

	const coldef: Array<ColDef<AthenaScheduleLine>> = [
		{ field: "periodNumber", headerName: "#", maxWidth: 70, type: "numericColumn", editable: false },
		{
			field: "observationDate",
			headerName: "Observation Dates",
			type: ["editableDate"],
		},
		{
			field: "autocall",
			headerName: "Autocall",
			type: ["numericColumn", "editablePercentage"],
		},
		{
			field: "coupon",
			headerName: "Coupon",
			type: ["numericColumn", "editablePercentage"],
		},
		{
			field: "redemptionDate",
			headerName: "Redemption Dates",
			type: ["editableDate"],
		},
		{
			field: "paymentDate",
			headerName: "Coupon Payment Dates",
			type: ["editableDate"],
		},
	];

	return (
		<>
			<EditableGrid
				initialValue={lines}
				rowIdAccessor={(d) => d.periodNumber.toString()}
				coldef={coldef}
				onChange={onLinesChange}
				containerStyle={{ minHeight: "298px" }}
				className="h-100"
			/>
			{!isValid && <div className="invalid-feedback d-block">{errorMessage}</div>}
		</>
	);
};
