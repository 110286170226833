import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState } from "Models/LoadingState";
import { BrochureGeneratorAppState } from "Domain/report";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";
import PhoenixBearishSlice from "state/Reports/PhoenixBearish/PhoenixBearish.state";

const GeneratePhoenixBearishBrochureButton: React.FC = () => {
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();
	const currentLoadingState = useSelector((a: BrochureGeneratorAppState) => a.tarn.brochureLoadingState);
	const isLoading = currentLoadingState === LoadingState.Pending;

	const onGenerateClick = () => {
		dispatcher(PhoenixBearishSlice.actions.generateBrochure());
		analyticsMonitoring.functional("Phoenix Bearish", "Generate");
	};
	return (
		<button
			disabled={isLoading}
			className={`btn btn-xl btn-primary btn-icon-end ${isLoading ? "loading" : ""}`}
			onClick={onGenerateClick}
		>
			Generate Phoenix Bearish
			{isLoading && <span className="loader"></span>}
			<i className="icon">publish</i>
		</button>
	);
};

export default GeneratePhoenixBearishBrochureButton;
