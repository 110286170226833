import { AthenaClientConfiguration } from "Domain/report.athena";
import { AthenaClientConfigurationDto } from "Domain/reportDto.athena";
import { colorPresetMapper } from "../colorPresetMapper";

function mapEntityToDto(entity: AthenaClientConfiguration): AthenaClientConfigurationDto {
	const dto: AthenaClientConfigurationDto = {
		client: entity.client,
		colorPreset: entity.colorPreset,
		pictureId: entity.pictureId,
	};
	return dto;
}
function mapDtoToEntity(dto: AthenaClientConfigurationDto): AthenaClientConfiguration {
	const entity: AthenaClientConfiguration = {
		client: dto.client,
		colorPreset: dto.colorPreset ? colorPresetMapper.mapDtoToEntity(dto.colorPreset) : undefined,
		pictureId: dto.pictureId,
	};
	return entity;
}

export const athenaClientConfigurationMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
