import * as React from "react";
import { Offcanvas } from "reactstrap";
import Fuse from "fuse.js";
import { isEmpty, isNil } from "lodash";
import { onKeyEnterOrSpacePressed } from "Helpers/keyboardEventHelper";
import { useSelector } from "react-redux";
import { BrochureGeneratorAppState, PictureInfo } from "Domain/report";

interface PictureSelectorProps {
	selectedPictureId: string | undefined;
	onChange: (pictureId: string) => void;

	errorMessage: string | undefined;
}

const pictureStateSelector =
	(id: string | undefined) =>
		(state: BrochureGeneratorAppState): PictureInfo | undefined => {
			if (!id) return undefined;
			return state.homepage.pictures.find((p) => p.id === id);
		};

export const PictureSelector: React.FC<PictureSelectorProps> = ({ selectedPictureId, onChange, errorMessage }) => {
	const [pictureSelectorVisible, setPictureSelectorVisible] = React.useState(false);
	const currentPictureInfo = useSelector(pictureStateSelector(selectedPictureId));
	const hidePictureSelector = () => setPictureSelectorVisible(false);
	const showPictureSelector = () => setPictureSelectorVisible(true);
	const isValid = isNil(errorMessage) || isEmpty(errorMessage);
	const hasSelectedPicture = !isNil(currentPictureInfo);

	return (
		<>
			<div
				className={`input-group input-group-lg ${isValid === false ? "custom-form-invalid" : ""}`}
				onClick={showPictureSelector}
				onKeyDown={onKeyEnterOrSpacePressed(showPictureSelector)}
				role="button"
				tabIndex={0}
			>
				{hasSelectedPicture && (
					<span
						className="input-group-text border border-secondary"
						style={{
							width: 100,
							backgroundImage: `url(${currentPictureInfo?.imageUrl})`,
							backgroundPosition: "center center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}
					></span>
				)}
				<div
					className={`form-control d-flex align-items-center flex-fill text-truncate pe-none ${hasSelectedPicture ? "text-muted" : ""}`}
				>
					{currentPictureInfo?.name || "Please select a picture ..."}
				</div>
				<div className="btn btn-discreet-primary">Select</div>
			</div>
			{errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>}
			<PictureExplorer
				selectedPictureId={currentPictureInfo?.id || undefined}
				visible={pictureSelectorVisible}
				onClose={hidePictureSelector}
				onChange={(p) => {
					onChange(p.id);
					hidePictureSelector();
				}}
			/>
		</>
	);
};

type PictureExplorerProps = {
	selectedPictureId: string | undefined;
	visible: boolean;
	onClose: () => void;
	onChange: (picture: PictureInfo) => void;
};
const PictureExplorer: React.FC<PictureExplorerProps> = ({ selectedPictureId, visible, onClose, onChange }) => {
	const allPics = useSelector((s: BrochureGeneratorAppState) => s.homepage.pictures);
	const [searchTerm, setSearchTerm] = React.useState("");

	const filteredPics = React.useMemo(() => {
		if (isEmpty(searchTerm)) {
			return allPics;
		}
		const fuseInstance = new Fuse(allPics, { keys: ["name"], ignoreLocation: true });
		return fuseInstance.search(searchTerm).map((sr) => sr.item);
	}, [allPics, searchTerm]);

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.currentTarget.value);
	};
	return (
		<Offcanvas backdrop={true} isOpen={visible} direction="end" toggle={onClose} className="w-50">
			{visible && (
				<div className="d-flex h-100 flex-column">
					<div className="header-like p-3">
						<div className="d-flex flex-row">
							<h2 className="flex-fill">Select your picture</h2>
							<input
								type="text"
								className="form-control w-auto"
								value={searchTerm}
								onChange={onInputChange}
								placeholder="Filter images ..."
							/>
							<button className="btn-close" onClick={onClose}></button>
						</div>
					</div>
					<div className="flex-fill overflow-auto px-3">
						<div className="row row-cols-3">
							{filteredPics.map((picture) => {
								const onPictureClick = () => onChange(picture);
								const isActive = picture.id === selectedPictureId;

								return (
									<div className="col" key={picture.id}>
										<div
											role="button"
											className={`card card-raising card-filling card-bordered mb-3 cursor-pointer ${isActive ? "active" : ""}`}
											onClick={onPictureClick}
											onKeyDown={onKeyEnterOrSpacePressed(onPictureClick)}
											tabIndex={0}
										>
											<div
												className="card-img-top"
												style={{
													backgroundImage: `url(${picture.imageUrl})`,
													backgroundPosition: "center center",
													backgroundRepeat: "no-repeat",
													backgroundSize: "cover",
													height: 200,
												}}
											></div>
											<div className="card-body">
												<h4 className="card-title line-height-1 text-truncate">{picture.name}</h4>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="p-3">
						<button className="btn btn-primary btn-block" onClick={onClose}>
							Close
						</button>
					</div>
				</div>
			)}
		</Offcanvas>
	);
};
