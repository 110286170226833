// This is the app authorization file ...
// Where we make sure you are authorized to use the application

import { put, take, select, call } from "typed-redux-saga";
import { authorizationSlice } from "./authorizationReducer";
import { getCurrentEnvironment, type DeployedEnvironment } from "@analytics-web-core/core";
import { BrochureGeneratorAppState } from "Domain/report";
import { GetRolesResponse, getRoles } from "Api/brochureGeneratorApi";

const BROCHURE_GENERATOR_ACCESS_PERMISSION = 'ANALYTICS_BROCHURE_GENERATOR';
const BROCHURE_GENERATOR_ADMIN_PERMISSION = 'ANALYTICS_BROCHURE_GENERATOR_ADMIN';

export function* authorizationSaga() {
	yield* take(authorizationSlice.actions.checkAuthorization);
	try {
		const res: GetRolesResponse = yield* call(getRoles);
		const arg = {
			base: res.roles.includes(BROCHURE_GENERATOR_ACCESS_PERMISSION),
			write: res.optionalRoles.includes(BROCHURE_GENERATOR_ADMIN_PERMISSION),
		};
		yield* put(authorizationSlice.actions.setAuthorized(arg));
		if (arg.base === true) {
			return;
		}
	} catch (e) {
		console.error(e);
		yield* put(authorizationSlice.actions.setAuthorized({ base: false, write: false }));
		if ((e as Error).name === "AxiosError" && (e as Error).message === "Network Error") {
			// @fixme: current error linked to network error ?
			// suppose that if it literally breaks (not an absence of role) it's because of network error
			// and hard reload :O
			window.location.assign(window.location.href);
			return;
		}
	}

	const applicationName: string = (yield* select((state: BrochureGeneratorAppState) => state.config.applicationName)) || "";
	const sourceUrl: string = (yield* select(
		(state: BrochureGeneratorAppState) => state.config.currentEnvConfig?.sgConnectConfig.redirect_uri,
	)) || "";

	const currentEnv: DeployedEnvironment = getCurrentEnvironment() ?? "DEV";
	const isProd = currentEnv === "PRD";

	const host = isProd ? "https://shared.sgmarkets.com" : "https://shared-uat.sgmarkets.com";
	const redirectUrl = isProd
		? "https://brochuregenerator.sgmarkets.com"
		: "https://brochuregenerator-uat.sgmarkets.com";

	const uri = `${host}/unauthorized/?redirectUri=${encodeURIComponent(redirectUrl)}&sourceurl=${encodeURIComponent(
		currentEnv === "LOCAL" ? "https://analytics-dev.sgmarkets.com/analyticsclientssupervision" : sourceUrl,
	)}&sourcecontext=${encodeURIComponent(applicationName)}`;

	window.location.assign(new URL(uri));
}
