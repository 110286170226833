import { isNil, isNumber, reduce } from 'lodash';
import { PartialRecord } from 'state/root/rootReducer';

export type BreakPointsName = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type Sizes = PartialRecord<BreakPointsName, number> & {
	defaultSize?: number;
};

function getSizeClass(value: number | Sizes | undefined, prefix: string, initialValue?: string): string {
	let valToReturn = initialValue ?? '';
	if (!isNil(value)) {
		if (isNumber(value)) {
			valToReturn = `${valToReturn} ${prefix}-${value}`;
		} else {
			const { defaultSize, ...breakpointSizes } = value;
			valToReturn = reduce(
				breakpointSizes,
				(acc, sizeValue, breakpointName) => {
					return `${acc} ${prefix}-${breakpointName}-${sizeValue}`;
				},
				valToReturn
			);
			if (isNumber(defaultSize)) {
				valToReturn = `${valToReturn} ${prefix}-${defaultSize}`;
			}
		}
	}

	return valToReturn.trim();
}
const colSizeClassNames = (value: number | Sizes | undefined, initialValue?: string): string => getSizeClass(value, 'col', initialValue);
const formValidationStateClassNames = (isvalid: boolean | undefined): string => {
	if (isNil(isvalid)) return '';
	const validName = isvalid ? '' : 'invalid';
	return `is-${validName}`;
};
export const cssHelper = {
	getSizeClass,
	colSizeClassNames,
	formValidationStateClassNames,
};
