import { call, put, takeEvery } from "typed-redux-saga";
import { BrochureGeneratorApi } from "Api/brochureGeneratorApi";
import HomePageSlice from "state/HomePage/HomePage.state";

const api = new BrochureGeneratorApi();

export function* pictureSaga(): Generator {
	yield* takeEvery(HomePageSlice.actions.loadImages, onLoadImages);
}
export function* onLoadImages(): Generator {
	try {
		const allImages = yield* call(api.getImages);
		yield* put(HomePageSlice.actions.loadImagesSuccess({ pictures: allImages }));
	} catch {
		/* empty */
	}
}
