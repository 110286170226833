import * as React from 'react';
import { DraftEditorCommand, Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { toUpper, isMatch } from 'lodash';
import { RenderConfig, stateToHTML } from 'draft-js-export-html';
import { CustomInlineFn, stateFromHTML } from 'draft-js-import-html';

type InlineCommands = 'bold' | 'italic' | 'underline';

const EditorButton: React.FC<{ icon: string; onClick: () => void }> = ({ icon, onClick }) => {
	return (
		<button className="btn btn-discreet-secondary" onClick={onClick}>
			<em className="icon">{icon}</em>
		</button>
	);
};
const EditorTextButton: React.FC<{ text: string; textClassName: string; onClick: () => void }> = ({ text, textClassName, onClick }) => {
	return (
		<button className="btn btn-discreet-secondary" onClick={onClick}>
			<span className={textClassName}>{text}</span>
		</button>
	);
};

function replaceAll(str: string, search: string, replacement: string) {
	let lastStr = str;
	// eslint-disable-next-line no-constant-condition
	while (true) {
		const res = lastStr.replace(search, replacement);
		if (res !== lastStr) {
			lastStr = res;
		} else {
			return res;
		}
	}
}

type CustomNotesEditorProps = {
	initialContent: string | undefined;
	onChange: (htmlcontent: string) => void;
};

type AvailableStyles = 'accent1' | 'accent2';
type CustomStyleConfiguration = {
	editorRenderedStyle: React.CSSProperties;
	styleHtmlConvertionConfig: RenderConfig;
};

const styleConfiguration: Record<AvailableStyles, CustomStyleConfiguration> = {
	accent1: {
		editorRenderedStyle: { color: 'var(--bs-socgen)' },
		styleHtmlConvertionConfig: {
			element: 'span',
			attributes: { className: 'accent1' },
			style: undefined,
		},
	},
	accent2: {
		editorRenderedStyle: {},
		styleHtmlConvertionConfig: {
			element: 'span',
			attributes: { className: 'accent1' },
			style: undefined,
		},
	},
};

const retrieveCustomStyleNamesFromHtml: CustomInlineFn = (element, { Style }) => {
	for (const styleName in styleConfiguration) {
		const theStyle = styleConfiguration[styleName as AvailableStyles];
		if (
			element.tagName.toLowerCase() === theStyle.styleHtmlConvertionConfig.element &&
			isMatch(element, theStyle.styleHtmlConvertionConfig.attributes)
		) {
			return Style(styleName);
		}
	}

	return undefined;
};

const editorCustomStyleMap = Object.keys(styleConfiguration).reduce((acc, styleName) => {
	acc[styleName] = styleConfiguration[styleName as AvailableStyles].editorRenderedStyle;
	return acc;
}, {} as Record<string, React.CSSProperties>);

const stateToHtmlInlineStyles = Object.keys(styleConfiguration).reduce((acc, styleName) => {
	acc[styleName as AvailableStyles] = styleConfiguration[styleName as AvailableStyles].styleHtmlConvertionConfig;
	return acc;
}, {} as Record<AvailableStyles, RenderConfig>);

export const CustomNotesEditor: React.FC<CustomNotesEditorProps> = ({ initialContent, onChange }) => {
	const [isReadOnly, setIsReadOnly] = React.useState(true);
	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

	const toggleInlineStyle = (style: InlineCommands) => () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, toUpper(style)));
	};

	const _toggleColor = (toggledColor: AvailableStyles) => {
		const selection = editorState.getSelection();

		// Let's just allow one color at a time. Turn off all active colors.
		const nextContentState = Object.keys(editorCustomStyleMap).reduce((contentState, color) => {
			return Modifier.removeInlineStyle(contentState, selection, color);
		}, editorState.getCurrentContent());

		let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

		const currentStyle = editorState.getCurrentInlineStyle();

		// Unset style override for current color.
		if (selection.isCollapsed()) {
			nextEditorState = currentStyle.reduce((state, color) => {
				return RichUtils.toggleInlineStyle(state!, color!);
			}, nextEditorState);
		}

		// If the color is being toggled on, apply it.
		if (!currentStyle.has(toggledColor)) {
			nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor);
		}

		setEditorState(nextEditorState);
	};

	const handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);

		if (newState) {
			setEditorState(newState);
			return 'handled';
		}

		return 'not-handled';
	};

	const resetContent = React.useCallback(() => {
		let newState: EditorState;
		if ((initialContent ?? '').trim().length === 0) {
			newState = EditorState.createEmpty();
		} else {
			const contentState = stateFromHTML(initialContent ?? '', { customInlineFn: retrieveCustomStyleNamesFromHtml });
			newState = EditorState.createWithContent(contentState);
		}

		setEditorState(newState);
	}, [initialContent]);

	React.useEffect(() => {
		resetContent();
	}, [resetContent]);

	const onValidate = React.useCallback(() => {
		const rawHtml = stateToHTML(editorState.getCurrentContent(), { inlineStyles: stateToHtmlInlineStyles }) || '';
		let newRawHtml = replaceAll(rawHtml, '\n', '<br />');
		if (newRawHtml === '<p><br></p>') {
			newRawHtml = '';
		}
		onChange(newRawHtml);
		setIsReadOnly(true);
	}, [editorState, onChange]);

	const onEditorContentChange = (state: EditorState) => {
		setEditorState(state);
	};

	const onStartEdit = React.useCallback(() => {
		setIsReadOnly(false);
	}, [setIsReadOnly]);
	const onCancelEdit = React.useCallback(() => {
		setIsReadOnly(true);
		resetContent();
	}, [resetContent]);

	return (
		<div className="d-flex flex-column jecot-text-editor">
			{!isReadOnly && (
				<div className="flex-shrink bg-lvl2 border border-bottom-0">
					<div className="btn-toolbar">
						<EditorButton icon="format_bold" onClick={toggleInlineStyle('bold')} />
						<EditorButton icon="format_italic" onClick={toggleInlineStyle('italic')} />
						<EditorButton icon="format_underline" onClick={toggleInlineStyle('underline')} />
						<EditorTextButton text="Accent 1" textClassName="text-danger fw-bold" onClick={() => _toggleColor('accent1')} />
					</div>
				</div>
			)}
			<div className="flex-fill bg-lvl1 border">
				<Editor
					editorState={editorState}
					handleKeyCommand={handleKeyCommand}
					onChange={onEditorContentChange}
					preserveSelectionOnBlur
					readOnly={isReadOnly}
					placeholder={"Click on 'Edit' then 'Validate' to update content."}
					customStyleMap={editorCustomStyleMap}
				/>
			</div>
			<div className="text-end pt-3">
				{isReadOnly && (
					<button className="btn btn-primary" onClick={onStartEdit}>
						Edit
					</button>
				)}
				{!isReadOnly && (
					<>
						<button className="btn btn-flat-secondary" onClick={onCancelEdit}>
							Cancel
						</button>
						<button className="btn btn-primary" onClick={onValidate}>
							Validate
						</button>
					</>
				)}
			</div>
		</div>
	);
};
