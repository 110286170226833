import { MimeType } from './mimeTypes';

/**
 *  call this function will make the browser open/save the given file
 * @param fileContent blob object containing file data
 * @param filename file's name
 * @param fileExtension file's extension
 */
interface FileProperties {
	content: BlobPart;
	filename: string;
	fileExtension: string;
	mimeType: MimeType;
}

export const downloadFile = async ({ content, filename, fileExtension, mimeType }: FileProperties) => {
	const url = window.URL.createObjectURL(new Blob([content], { type: mimeType }));
	const link = document.createElement('a');
	link.href = url;

	link.setAttribute('download', `${filename}.${fileExtension}`);
	document.body.appendChild(link);

	link.click();
	link.remove();
};
