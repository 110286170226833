import { noop } from "lodash";
import { useNavigate, NavigateFunction } from "react-router-dom";

export let globalNavigate: NavigateFunction = noop;

type ToolPages = "Home" | "NewAthenaReport" | "NewPhoenixBearishReport" | "NewTarnReport" | "NewTauxFixeReport";

export type PathConfiguration = (...args: any[]) => {};

const ApplicationPaths: Record<ToolPages, string> = {
	Home: "/",
	NewAthenaReport: "/reports/athena",
	NewTarnReport: "/reports/tarn",
	NewTauxFixeReport: "/reports/tauxfixe",
	NewPhoenixBearishReport: "/reports/phoenixbearish",
};

export const GlobalHistory = () => {
	globalNavigate = useNavigate();
	return null;
};

export const GlobalNavigation = {
	navigate: (to: string) => {
		globalNavigate(to);
	},
	navigateTo: (page: ToolPages) => {
		globalNavigate(ApplicationPaths[page]);
	},
};
