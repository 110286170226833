import { ColorProfile } from "Domain/report";
import { ColorProfileDto } from "Domain/reportDto";


function mapEntityToDto(entity: ColorProfile): ColorProfileDto {
	const dto: ColorProfileDto = {
		name: entity.name,
		r: entity.r,
		g: entity.g,
		b: entity.b,
		a: entity.a,
	};
	return dto;
}

function mapDtoToEntity(dto: ColorProfileDto): ColorProfile {
	return {
		name: dto.name,
		r: dto.r,
		g: dto.g,
		b: dto.b,
		a: dto.a,
	};
}

export const colorProfileMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
