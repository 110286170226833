import { SgDatePicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import { DateTime } from "luxon";
import React from "react";
import { BreakPointsName } from "../cssHelper";
import { DefaultInputProps } from "./DefaultInputProps";


interface DatePickerFormControlProps extends DefaultInputProps<Date | undefined> {
	size?: BreakPointsName;
}

const DatePickerFormControl: React.FC<DatePickerFormControlProps> = ({ initialValue, onChange, size, isValid, disabled }) => {
	const id = React.useId();
	const key = `${id}_${initialValue ? DateTime.fromJSDate(initialValue).toMillis() : 'undef'}`;
	return (
		<SgDatePicker
			key={key}
			disabled={disabled}
			validationState={isValid === true ? undefined : isValid === false ? 'invalid' : null}
			onDateSelected={(e) => {
				onChange(e.detail.date);
			}}
			yearControllers={true}
			onInputCleared={(e) => {
				onChange(undefined);
			}}
			defaultValue={initialValue ? DateTime.fromJSDate(initialValue).valueOf().toString() : undefined}
			size={(size as any) ?? 'md'}
			inputId={id}
		/>
	);
};

export default DatePickerFormControl;
