import { TextInput } from "@analytics-web-core/components";
import React, { useState, useCallback } from "react";
import { primeToaster } from "components/App/PrimeToasterContainer";
import { TextArea } from "components/Shared/FormControls/TextArea";
import ResourcesSlice, { DisclaimerItem } from "state/Resources/Resources.state";
import { useDispatch } from "react-redux";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

type DisclaimerFormProps = {
	disclaimer: DisclaimerItem;
	isNew: boolean;
	onClose: () => void;
};

export const DisclaimerForm: React.FC<DisclaimerFormProps> = ({ disclaimer: underlying, isNew, onClose }) => {
	const [isLoading, setIsLoading] = useState(false);

	const [currentDisclaimer, setCurrentDisclaimer] = useState(underlying);
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();

	const onChange = (prop: keyof DisclaimerItem) => (value: any) => {
		setCurrentDisclaimer({
			...currentDisclaimer,
			[prop]: value,
		});
	};

	const onSaveClick = useCallback(() => {
		setIsLoading(true);
		dispatcher(
			ResourcesSlice.actions.updateDisclaimer({
				disclaimer: currentDisclaimer,
				success: () => {
					setIsLoading(false);
					primeToaster.success("", "Disclaimer updated");
					onClose();
				},
				error: () => {
					setIsLoading(false);
					primeToaster.danger("Error", "Unable to disclaimer");
				},
			}),
		);
		analyticsMonitoring.functional("Disclaimers", "Save");
	}, [analyticsMonitoring, currentDisclaimer, dispatcher, onClose]);

	return (
		<div className="vstack h-100 p-3">
			<h1 className="">{isNew ? "Creation d'un nouveau disclaimer" : "Edition d'un disclaimer"}</h1>
			<div className={` my-4 alert ${isNew ? "alert-info" : "alert-danger"}`}>
				{isNew && (
					<p>
						You are about to create a new disclaimer. <br />
						Please note that until we release a new version of the service using this new content, it is impossible to
						see the result on a brochure at this time.
					</p>
				)}
				{!isNew && (
					<p>
						Please be aware that any changes to the content of this disclaimer will be applied to all future documents
						generated once you have validated this form.
					</p>
				)}
			</div>

			<div className="mb-3">
				<div className="form-label">Disclaimer Id</div>
				<TextInput readOnly={!isNew} value={currentDisclaimer.disclaimerId} onChange={onChange("disclaimerId")} />
				<div className="form-text">
					Disclaimer ID used in the tool to retrive the content.
					{!isNew && (
						<span className="fw-bold">
							<br /> You are now allowed to update the id of an existing resource.
						</span>
					)}
				</div>
			</div>
			<div className="mb-3">
				<div className="form-label">Description</div>
				<TextArea value={currentDisclaimer.description} onChange={onChange("description")} />
				<div className="form-text">
					This is just a friendly description. This description is only here to identify the purpose of the disclaimer
					(and probably to help you to understand where this content is located in brochures)
				</div>
			</div>
			<div className="flex-fill">
				<div className="vstack h-100">
					<div className="form-label">Disclaimer content</div>
					<TextArea className="flex-fill" value={currentDisclaimer.content} onChange={onChange("content")} />
				</div>
			</div>
			<div className="text-end mt-3">
				<button className="btn btn-lg btn-flat-secondary" onClick={onClose}>
					Cancel
				</button>
				<button className="btn btn-lg btn-primary" onClick={onSaveClick} disabled={isLoading}>
					Save
				</button>
			</div>
		</div>
	);
};
