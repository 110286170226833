import { createSlice } from "@reduxjs/toolkit";

export type AuthorizationState = {
	base: boolean | null;
	write: boolean | null;
};

export const authorizationSlice = createSlice({
	name: "authorization",
	initialState: {
		base: null,
		write: null,
	} as AuthorizationState,
	reducers: {
		checkAuthorization(state) {
			return state;
		},
		setAuthorized(state, { payload: { base, write } }: { payload: { base: boolean; write: boolean } }) {
			state.base = base;
			state.write = write;
		},
	},
});
