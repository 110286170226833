import { ReportType } from "Domain/report";
import { PhoenixBearishReportConfiguration, PhoenixBearishReportModel } from "Domain/report.phoenixBearish";
import { PhoenixBearishReportConfigurationDto, PhoenixBearishReportModelDto } from "Domain/reportDto.phoenixBearish";
import { PhoenixBearishTransversalConfigurationMapper } from "./phoenixBearishTransversalConfigurationMapper";

function mapEntityToDto(
	entity: PhoenixBearishReportConfiguration,
	tradeReference: string | undefined,
): PhoenixBearishReportModelDto {
	const configdto: PhoenixBearishReportConfigurationDto = {
		transversalConfiguration: PhoenixBearishTransversalConfigurationMapper.mapEntityToDto(
			entity.transversalConfiguration,
		),
	};
	const dto: PhoenixBearishReportModelDto = {
		reportConfiguration: configdto,
		type: ReportType.PhoenixBearish,
		tradeReference,
	};
	return dto;
}
function mapDtoToEntity(dto: PhoenixBearishReportModelDto): PhoenixBearishReportModel {
	const reportConfig: PhoenixBearishReportConfiguration = {
		transversalConfiguration: PhoenixBearishTransversalConfigurationMapper.mapDtoToEntity(
			dto.reportConfiguration.transversalConfiguration,
		),
	};
	const model: PhoenixBearishReportModel = {
		type: ReportType.PhoenixBearish,
		configuration: reportConfig,
		tradeReference: dto.tradeReference,
	};
	return model;
}

export const phoenixBearishReportModelMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
