import './PrimeToasterContainer.scss';

import * as React from 'react';
import { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

export type ToasterTypes = 'success' | 'info' | 'warning' | 'danger' | 'white' | 'black' | 'socgen';
export type ToasterMessage = string | JSX.Element;

export type ToasterContentProps = {
	type: ToasterTypes;
	title: string;
	message: ToasterMessage;
	detail?: string;
	closeButton?: boolean;
	onClose?: (props: unknown) => void;
	icon?: ReactNode;
};

export const ToasterContent: React.FC<ToasterContentProps> = ({ title, message, detail, closeButton, onClose, icon }) => {
	let $closeBtn: React.ReactNode;
	let $iconColumn: React.ReactNode;
	if (closeButton === true) {
		$closeBtn = <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>;
	}
	if (icon) {
		$iconColumn = <div className="me-2">{icon}</div>;
	}
	return (
		<>
			<div className="toast-header">
				<strong className="flex-fill">{title}</strong>
				{$closeBtn}
			</div>
			<div className="toast-body">
				<div className="d-flex flex-row align-items-center">
					{$iconColumn}
					<div>
						<div className="text-primary">{message}</div>
						{detail && <div className="text-primary">{detail}</div>}
					</div>
				</div>
			</div>
		</>
	);
};

function notify(title: string, message: ToasterMessage, iconName?: string, options?: ToastOptions, type?: ToasterTypes) {
	const { closeButton, onClose, icon, ...otherOptions } = options ?? {};
	const toastType = type ?? 'info';
	const $icon: ReactNode = iconName ? <em className={`icon icon-md text-${toastType}`}>{iconName}</em> : (icon as any);

	const $content = (
		<ToasterContent title={title} closeButton={closeButton !== false} onClose={onClose} message={message} type={toastType} icon={$icon} />
	);
	const finalToasterOptions: ToastOptions = {
		...otherOptions,
		closeButton: false,
		className: () => `toast show toast-${toastType}`,
		bodyClassName: () => '',
		progressClassName: () => {
			const defClasses = 'Toastify__progress-bar Toastify__progress-bar--animated';
			return `${defClasses} bg-${toastType}`;
		},
	};
	return toast($content, finalToasterOptions);
}

const allToasterTypes: ToasterTypes[] = ['success', 'info', 'warning', 'danger', 'white', 'black', 'socgen'];

export type Toaster = Record<ToasterTypes, typeof notify> & {
	toast: typeof toast;
};
export let primeToaster: Toaster = {
	toast,
} as Toaster;

primeToaster = allToasterTypes.reduce((acc, toastrType) => {
	acc[toastrType] = (title: string, message: ToasterMessage, iconName?: string, options?: ToastOptions) => {
		return notify(title, message, iconName, options, toastrType);
	};
	return acc;
}, primeToaster);
