import * as React from 'react';

export const ReportFormSection: React.FC<React.PropsWithChildren<{ title: string; containerClassName?: string }>> = ({
	children,
	title,
	containerClassName,
}) => {
	return (
		<div className={`card card-bordered bg-lvl2 h-100 ${containerClassName || ''}`}>
			<div className="card-header pb-4">
				<h5 className="card-title">{title}</h5>
			</div>
			<div className="card-body pb-0">
				<div className="container-fluid p-0 mx-n1">{children}</div>
			</div>
		</div>
	);
};
