
import { LabelsConfiguration, KeyLabel } from 'components/Shared/FormControls/FieldConfiguration';
import { ColorPreset, DateRange, Guid, ProductEligibility, ProductLaw, ReportType, BaseReportModel, Periodicity, ProductClient, DurationInfo } from './report';

export type TarnReportConfiguration = {
	transversalConfiguration: TarnTransversalConfiguration;
};

export type TarnTransversalConfiguration = {
	productName: string;
	productGuarantor: string;
	productCotation: string;
	calculationAgent: string;
	productIssuer: string;
	productCurrency: string;
	minimumInvestment: number;
	isin?: string;
	mtmPublication: string;
	productLaw: ProductLaw;
	firstUnderlying: TarnUnderlyingInfo;
	secondUnderlying: TarnUnderlyingInfo;
	marketingPeriod?: DateRange;
	maturityDate?: Date;
	issueDate?: Date;
	productDuration: DurationInfo;
	rateCurrency: string;
	bidOffer: number;
	capitalPaymentBusinessDays?: string;
	autocallBarrier?: number;
	colorPreset: ColorPreset;
	scheduleLines: TarnScheduleLine[];
	doubleValuation?: string;
	fixedCoupon?: number;
	fixedCouponPeriods?: number;
	totalPeriods?: number;
	productEligibility?: ProductEligibility;
	pictureId?: Guid;
	commission: number;
	feesPercent: number;
	issuePrice?: number;
	productDenomination?: number;
	priceRate?: number;
	ftDate?: Date;
	observationPeriodicity?: Periodicity;
	client?: ProductClient;
	hasLeverage: boolean;
	leverageAmount?: number;
	hasZc: boolean;
	bonusPeriod?: DateRange;
	bonusCoupon?: number;
	couponDisplayMode: CouponDisplayMode;
};


export enum CouponDisplayMode {
	SumOfVariableCoupon = 'SumOfCouponVariable',
	TotalCoupons = 'TotalCoupons',
}

export const couponDisplayModeItems: LabelsConfiguration<CouponDisplayMode> = {
	[CouponDisplayMode.SumOfVariableCoupon]: {
		key: CouponDisplayMode.SumOfVariableCoupon,
		label: 'Sum of variable coupons'
	},
	[CouponDisplayMode.TotalCoupons]: {
		key: CouponDisplayMode.TotalCoupons,
		label: 'Total coupons'
	}
}

export type TarnScheduleLine = {
	periodNumber: number;
	paymentDate?: Date;
	isAutocallable: boolean;
	autocallBarrier: number | undefined;
	isFloatingCouponApplicable: boolean;
	isFloatingCouponLeveraged: boolean;
	leverage: number | undefined;
	isFixedCouponApplicable: boolean;
	fixedCoupon: number | undefined;
};

export type TarnUnderlyingInfo = {
	code: string;
	displayName: string;
	maturity: string;
	ticker: string;
	maturityYears: number;
};



export interface TarnReportModel extends BaseReportModel {
	type: ReportType.Tarn;
	configuration?: TarnReportConfiguration;
}

export type TarnPaymentDelayTypes = '5' | '10' | '15';

export const paymentDelayItems: LabelsConfiguration<TarnPaymentDelayTypes> = {
	5: { key: '5', label: '5' },
	10: { key: '10', label: '10' },
	15: { key: '15', label: '15' },
};

export const tarnUnderlyings: TarnUnderlyingInfo[] = [
	{
		code: 'CMS.EUR.30Y',
		displayName: 'CMS EUR 30 ans',
		maturity: '30Y',
		ticker: 'EUAMDB30 Index',
		maturityYears: 30,
	},
	{
		code: 'CMS.EUR.20Y',
		displayName: 'CMS EUR 20 ans',
		maturity: '20Y',
		ticker: 'EUAMDB20 Index',
		maturityYears: 20,
	},
	{
		code: 'CMS.EUR.10Y',
		displayName: 'CMS EUR 10 ans',
		maturity: '10Y',
		ticker: 'EUAMDB10 Index',
		maturityYears: 10,
	},
	{
		code: 'CMS.EUR.5Y',
		displayName: 'CMS EUR 5 ans',
		maturity: '5Y',
		ticker: 'EUAMDB05 Index',
		maturityYears: 5,
	},
	{
		code: 'CMS.EUR.2Y',
		displayName: 'CMS EUR 2 ans',
		maturity: '2Y',
		ticker: 'EUAMDB02 Index',
		maturityYears: 2,
	},
];

export const underlyingItems: KeyLabel[] = tarnUnderlyings.map((uInfo) => {
	const item: KeyLabel = {
		key: uInfo.code,
		label: uInfo.code,
	};
	return item;
});
