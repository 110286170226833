import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { AthenaReport } from "./Athena/AthenaReport";
import { TarnReport } from "./Tarn/TarnReport";
import { TauxFixeReport } from "./TauxFixe/TauxFixeReport";
import { PhoenixBearishReport } from "./PhoenixBearish/PhoenixBearishReport";

export const ReportPage: React.FC = () => {
	return (
		<div className="d-flex flex-column h-100">
			<div className="flex-fill">
				<div className="row g-0 h-100">
					<div className="col">
						<Routes>
							<Route path="athena" element={<AthenaReport />} />
							<Route path="phoenixBearish" element={<PhoenixBearishReport />} />
							<Route path="tarn" element={<TarnReport />} />
							<Route path="tauxfixe" element={<TauxFixeReport />} />
						</Routes>
					</div>
				</div>
			</div>
		</div>
	);
};
