import { call, put, takeEvery } from "typed-redux-saga";
import ResourcesSlice from "./Resources.state";
import { BrochureGeneratorApi } from "Api/brochureGeneratorApi";

const api = new BrochureGeneratorApi();

export function* resourcesSaga(): Generator {
	yield* takeEvery(ResourcesSlice.actions.loadDisclaimers, loadDisclaimers);
	yield* takeEvery(ResourcesSlice.actions.loadUnderlyings, loadUnderlyings);
	yield* takeEvery(ResourcesSlice.actions.updateDisclaimer, onUpdateDisclaimer);
	yield* takeEvery(ResourcesSlice.actions.updateUnderlying, onUpdateUnderlying);
	yield* takeEvery(ResourcesSlice.actions.deleteUnderlying, onDeleteUnderlying);
}

function* loadDisclaimers() {
	const result = yield* call(api.getDisclaimers);
	yield* put(ResourcesSlice.actions.loadDisclaimersSuccess({ disclaimers: result.disclaimers }));
}

function* loadUnderlyings() {
	const result = yield* call(api.getAllUnderlyings);
	yield* put(ResourcesSlice.actions.loadUnderlyingsSuccess({ underlyings: result.underlyings }));
}

function* onUpdateDisclaimer(action: ReturnType<typeof ResourcesSlice.actions.updateDisclaimer>) {
	let thrownError: unknown;
	try {
		const res = yield* call(api.upsertDisclaimer, action.payload.disclaimer);
		if (res === true) {
			action.payload.success();
			yield* put(ResourcesSlice.actions.loadDisclaimers());
			return;
		} else if (res !== false) {
			thrownError = res.errorMessage;
		}
	} catch (error) {
		thrownError = error;
	}
	action.payload.error(thrownError);
}
function* onUpdateUnderlying(action: ReturnType<typeof ResourcesSlice.actions.updateUnderlying>) {
	let thrownError: unknown;
	try {
		const res = yield* call(api.upsertUnderlying, action.payload.underlying);
		if (res === true) {
			action.payload.success();
			yield* put(ResourcesSlice.actions.loadUnderlyings());
			return;
		} else if (res !== false) {
			thrownError = res.errorMessage;
		}
	} catch (error) {
		thrownError = error;
	}
	action.payload.error(thrownError);
}

function* onDeleteUnderlying(action: ReturnType<typeof ResourcesSlice.actions.deleteUnderlying>) {
	let thrownError: unknown;
	try {
		const res = yield* call(api.deleteUnderlying, action.payload.ticker);
		if (res === true) {
			action.payload.success();
			yield* put(ResourcesSlice.actions.loadUnderlyings());
			return;
		}
	} catch (error) {
		thrownError = error;
	}
	action.payload.error(thrownError);
}
