import { PhoenixBearishScheduleLine, PhoenixBearishScheduleLineDto } from "Domain/reportDto.phoenixBearish";
import { toDateOnly, toJSDate } from "Utilities/date";

function mapEntityToDto(entity: PhoenixBearishScheduleLine): PhoenixBearishScheduleLineDto {
	const dto: PhoenixBearishScheduleLineDto = {
		periodNumber: entity.periodNumber,
		paymentDate: toDateOnly(entity.paymentDate),
		observationDate: toDateOnly(entity.observationDate),
		autocallBarrier: entity.autocallBarrier,
		couponBarrier: entity.couponBarrier,
		hasUnconditionalCoupon: entity.hasUnconditionalCoupon,
		unconditionalCoupon: entity.unconditionalCoupon,
		hasConditionalCoupon: entity.hasConditionalCoupon,
		conditionalCoupon: entity.conditionalCoupon,
		isAutocallable: entity.isAutocallable,
	};
	return dto;
}
function mapDtoToEntity(dto: PhoenixBearishScheduleLineDto): PhoenixBearishScheduleLine {
	const entity: PhoenixBearishScheduleLine = {
		periodNumber: dto.periodNumber,
		paymentDate: toJSDate(dto.paymentDate)!,
		observationDate: toJSDate(dto.observationDate)!,
		autocallBarrier: dto.autocallBarrier,
		couponBarrier: dto.couponBarrier,
		hasUnconditionalCoupon: dto.hasUnconditionalCoupon,
		unconditionalCoupon: dto.unconditionalCoupon,
		hasConditionalCoupon: dto.hasConditionalCoupon,
		conditionalCoupon: dto.conditionalCoupon,
		isAutocallable: dto.isAutocallable,
	};
	return entity;
}
export const PhoenixBearishScheduleLineMappers = {
	mapDtoToEntity,
	mapEntityToDto,
};
