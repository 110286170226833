import { useAnalyticsMonitoring } from "@analytics-web-core/core";
import React from "react";
import { useDispatch } from "react-redux";
import HomePageSlice from "state/HomePage/HomePage.state";

interface Props {
	className?: string;
}

export const NewReportButton: React.FC<Props> = ({ className }) => {
	const dispatcher = useDispatch();
	const classNames = `btn btn-primary ${className ?? ""}`;
	const analyticsMonitoring = useAnalyticsMonitoring();

	const onNewBrochureClick = () => {
		dispatcher(HomePageSlice.actions.showNewBrochureModal());
		analyticsMonitoring.functional("Brochure", "New brochure");
	};

	return (
		<button className={classNames} onClick={onNewBrochureClick}>
			New brochure <i className="icon">add</i>
		</button>
	);
};
