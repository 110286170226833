import { isNil, isEmpty, forEach } from "lodash";
import React, { PropsWithChildren } from "react";
import { AthenaReportConfiguration, AthenaUnderlyingId } from "Domain/report.athena";
import { ButtonGroupFormControl } from "components/Shared/FormControls/ButtonGroupFormControl";
import DateListFormControl from "components/Shared/FormControls/DateListFormControl";
import DatePickerFormControl from "components/Shared/FormControls/DatePickerFormControl";
import DateRangePickerFormControl from "components/Shared/FormControls/DateRangePickerFormControl";
import DurationInfoFormControl from "components/Shared/FormControls/DurationInfoFormControl";
import { AllFieldsConfigurationTypes, BaseFieldConfiguration } from "components/Shared/FormControls/FieldConfiguration";
import NumericFormControl from "components/Shared/FormControls/NumericFormControl";
import PercentFormControl from "components/Shared/FormControls/PercentFormControl";
import { PeriodFormControl } from "components/Shared/FormControls/PeriodFormControl";
import { RadioButtonGroup } from "components/Shared/FormControls/RadioButtonGroup";
import { RadioList } from "components/Shared/FormControls/RadioList";
import { SimpleSelectFormControl } from "components/Shared/FormControls/SimpleSelectFormControl";
import UnderlyingSelector from "components/Shared/FormControls/UnderlyingSelector";
import { Sizes, cssHelper } from "components/Shared/cssHelper";
import { editValueCreatorHelper } from "state/Reports/Athena/Athena.state";
import { useAthenaValidationStateForField } from "state/Reports/Athena/useAthenaValidationStateForField";
import { Checkbox } from "components/Shared/FormControls/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { BrochureGeneratorAppState, PeriodicInfo } from "Domain/report";

interface AthenaFormControlProps<TReportConfigKey extends keyof AthenaReportConfiguration> {
	size?: number | Sizes;
	contextKey: TReportConfigKey;
	field: keyof AthenaReportConfiguration[TReportConfigKey];
	fieldConfigOverrides?: Partial<AllFieldsConfigurationTypes>;
}

function getFormControl(
	fieldConfig: AllFieldsConfigurationTypes,
	fieldId: string,
	triggerValueChange: (value: any) => void,
	value: any,
	isValid: boolean,
) {
	switch (fieldConfig.type) {
		case "Text":
			if (fieldConfig.multiline) {
				return (
					<textarea
						className="form-control"
						onChange={(e) => {
							triggerValueChange(e.currentTarget.value);
						}}
						value={(value || "") as string}
						disabled={fieldConfig.disabled}
					/>
				);
			} else {
				return (
					<input
						type="text"
						className={`form-control ${cssHelper.formValidationStateClassNames(isValid)}`}
						value={value || ""}
						onChange={(e) => triggerValueChange(e.currentTarget.value)}
						disabled={fieldConfig.disabled}
					/>
				);
			}
		case "Checkbox":
			return (
				<Checkbox
					initialValue={value}
					onChange={triggerValueChange}
					label={fieldConfig.valueLabel}
					id={fieldId}
					disabled={fieldConfig.disabled}
					isValid={isValid}
				/>
			);
		case "RadioList":
			return (
				<RadioList
					isValid={isValid}
					initialValue={value || ""}
					onChange={(e) => triggerValueChange(e.key)}
					items={Object.values(fieldConfig.datasource)}
					disabled={fieldConfig.disabled}
				/>
			);
		case "RadioButtonGroup":
			return (
				<RadioButtonGroup
					isValid={isValid}
					initialValue={value || ""}
					onChange={(e) => triggerValueChange(e.key)}
					items={Object.values(fieldConfig.datasource)}
					disabled={fieldConfig.disabled}
					className={fieldConfig.className}
				/>
			);
		case "ButtonList":
			return (
				<ButtonGroupFormControl
					isValid={isValid}
					initialValue={value || ""}
					onChange={(e) => triggerValueChange(e?.key)}
					items={Object.values(fieldConfig.datasource)}
					disabled={fieldConfig.disabled}
					className={fieldConfig.className}
				/>
			);
		case "Number":
			return (
				<NumericFormControl
					isValid={isValid}
					initialValue={value}
					onChange={triggerValueChange}
					formatOptions={fieldConfig.format ?? { thousandSeparated: true }}
					disabled={fieldConfig.disabled}
					showClearButton={fieldConfig.hideClearButton !== true}
				/>
			);
		case "List":
			return (
				<SimpleSelectFormControl
					isValid={isValid}
					initialValue={value || ""}
					onChange={(e) => triggerValueChange(e?.key)}
					items={Object.values(fieldConfig.datasource)}
					disabled={fieldConfig.disabled}
				/>
			);
		case "Percent":
			return (
				<PercentFormControl
					isValid={isValid}
					initialValue={value as number}
					onChange={triggerValueChange}
					disabled={fieldConfig.disabled}
					showClearButton={fieldConfig.hideClearButton !== true}
				/>
			);
		case "Period":
			return (
				<PeriodFormControl
					isValid={isValid}
					initialValue={value as PeriodicInfo}
					onChange={triggerValueChange}
					enablePeriodicitySelection={fieldConfig.disableUnitEdit === false}
					disabled={fieldConfig.disabled}
				/>
			);
		case "Underlying":
			return (
				<UnderlyingSelector
					initialValue={value as AthenaUnderlyingId[]}
					onChange={triggerValueChange}
					isValid={undefined}
					disabled={fieldConfig.disabled}
				/>
			);
		case "Date":
			return (
				<DatePickerFormControl
					initialValue={value}
					onChange={triggerValueChange}
					isValid={isValid}
					disabled={fieldConfig.disabled}
				/>
			);
		case "DateRange":
			return (
				<DateRangePickerFormControl
					initialValue={value}
					onChange={triggerValueChange}
					isValid={isValid}
					disabled={fieldConfig.disabled}
				/>
			);
		case "DateList":
			return (
				<DateListFormControl
					initialValue={value || []}
					onChange={triggerValueChange}
					disabled={fieldConfig.disabled}
					isValid={isValid}
				/>
			);
		case "Duration":
			return (
				<DurationInfoFormControl
					initialValue={value ?? { days: 0, months: 0, years: 0 }}
					onChange={triggerValueChange}
					disabled={fieldConfig.disabled}
					isValid={isValid}
				/>
			);
		default:
			return (
				<div className="input-group">
					<span className="text-danger">Unknown Form Control</span>
				</div>
			);
	}
}
export default function AthenaFormControl<TReportConfigKey extends keyof AthenaReportConfiguration>(
	props: PropsWithChildren<AthenaFormControlProps<TReportConfigKey>>,
) {
	const dispatcher = useDispatch();
	const context = useSelector((s: BrochureGeneratorAppState) => {
		if (s.athena.currentReport) {
			return s.athena.currentReport[props.contextKey];
		}
		return undefined;
	});

	const errorMessage: string | undefined = useAthenaValidationStateForField(props.contextKey, props.field);
	const isValid = isNil(errorMessage) || isEmpty(errorMessage);

	let fieldConfig = useSelector((s: BrochureGeneratorAppState) => {
		const contextConfig = s.athena.formState.fieldConfiguration[props.contextKey] as any;
		if (contextConfig) {
			return contextConfig[props.field] as AllFieldsConfigurationTypes | undefined;
		}
	});
	if (props.fieldConfigOverrides && fieldConfig) {
		forEach(props.fieldConfigOverrides, (value, property) => {
			const fieldName = property as keyof BaseFieldConfiguration;
			if (value) {
				fieldConfig = { ...fieldConfig, [fieldName]: value } as any;
			}
		});
	}

	const triggerValueChange = (value: any) => {
		if (props.field) {
			dispatcher(editValueCreatorHelper(props.contextKey, props.field, value));
		}
	};
	let value: any;
	if (context && props.field) {
		value = context[props.field];
	}

	let $control: React.ReactNode | undefined;
	if (fieldConfig) {
		$control = getFormControl(fieldConfig, props.field as string, triggerValueChange, value, isValid);
	}

	return (
		<div
			className={`${cssHelper.getSizeClass(props.size, "col", "col mb-3")} ${fieldConfig?.hidden === true ? "invisible" : ""}`}
		>
			{fieldConfig?.displayLabel !== undefined && (
				<label
					className={`form-label d-block ${["RadioList", "ScheduleLines"].includes(fieldConfig.type) ? "mb-1" : ""}`}
				>
					{fieldConfig?.displayLabel ?? "\u00A0"}
				</label>
			)}
			{$control}
			{!errorMessage && fieldConfig?.description !== undefined && (
				<div className={`form-text fst-italic ${fieldConfig.disabled === true ? "invisible" : ""}`}>
					{fieldConfig.description}
				</div>
			)}
			{errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>}
		</div>
	);
}
