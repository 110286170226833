import { isEmpty } from "lodash";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoadingState } from "Models/LoadingState";
import { BrochureGeneratorAppState } from "Domain/report";
import AthenaSlice from "state/Reports/Athena/Athena.state";
import { useGlobalAthenaValidationMessage } from "state/Reports/Athena/useAthenaValidationStateForField";
import { AthenaForm } from "./AthenaForm";
import GenerateAthenaBrochureButton from "./GenerateAthenaBrochureButton";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

export const AthenaReport: React.FC = () => {
	const athenaState = useSelector((a: BrochureGeneratorAppState) => a.athena);
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();
	const { brochureLoadingState } = athenaState;
	const isLoading = brochureLoadingState === LoadingState.Pending;
	const onReset = () => {
		dispatcher(AthenaSlice.actions.resetReport());
		analyticsMonitoring.functional("Athena", "Reset form");
	};
	const globalValidationError = useGlobalAthenaValidationMessage();

	return (
		<div className="d-flex flex-column h-100  overflow-hidden">
			<div className="p-3 d-flex align-items-center shadow border-bottom">
				<div className="display-4 flex-fill">Athena</div>
			</div>
			<div className="flex-fill position-relative bg-lvl3">
				{isLoading && <div className="prime-overlay"></div>}
				<AthenaForm />
			</div>
			{!isEmpty(globalValidationError) && (
				<div className="bg-lvl3">
					<div className="alert alert-danger m-3">{globalValidationError}</div>
				</div>
			)}
			<div className="p-3 shadow border-top">
				<div className="container-fluid p-0">
					<div className="row">
						<div className="col"></div>
						<div className="col-auto ms-auto">
							<button className="btn btn-xl btn-outline-secondary" disabled={isLoading} onClick={onReset}>
								Reset
							</button>
						</div>
						<div className="col-auto">
							<GenerateAthenaBrochureButton />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
