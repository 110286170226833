import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ReportInfoItem, ReportStatus } from "Domain/report";
import HomePageSlice from "state/HomePage/HomePage.state";
import DownloadBrochureButton from "./DownloadBrochureButton";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";
import { Tooltip } from "@analytics-web-core/components";

export const ReportActionMenu: FC<{ data: ReportInfoItem }> = ({ data }) => {
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();

	const onCloneClick = useCallback(() => {
		dispatcher(HomePageSlice.actions.duplicateBrochure({ brochureId: data.id }));
		analyticsMonitoring.functional("Brochure", "Clone", "", "", {
			name: data.name,
		});
	}, [dispatcher, data.id, data.name, analyticsMonitoring]);

	const onDeleteClick = useCallback(() => {
		dispatcher(HomePageSlice.actions.deleteBrochure({ brochureId: data.id }));
		analyticsMonitoring.functional("Brochure", "Delete", "", "", {
			name: data.name,
		});
	}, [dispatcher, data.id, data.name, analyticsMonitoring]);
	return (
		<div className="hstack w-100 h-100 justify-content-end">
			<DownloadBrochureButton reportInfo={data} disabled={data.status !== ReportStatus.Processed} />

			<Tooltip
				target={"btn-copy"}
				placement="top"
				content={<span>Duplicate brochure</span>}
			>
				<button type="button" id={"btn-copy"} className="btn btn-link btn-icon ms-2" onClick={onCloneClick}>
					<em className="icon">content_copy</em>
				</button>
			</Tooltip>

			<Tooltip
				target={"btn-delete"}
				placement="top"
				content={<span>Delete brochure</span>}
			>
				<button type="button" id={"btn-delete"} className="btn btn-link btn-icon ms-2" title="delete brochures" onClick={onDeleteClick}>
					<em className="icon">delete</em>
				</button>
			</Tooltip>
		</div>
	);
};
