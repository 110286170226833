
import { ReportType } from "Domain/report";
import { TarnReportConfiguration, TarnReportModel } from "Domain/report.tarn";
import { TarnReportConfigurationDto, TarnReportModelDto } from "Domain/reportDto.tarn";
import { tarnTransversalConfigurationMapper } from "./tarnTransversalConfigurationMapper";

function mapEntityToDto(entity: TarnReportConfiguration, tradeReference: string | undefined): TarnReportModelDto {
	const configdto: TarnReportConfigurationDto = {
		transversalConfiguration: tarnTransversalConfigurationMapper.mapEntityToDto(entity.transversalConfiguration),

	};
	const dto: TarnReportModelDto = {
		reportConfiguration: configdto,
		type: ReportType.Tarn,
		tradeReference: tradeReference
	};
	return dto;
}
function mapDtoToEntity(dto: TarnReportModelDto): TarnReportModel {
	const reportConfig: TarnReportConfiguration = {
		transversalConfiguration: tarnTransversalConfigurationMapper.mapDtoToEntity(dto.reportConfiguration.transversalConfiguration),
	};
	const model: TarnReportModel = {
		type: ReportType.Tarn,
		configuration: reportConfig,
		tradeReference: dto.tradeReference
	};
	return model;
}

export const tarnReportModelMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};

