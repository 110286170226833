import { TarnScheduleLine } from "Domain/report.tarn";
import { TarnScheduleLineDto } from "Domain/reportDto.tarn";
import { toDateOnly, toJSDate } from "Utilities/date";


function mapEntityToDto(entity: TarnScheduleLine): TarnScheduleLineDto {
	const dto: TarnScheduleLineDto = {
		periodNumber: entity.periodNumber,
		paymentDate: toDateOnly(entity.paymentDate),
		isAutocallable: entity.isAutocallable,
		autocallBarrier: entity.autocallBarrier,
		isFloatingCouponApplicable: entity.isFloatingCouponApplicable,
		isFloatingCouponLeveraged: entity.isFloatingCouponLeveraged,
		leverage: entity.leverage,
		isFixedCouponApplicable: entity.isFixedCouponApplicable,
		fixedCoupon: entity.fixedCoupon,
	};
	return dto;
}
function mapDtoToEntity(dto: TarnScheduleLineDto): TarnScheduleLine {
	const entity: TarnScheduleLine = {
		periodNumber: dto.periodNumber,
		paymentDate: toJSDate(dto.paymentDate)!,
		isAutocallable: dto.isAutocallable,
		autocallBarrier: dto.autocallBarrier,
		isFloatingCouponApplicable: dto.isFloatingCouponApplicable,
		isFloatingCouponLeveraged: dto.isFloatingCouponLeveraged,
		leverage: dto.leverage,
		isFixedCouponApplicable: dto.isFixedCouponApplicable,
		fixedCoupon: dto.fixedCoupon,
	};
	return entity;
}
export const tarnScheduleLineMappers = {
	mapDtoToEntity,
	mapEntityToDto,
};
