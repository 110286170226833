import { useMemo } from "react";
import { isEmpty, startsWith, chain } from "lodash";
import { useSelector } from "react-redux";
import { BrochureGeneratorAppState } from "Domain/report";
import { PhoenixBearishReportConfiguration } from "Domain/report.phoenixBearish";

export function usePhoenixBearishValidationStateForField<
	TReportConfigKey extends keyof PhoenixBearishReportConfiguration,
	TFieldKey extends keyof PhoenixBearishReportConfiguration[TReportConfigKey],
>(key: TReportConfigKey, field: TFieldKey): string | undefined {
	const validationState = useSelector((s: BrochureGeneratorAppState) => s.phoenixBearish.formState.validationState);
	const fullPath = useMemo(() => `${key}.${field as string}`, [field, key]);
	const result: string[] = chain(validationState)
		.reduce((acc: string[], value, key) => {
			if (startsWith(key, fullPath)) {
				acc.push(value);
			}
			return acc;
		}, [])
		.uniq()
		.value();
	if (!isEmpty(result)) {
		return result.join(", ");
	}
	return undefined;
}
