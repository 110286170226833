import { isEmpty, toLower } from 'lodash';

export const getPtpId = (xOneId: string) => {
	let ptpId = toLower(xOneId).trim();

	if (isEmpty(ptpId)) {
		return undefined;
	}

	if (!ptpId.startsWith('ptp-')) {
		ptpId = `ptp-${ptpId}`;
	}

	return ptpId;
};
