import { AgGridReact } from 'ag-grid-react';
import { DateTime } from 'luxon';
import { ColDef, GridOptions } from 'ag-grid-enterprise';
import { toUpper } from 'lodash';
import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReportInfoItem, ReportStatus } from 'Domain/report';
import { LoadingState } from 'Models/LoadingState';
import HomePageSlice from 'state/HomePage/HomePage.state';
import Loader from 'components/Shared/Loader/Loader';
import { ReportActionMenu } from './ReportActionMenu';
import { StatusBadge } from './StatusBadge';
import { BrochureGeneratorAppState } from 'Domain/report';

interface UserReportsProps {
	className?: string;
}

function dateAndTimeValueFormatter(params: { value: Date }): string {
	return DateTime.fromJSDate(params.value).toFormat('yyyy LLL dd HH:mm');
}

export const UserReports: React.FC<UserReportsProps> = () => {
	const reports = useSelector((a: BrochureGeneratorAppState) => a.homepage.reports);
	const reportsLoadingState = useSelector((a: BrochureGeneratorAppState) => a.homepage.reportsLoadingState);

	const dispatcher = useDispatch();
	useEffect(() => {
		if (reportsLoadingState === LoadingState.Idle) {
			dispatcher(HomePageSlice.actions.loadReports());
		}
	}, [dispatcher, reportsLoadingState]);

	const onTryAgainClick = () => {
		dispatcher(HomePageSlice.actions.loadReports());
	};

	let $loader: ReactNode;
	if (reportsLoadingState === LoadingState.Pending) {
		$loader = (
			<div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center z-3">
				<div className="bg-lvl1 border border-primary">
					<div className="text-center  p-4">
						<Loader size="lg" />
						<div className="fs-1 mb-2">Loading reports...</div>
					</div>
				</div>
			</div>
		);
	}

	if (reportsLoadingState === LoadingState.Error) {
		return (
			<div className="col-auto mx-auto my-4">
				<div className="text-center">
					<div className="fs-1 mb-2">An error occured while loading the reports</div>
					<button className="btn btn-primary" onClick={onTryAgainClick}>
						Please try again
					</button>
				</div>
			</div>
		);
	}

	const coldef: Array<ColDef<ReportInfoItem>> = [
		{
			field: 'id',
			maxWidth: 30,
			cellClass: 'text-center',
			cellRenderer: 'agGroupCellRenderer',
			floatingFilter: false,
			filter: false,
			valueFormatter: (_) => '',
			useValueFormatterForExport: false
		},
		{
			field: 'status',
			maxWidth: 150,
			cellRenderer: (props: { value: ReportStatus }) => <StatusBadge status={props.value} />,
		},

		{
			field: 'type',
			maxWidth: 150,
			cellClass: 'text-center',
		},

		{ field: 'name', flex: 2 },
		{ field: 'isin', flex: 1 },
		{ field: 'tradeReference', flex: 1, valueFormatter: (e) => toUpper(e.value) },
		{
			field: 'creationDate',
			maxWidth: 200,
			filter: 'agDateColumnFilter',
			valueFormatter: dateAndTimeValueFormatter,
			initialSort: 'desc',
			filterValueGetter: (a) => {
				return DateTime.fromJSDate(a.data!.creationDate).startOf('day').toJSDate();
			},
		},
		{ cellRenderer: ReportActionMenu, sortable: false, minWidth: 450, maxWidth: 450, filter: false, floatingFilter: false, headerName: 'Action' },
	];

	const gridOptions: GridOptions = {
		columnDefs: coldef,
		defaultColDef: { flex: 1, filter: 'agTextColumnFilter', floatingFilter: true, resizable: true, sortable: true },
		domLayout: 'normal',
		rowClassRules: {
			'text-danger bg-danger bg-opacity-20': (p: { data: { status: ReportStatus } }) => {
				return p.data?.status === ReportStatus.Error;
			},
		},
		rowHeight: 42,
		headerHeight: 42,
		statusBar: {
			statusPanels: [
				{
					statusPanel: 'agTotalAndFilteredRowCountComponent',
					align: 'left',
				},
			],
		},
		getRowId: (a) => {
			const tmpReport = a.data as ReportInfoItem;
			const rowId = `${tmpReport.id}-${tmpReport.status}`;
			return rowId;
		},
		masterDetail: true,
		isRowMaster: (item: ReportInfoItem) => {
			return item.errors.length > 0 || item.status === ReportStatus.Error;
		},
		detailRowAutoHeight: true,
		detailCellRendererParams: {
			getDetailRowData: (params: { data: ReportInfoItem; successCallback: any }) => {
				const errors = params.data.errors;
				params.successCallback(errors || []);
			},
			detailGridOptions: {
				columnDefs: [
					{ field: 'errorMessage', flex: 1, suppressHeaderMenuButton: true },
					{ field: 'fields', flex: 1, headerName: 'Context', suppressHeaderMenuButton: true },
				],
			},
		},
	};
	return (
		<>
			{$loader}
			<div className="ag-theme-sg-bootstrap h-100">
				<AgGridReact gridOptions={gridOptions} rowData={reports} />
			</div>
		</>
	);
};
