import { BaseWidgetProps, NavigateAsItem } from '@analytics-web-core/components';
import * as React from 'react';
import { forwardRef } from 'react';
import { convertOptionsToWIdgetAttributes } from './WidgetConfig';


export type SGWTAccountCenterProps = BaseWidgetProps & {
	authentication?: 'sg-connect-v2';
	environment?: string;
	mode?: string;
	availableLanguages?: string;
	language?: string;
	producerCode?: string;
	showSignInButton?: boolean;
	showPersonalisation?: boolean;
	showLanguagesSelection?: boolean;
	themeSwitcher?: boolean;
	navigateAs?: boolean;
	navigateAsList?: NavigateAsItem[];
	debug?: boolean;
};

export const SGWTAccountCenter = forwardRef((props: SGWTAccountCenterProps, ref) => {
	const defaultOptions: SGWTAccountCenterProps = {
		id: 'analyticsAccountCenter',
		authentication: 'sg-connect-v2',
		mode: 'sg-markets',
		availableLanguages: 'en',
		navigateAs: true,
		showPersonalisation: false,
		themeSwitcher: false,
	};

	const options: SGWTAccountCenterProps = {
		...defaultOptions,
		...props,
	};
	const widgetOptions = convertOptionsToWIdgetAttributes(options);

	return React.createElement('sgwt-account-center', { ...widgetOptions, ref });
});
