import { call, takeEvery, put, select } from "typed-redux-saga";
import { BrochureGeneratorApi } from "Api/brochureGeneratorApi";
import { isEmpty } from "lodash";
import { globalNavigate } from "components/App/GlobalHistory";
import { primeToaster } from "components/App/PrimeToasterContainer";
import { tauxFixeReportModelMapper } from "Mappers/TauxFixe/tauxFixeReportModelMapper";
import TauxFixeSlice from "./tauxFixe.state";
import { BrochureGeneratorAppState } from "Domain/report";

const api = new BrochureGeneratorApi();

export function* tauxFixeSaga(): Generator {
	yield* takeEvery(TauxFixeSlice.actions.generateBrochure, onGenerateBrochure);
	yield* takeEvery(TauxFixeSlice.actions.generateBrochureSuccess, onGenerateBrochureSuccess);
	yield* takeEvery(TauxFixeSlice.actions.generateBrochureError, onGenerateBrochureError);
}

function* onGenerateBrochure(action: ReturnType<typeof TauxFixeSlice.actions.generateBrochure>) {
	const { currentReport, formState, tradeReference } = yield* select(
		(state: BrochureGeneratorAppState) => state.tauxFixe,
	);

	// Form is invalid
	if (!isEmpty(formState.validationState)) {
		return;
	}

	try {
		const reportDto = tauxFixeReportModelMapper.mapEntityToDto(currentReport, tradeReference);
		yield* call(api.createTauxFixeReport, reportDto);

		yield* put(TauxFixeSlice.actions.generateBrochureSuccess());
	} catch (err: any) {
		console.error(err);

		yield* put(TauxFixeSlice.actions.generateBrochureError());
	}
}

function onGenerateBrochureSuccess() {
	primeToaster.info("TauxFixe", "Brochure is being generated.", "info");
	globalNavigate("/");
}

function onGenerateBrochureError() {
	primeToaster.danger("Generate brochure", "Unable to generate the TauxFixe report.");
}
