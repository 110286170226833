import * as React from "react";

export interface TextInputProps {
	value: string;
	placeholder?: string;
	isWarning?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	onChange: (value: string) => void;
}

export const TextInput: React.FC<TextInputProps> = ({ value, isWarning, onChange, disabled, readOnly, placeholder }) => (
	<input
		type="text"
		disabled={disabled}
		placeholder={placeholder}
		readOnly={readOnly}
		className={`form-control ${isWarning ? "is-warning" : ""}`}
		value={value}
		onChange={(e) => onChange(e.currentTarget.value)}
	/>
);
