import { AxiosError } from "axios";
import { isNil, isEmpty } from "lodash";
import { all, call, delay, put, race, select, take, takeEvery, takeLatest } from "typed-redux-saga";
import { ReportInfoItem, ReportStatus, ReportType, ReportDocumentType, BrochureGeneratorAppState } from "Domain/report";
import { downloadFile } from "Utilities/downloadFile";
import { GlobalNavigation } from "components/App/GlobalHistory";
import { primeToaster } from "components/App/PrimeToasterContainer";
import AthenaSlice from "../Reports/Athena/Athena.state";
import { createEmptyAthenaReport } from "../Reports/Athena/createDefaultAthenaReport";
import { createDefaultTarnReport } from "../Reports/Tarn/createDefaultTarnReport";
import TarnSlice from "../Reports/Tarn/tarn.state";
import { createDefaultTauxFixeReport } from "../Reports/TauxFixe/createDefaultTauxFixeReport";
import TauxFixeSlice from "../Reports/TauxFixe/tauxFixe.state";
import HomePageSlice from "./HomePage.state";
import { showBrochureToastSuccess } from "./BrochureMessage";
import { MimeType } from "Utilities/mimeTypes";
import { BrochureGeneratorApi } from "Api/brochureGeneratorApi";
import PhoenixBearishSlice from "state/Reports/PhoenixBearish/PhoenixBearish.state";
import { createDefaultPhoenixBearishReport } from "state/Reports/PhoenixBearish/createDefaultPhoenixBearishReport";

const api = new BrochureGeneratorApi();

export function* homepageSaga(): Generator {
	yield* takeLatest(
		[
			HomePageSlice.actions.deleteBrochureSuccess,
			HomePageSlice.actions.setImpersonatedUserEmail,
			AthenaSlice.actions.generateBrochureSuccess,
			TarnSlice.actions.generateBrochureSuccess,
			TauxFixeSlice.actions.generateBrochureSuccess,
			PhoenixBearishSlice.actions.generateBrochureSuccess
		],
		triggerLoadReports,
	);
	yield* takeLatest(HomePageSlice.actions.loadReports, loadReports);
	yield* takeEvery(HomePageSlice.actions.setReportStatus, onBrochureProcessed);
	yield* takeEvery(HomePageSlice.actions.duplicateBrochure, onDuplicateBrochure);
	yield* takeEvery(HomePageSlice.actions.deleteBrochure, onDeleteBrochure);
	yield* takeEvery(HomePageSlice.actions.initReport, onInitReport);
	yield* takeEvery(HomePageSlice.actions.downloadBrochure, onDownloadBrochure);
}
/* Worker Function */
const STOP_WATCH_TASK_TYPE = (suffix: string) => "STOP_WATCHER_TASK_" + suffix;
function* pollReportStatus(report: ReportInfoItem) {
	while (true) {
		try {
			const reportWithStatus = yield* call(api.getReportStatus, report.id);

			if (reportWithStatus.status === ReportStatus.Processed) {
				yield* put(HomePageSlice.actions.setReportStatus({ report, status: ReportStatus.Processed }));
			}

			if (reportWithStatus.status !== ReportStatus.Processing) {
				yield* put(HomePageSlice.actions.loadReports());
				yield* put({ type: STOP_WATCH_TASK_TYPE(report.id) });
			}

			yield* delay(2000);
		} catch (err: any) {
			console.error(err);

			yield* put({
				type: "ON_POST_FETCH_ERROR",
				message: err.message,
			});
			// Once the polling has encountered an error,
			// it should be stopped immediately
			yield* put({ type: STOP_WATCH_TASK_TYPE(report.id) });
		}
	}
}
function* triggerLoadReports() {
	yield* delay(1000);
	yield* put(HomePageSlice.actions.loadReports());
}
/* Watcher Function */
function* reportStatusWorkers() {
	const reports = yield* select((state: BrochureGeneratorAppState) => state.homepage.reports);
	const processingReports = reports.filter(
		(report) => report.status === ReportStatus.Processing || report.status === ReportStatus.Created,
	);

	yield* all(
		processingReports.map((report) => race([call(pollReportStatus, report), take(STOP_WATCH_TASK_TYPE(report.id))])),
	);
}

export function* loadReports() {
	try {
		const impersonatedUserEmail = yield* select(
			(state: BrochureGeneratorAppState) => state.homepage.impersonatedUserEmail,
		);
		const reports = yield* call(api.loadReports, impersonatedUserEmail);
		// Load reports
		yield* put(HomePageSlice.actions.setReports({ reports }));
		// Start worker for "Processing" reports
		yield* call(reportStatusWorkers);
	} catch (e) {
		console.error(e);

		yield* put(HomePageSlice.actions.setReportsError());
	}
}
export function onBrochureProcessed(action: ReturnType<typeof HomePageSlice.actions.setReportStatus>) {
	const { report } = action.payload;
	showBrochureToastSuccess(report);
}

export function* onDuplicateBrochure(action: ReturnType<typeof HomePageSlice.actions.duplicateBrochure>): Generator {
	const brochureId = action.payload.brochureId;
	const impersonatedUserEmail = yield* select(
		(state: BrochureGeneratorAppState) => state.homepage.impersonatedUserEmail,
	);
	const reportModel = yield* call(api.getAthenaReportConfiguration, brochureId, impersonatedUserEmail);

	if (!isNil(reportModel) && reportModel.type === ReportType.Athena) {
		// try to take the latest value of underlyings
		const loadedUnderlyings = yield* select((state: BrochureGeneratorAppState) => state.athena.availableUnderlyings);
		reportModel.configuration.underlyingConfiguration.underlyings =
			reportModel.configuration.underlyingConfiguration.underlyings.map((originalUdl: any) => {
				const newUnder = loadedUnderlyings.find((u) => u.bloombergTickerCode === originalUdl.bloombergTickerCode);
				return newUnder ?? originalUdl;
			});

		yield* put(
			AthenaSlice.actions.initReportSuccess({
				reportConfiguration: reportModel.configuration!,
				tradeReference: reportModel.tradeReference,
			}),
		);
		GlobalNavigation.navigateTo("NewAthenaReport");
	} else if (!isNil(reportModel) && reportModel.type === ReportType.Tarn) {
		yield* put(
			TarnSlice.actions.initReportSuccess({
				reportConfiguration: reportModel.configuration!,
				tradeReference: reportModel.tradeReference,
			}),
		);
		GlobalNavigation.navigateTo("NewTarnReport");
	} else if (!isNil(reportModel) && reportModel.type === ReportType.TauxFixe) {
		yield* put(
			TauxFixeSlice.actions.initReportSuccess({
				reportConfiguration: reportModel.configuration!,
				tradeReference: reportModel.tradeReference,
			}),
		);
		GlobalNavigation.navigateTo("NewTauxFixeReport");
	} else if (!isNil(reportModel) && reportModel.type === ReportType.PhoenixBearish) {
		yield* put(
			PhoenixBearishSlice.actions.initReportSuccess({
				reportConfiguration: reportModel.configuration!,
				tradeReference: reportModel.tradeReference,
			}),
		);
		GlobalNavigation.navigateTo("NewPhoenixBearishReport");
	} else {
		yield* put(AthenaSlice.actions.initReportNotManaged());
		primeToaster.danger("Error", "Unable to initialise a Tarn report for the moment :'( Sorry");
	}
}
export function* onDeleteBrochure(action: ReturnType<typeof HomePageSlice.actions.deleteBrochure>): Generator {
	const brochureId = action.payload.brochureId;
	const isSuccess = yield* call(api.deleteReport, brochureId);
	if (!isSuccess) {
		primeToaster.danger("Error", "Unable to delete the report");
	} else {
		yield* put(HomePageSlice.actions.deleteBrochureSuccess());
		primeToaster.success("Success", "Report deleted");
	}
}

function* onInitReport(action: ReturnType<typeof HomePageSlice.actions.initReport>): Generator {
	if (!isEmpty(action.payload.ptpId) && !isNil(action.payload.ptpId)) {
		try {
			const reportModel = yield* call(api.initReportFromXOneId, action.payload.ptpId, action.payload.reportType);

			if (!isNil(reportModel) && reportModel.type === ReportType.Athena) {
				yield* put(
					AthenaSlice.actions.initReportSuccess({
						reportConfiguration: reportModel.configuration!,
						tradeReference: action.payload.ptpId,
					}),
				);
				GlobalNavigation.navigateTo("NewAthenaReport");
			} else if (!isNil(reportModel) && reportModel.type === ReportType.PhoenixBearish) {
				yield* put(
					PhoenixBearishSlice.actions.initReportSuccess({
						reportConfiguration: reportModel.configuration!,
						tradeReference: action.payload.ptpId,
					}),
				);
				GlobalNavigation.navigateTo("NewPhoenixBearishReport");
			} else if (!isNil(reportModel) && reportModel.type === ReportType.Tarn) {
				yield* put(
					TarnSlice.actions.initReportSuccess({
						reportConfiguration: reportModel.configuration!,
						tradeReference: action.payload.ptpId,
					}),
				);
				GlobalNavigation.navigateTo("NewTarnReport");
			} else if (!isNil(reportModel) && reportModel.type === ReportType.TauxFixe) {
				yield* put(
					TauxFixeSlice.actions.initReportSuccess({
						reportConfiguration: reportModel.configuration!,
						tradeReference: action.payload.ptpId,
					}),
				);
				GlobalNavigation.navigateTo("NewTauxFixeReport");
			} else {
				yield* put(AthenaSlice.actions.initReportNotManaged());
				primeToaster.danger("Error", "Unable to initialise a Tarn report for the moment :'( Sorry");
			}
		} catch (e: unknown) {
			console.error(e);
			const t = e as AxiosError;
			yield* put(HomePageSlice.actions.createReportFromXOneError({ errorCode: t.response?.status ?? 500 }));
		}
	} else {
		if (action.payload.reportType === ReportType.Athena) {
			yield* put(
				AthenaSlice.actions.initReportSuccess({
					reportConfiguration: createEmptyAthenaReport().configuration,
					tradeReference: undefined,
				}),
			);
			GlobalNavigation.navigateTo("NewAthenaReport");
		} else if (action.payload.reportType === ReportType.PhoenixBearish) {
			yield* put(
				PhoenixBearishSlice.actions.initReportSuccess({
					reportConfiguration: createDefaultPhoenixBearishReport(),
					tradeReference: undefined,
				}),
			);
			GlobalNavigation.navigateTo("NewPhoenixBearishReport");
		} else if (action.payload.reportType === ReportType.TarnZC) {
			yield* put(
				TarnSlice.actions.initReportSuccess({
					reportConfiguration: createDefaultTarnReport(true),
					tradeReference: undefined,
				}),
			);
			GlobalNavigation.navigateTo("NewTarnReport");
		} else if (action.payload.reportType === ReportType.Tarn) {
			yield* put(
				TarnSlice.actions.initReportSuccess({
					reportConfiguration: createDefaultTarnReport(false),
					tradeReference: undefined,
				}),
			);
			GlobalNavigation.navigateTo("NewTarnReport");
		} else if (action.payload.reportType === ReportType.TauxFixe) {
			yield* put(
				TauxFixeSlice.actions.initReportSuccess({
					reportConfiguration: createDefaultTauxFixeReport(),
					tradeReference: undefined,
				}),
			);
			GlobalNavigation.navigateTo("NewTauxFixeReport");
		}
	}
}

function* onDownloadBrochure(action: ReturnType<typeof HomePageSlice.actions.downloadBrochure>): Generator {
	try {
		const report = action.payload.report;
		const reportFile = yield* call(api.downloadReport, report.id, action.payload.documentType);

		let filename = report.name;
		let fileExtension = "";
		let mimeType: MimeType;
		switch (action.payload.documentType) {
			case ReportDocumentType.BrochurePdf:
				fileExtension = "pdf";
				mimeType = MimeType.Pdf;
				break;
			case ReportDocumentType.TraTable:
				fileExtension = "xlsx";
				filename += " TRA Table";
				mimeType = MimeType.Xlsx;
				break;
			default:
				fileExtension = "pptx";
				mimeType = MimeType.Pptx;
				break;
		}

		downloadFile({
			content: reportFile,
			fileExtension: fileExtension,
			filename: filename,
			mimeType: mimeType,
		});
		if (action.payload.success) {
			action.payload.success();
		}
	} catch {
		if (action.payload.error) {
			action.payload.error();
		} else {
			primeToaster.danger("Download", "Unable to download the report.");
		}
	}
}
