import { ProductCurrency, ProductLaw, DefaultColorPreset, Periodicity } from "Domain/report";
import { TarnReportConfiguration, tarnUnderlyings, CouponDisplayMode } from "Domain/report.tarn";

export function createDefaultTarnReport(hasZc: boolean): TarnReportConfiguration {
	const reportConfig: TarnReportConfiguration = {
		transversalConfiguration: {
			productName: '',
			productGuarantor: 'Société Générale',
			productCotation: 'Bourse de Luxembourg',
			productIssuer: 'SG Issuer',
			productDenomination: 1_000,
			issuePrice: 1,
			priceRate: 0,
			rateCurrency: 'EUR',
			productCurrency: ProductCurrency.EUR,
			minimumInvestment: 1000,
			productLaw: ProductLaw.French,
			calculationAgent: 'Société Générale',
			isin: '',
			mtmPublication: '',
			firstUnderlying: tarnUnderlyings.find((u) => u.maturityYears === 30)!,
			secondUnderlying: tarnUnderlyings.find((u) => u.maturityYears === 5)!,
			bidOffer: 0.01,
			colorPreset: DefaultColorPreset,
			pictureId: '9f48d53a-d08c-4937-8218-fa9566ea1be6',
			scheduleLines: [],
			commission: 0.01,
			feesPercent: 0.01,
			hasLeverage: false,
			observationPeriodicity: Periodicity.Years,
			hasZc,
			couponDisplayMode: CouponDisplayMode.TotalCoupons,
			productDuration: { years: 0, months: 0, days: 0 },
		},
	};
	return reportConfig;
}
