import { ReportStatus } from "Domain/report";
import { getEnumValue } from "Utilities/enum";


function mapDtoToEntity(key: string): ReportStatus {
	return getEnumValue(ReportStatus, key, ReportStatus.Unknown);
}

export const reportStatusMapper = {
	mapDtoToEntity,
};
