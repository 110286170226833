import * as React from 'react';

export interface TextAreaProps {
	height?: string;
	value: string;
	placeholder?: string;
	className?: string;
	isWarning?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	onChange: (value: string) => void;
}

export const TextArea: React.FC<TextAreaProps> = ({ value, isWarning, onChange, disabled, readOnly, placeholder, height, className }) => (
	<textarea
		value={value}
		onChange={(e) => onChange(e.currentTarget.value)}
		className={`form-control ${isWarning ? 'is-warning' : ''} ${className ?? ""}`}
		disabled={disabled}
		placeholder={placeholder}
		readOnly={readOnly}
		style={{ height }}
	></textarea>
);
