import { LabelsConfiguration } from "components/Shared/FormControls/FieldConfiguration";
import { SimpleSelectFormControl } from "components/Shared/FormControls/SimpleSelectFormControl";
import { TextInput } from "components/Shared/FormControls/TextInput";
import React, { useState, useMemo, useEffect, useCallback, ReactNode } from "react";
import { primeToaster } from "components/App/PrimeToasterContainer";
import NumericFormControl from "components/Shared/FormControls/NumericFormControl";
import { TextArea } from "components/Shared/FormControls/TextArea";
import { CustomNotesEditor } from "components/Shared/RichTextEditor/RichTextEditor";
import ResourcesSlice, {
	UnderlyingItemType,
	UnderlyingItem,
	createNewUnderlyingItem,
} from "state/Resources/Resources.state";
import { UnderlyingTypeBadge } from "./UnderlyingTypeBadge";
import { BrochureGeneratorAppState } from "Domain/report";
import { useSelector, useDispatch } from "react-redux";
import { isNil } from "lodash";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

const underlyingTypesItem: LabelsConfiguration<UnderlyingItemType> = {
	Fund: {
		key: "Fund",
		label: "Fund",
	},
	Stock: {
		key: "Stock",
		label: "Stock",
	},
	Indice: {
		key: "Indice",
		label: "Indice",
	},
};
type UnderlyingFormProps = {
	underlying: UnderlyingItem;
	isNew: boolean;
	onClose: () => void;
	onDuplicate: () => void;
};

type FormFielConfig = {
	label: string;
	field: keyof UnderlyingItem;
	type: "string" | "stringmulti" | "number" | "html" | "list";
	readOnly?: boolean;
	datasource?: LabelsConfiguration;
};

function getFormFieldConfig(type: UnderlyingItemType, isNew: boolean): FormFielConfig[] {
	const commonFields: FormFielConfig[] = [
		{
			field: "underlyingType",
			label: "Underlying Type",
			type: "list",
			readOnly: isNew !== true,
			datasource: underlyingTypesItem,
		},
		{ field: "ticker", label: "Ticker", type: "string", readOnly: isNew !== true },
		{ field: "isin", label: "ISIN", type: "string" },
		{ field: "name", label: "Name", type: "string" },
		{ field: "commercialName", label: "Commercial name", type: "string" },
		{ field: "globalDescription", label: "Global description", type: "html" },
		{ field: "lastStrike", label: "Last Close", type: "number" },
	];

	const fieldsFromUnderlyingType: Record<UnderlyingItemType, FormFielConfig[]> = {
		Fund: [
			...commonFields,
			{ field: "risks", label: "Risks", type: "html" },
			{ field: "fundComparison", label: "Fund comparison", type: "string" },
			{ field: "inconvenients", label: "Inconvenients", type: "stringmulti" }, // Inséré dans un bullet, pas d'html pour le moment
		],
		Indice: [
			...commonFields,
			{ field: "legend", label: "Legend", type: "string" },
			{ field: "launch", label: "Launch", type: "string" },
			{ field: "longName", label: "Long name", type: "string" },
			{ field: "globalDisclaimer", label: "Global disclaimer", type: "html" },
			{ field: "adjustedName", label: "Adjusted name", type: "string" },
			{ field: "decrement", label: "Decrement", type: "stringmulti" }, // Inséré dans un bullet, pas d'html pour le moment
			{ field: "decrementComparison", label: "Decrement comparison", type: "string" },
			{ field: "licensorWebsite", label: "Licensor website", type: "string" },
			{ field: "externalSource", label: "External source", type: "string" },
		],
		Stock: [
			...commonFields,
			{ field: "creation", label: "Creation", type: "string" },
			{ field: "secteur", label: "Secteur", type: "string" },
			{ field: "notationSNP", label: "Notation SNP", type: "string" },
			{ field: "notationMoodys", label: "Notation Moodys", type: "string" },
			{ field: "siteInternet", label: "Site internet", type: "string" },
			{ field: "chiffreDaffaires", label: "Chiffre d'affaires", type: "stringmulti" },
			{ field: "resultatNet", label: "Resultat Net", type: "stringmulti" },
			{ field: "endettementNet", label: "Endettement Net", type: "stringmulti" },
			{ field: "capitauxPropres", label: "Capitaux propres", type: "stringmulti" },
		],
	};
	return fieldsFromUnderlyingType[type];
}

export const UnderlyingForm: React.FC<UnderlyingFormProps> = ({ underlying, isNew, onClose, onDuplicate }) => {
	const [currentUnderlying, setCurrentUnderlying] = useState(underlying);
	const allUnderlyings = useSelector((s: BrochureGeneratorAppState) => s.resources.allUnderlyings);
	const [isLoading, setIsLoading] = useState(false);
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();

	const alreadyExistingUnderlying = useMemo(() => {
		return allUnderlyings.find((u) => u.ticker === currentUnderlying.ticker);
	}, [allUnderlyings, currentUnderlying.ticker]);

	useEffect(() => {
		setCurrentUnderlying({ ...createNewUnderlyingItem(), ...underlying });
	}, [underlying]);
	const onChange = (prop: keyof UnderlyingItem) => (value: any) => {
		setCurrentUnderlying({
			...currentUnderlying,
			[prop]: value,
		});
	};

	const fieldConfiguration = useMemo(
		() => getFormFieldConfig(currentUnderlying.underlyingType, isNew),
		[isNew, currentUnderlying.underlyingType],
	);

	const simpleFields = useMemo(() => {
		return fieldConfiguration.filter((f) => f.type !== "html");
	}, [fieldConfiguration]);
	const htmlFields = useMemo(() => {
		return fieldConfiguration.filter((f) => f.type === "html");
	}, [fieldConfiguration]);

	const onDeleteClick = useCallback(() => {
		setIsLoading(true);
		dispatcher(
			ResourcesSlice.actions.deleteUnderlying({
				ticker: currentUnderlying.ticker,
				success: () => {
					setIsLoading(false);
					primeToaster.success("", "Underlying deleted");
					onClose();
				},
				error: (error) => {
					setIsLoading(false);
					primeToaster.danger("Error", `Unable to delete underlying - ${error}`);
				},
			}),
		);
	}, [currentUnderlying.ticker, dispatcher, onClose]);
	const onSaveClick = useCallback(() => {
		setIsLoading(true);
		dispatcher(
			ResourcesSlice.actions.updateUnderlying({
				underlying: currentUnderlying,
				success: () => {
					setIsLoading(false);
					primeToaster.success("", "Underlying updated");
					onClose();
				},
				error: (error) => {
					setIsLoading(false);
					primeToaster.danger("Error", `Unable to update underlying - ${error}`);
				},
			}),
		);
		analyticsMonitoring.functional("Underlyings", "Save");
	}, [analyticsMonitoring, currentUnderlying, dispatcher, onClose]);

	return (
		<div className="position-absolute h-100 w-100">
			<div className="d-flex flex-column h-100">
				<div className="shadow border-bottom py-2 ">
					<div className="container-fluid">
						<div className="row">
							<div className="col">
								<div className="display-3" title={`Technical Id : ${currentUnderlying.id}`}>
									<sup className="me-3">
										<UnderlyingTypeBadge underlyingType={currentUnderlying.underlyingType} size="md" />
									</sup>
									{currentUnderlying.name}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-fill position-relative">
					<div className="position-absolute h-100 w-100 pt-4" style={{ overflowY: "auto" }}>
						<div className="container-fluid">
							{isNew && alreadyExistingUnderlying && (
								<div className="alert alert-danger">
									There is already another underlying with the same Ticker ({currentUnderlying.ticker})
								</div>
							)}
							<div className="row">
								<div className="col-5">
									<div className="row">
										{simpleFields.map((f) => {
											let $control: ReactNode = (
												<TextInput
													value={currentUnderlying[f.field]?.toString() ?? ""}
													onChange={onChange(f.field)}
													disabled={f.readOnly === true}
												/>
											);
											if (f.type === "number") {
												$control = (
													<NumericFormControl
														initialValue={currentUnderlying[f.field] as number | undefined}
														onChange={onChange(f.field)}
														isValid={undefined}
														disabled={f.readOnly === true}
													/>
												);
											}
											if (f.type === "stringmulti") {
												$control = (
													<TextArea
														value={currentUnderlying[f.field]?.toString() ?? ""}
														onChange={onChange(f.field)}
														height="200px"
														disabled={f.readOnly === true}
													/>
												);
											}
											if (f.type === "list") {
												$control = (
													<SimpleSelectFormControl
														items={Object.values(f.datasource ?? [])}
														initialValue={currentUnderlying[f.field]?.toString() ?? ""}
														isValid={undefined}
														onChange={(e) => onChange(f.field)(e?.key)}
														disabled={f.readOnly === true}
														hideEmptyOption
													/>
												);
											}
											return (
												<div className="col-12 mb-2" key={f.field}>
													<div className="form-label">{f.label}</div>
													{$control}
												</div>
											);
										})}
									</div>
								</div>
								<div className="col">
									{htmlFields.map((f) => {
										return (
											<div className="col-12 mb-2" key={f.field}>
												<div className="form-label">{f.label}</div>
												<CustomNotesEditor
													initialContent={currentUnderlying[f.field]?.toString()}
													onChange={onChange(f.field)}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid shadow border-top py-2">
					<div className="row">
						<div className="col">
							{!isNew && (
								<button className="btn btn-lg btn-discreet-danger" onClick={onDeleteClick}>
									Delete
								</button>
							)}
						</div>
						{!isNew && (
							<div className="col-auto">
								<button className="btn btn-lg btn-icon-start btn-discreet-info" onClick={onDuplicate}>
									<i className="icon">content_copy</i> Duplicate
								</button>
							</div>
						)}
						<div className="col-auto">
							<button className="btn btn-lg btn-flat-secondary" onClick={onClose}>
								Cancel
							</button>
						</div>
						<div className="col-auto">
							<button
								className="btn btn-lg btn-icon-start btn-primary"
								onClick={onSaveClick}
								disabled={isLoading || (isNew && !isNil(alreadyExistingUnderlying))}
							>
								<i className="icon">{isNew ? "add" : "edit"}</i>
								{isNew ? "Create underlying" : "Update underlying"}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
