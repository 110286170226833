import { isEmpty } from "lodash";
import React, { FC } from "react";
import sharedEn from "Locales/shared.en.json";

export type MessageKey = keyof typeof sharedEn;

interface Props {
	id: MessageKey;
	defaultMessage?: string;
}

export const FormatMessage: FC<Props> = ({ id, defaultMessage }) => {
	if (!isEmpty(sharedEn[id])) {
		return <>{sharedEn[id]}</>;
	}

	if (!isEmpty(defaultMessage)) {
		return <>{defaultMessage}</>;
	}

	return <>{id}</>;
};
