import { UnderlyingIndexationType } from "Domain/report";
import { getEnumValue } from "Utilities/enum";

function mapDtoToEntity(key: string): UnderlyingIndexationType {
	return getEnumValue(UnderlyingIndexationType, key, UnderlyingIndexationType.None);
}

export const athenaUnderlyingIndexationTypeMapper = {
	mapDtoToEntity,
};
