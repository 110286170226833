import { trim } from 'lodash';
import { DateTime } from 'luxon';

function parseLongFrenchDate(stringValue: string): Date | null {
	const parsedDate = DateTime.fromFormat(stringValue, 'd LLLL yyyy', { locale: 'fr-FR' });
	if (!parsedDate.isValid) {
		return null;
	}
	return parsedDate.toJSDate();
}
function parseFrenchDate(stringValue: string): Date | null {
	const parsedDate = DateTime.fromFormat(stringValue, 'D', { locale: 'fr-FR' });
	if (!parsedDate.isValid) {
		return null;
	}
	return parsedDate.toJSDate();
}

function parseDates(stringValue: string): Date[] {
	let result: Date[] = [];
	const reworkedString = stringValue.replaceAll('\n', ' ').replaceAll('\t', '').replaceAll(' et', ';').replaceAll(',', ';');
	const separator = ';';

	const allStrings = reworkedString
		.split(separator)
		.map(trim)
		.filter((d) => d.length > 0);

	result = allStrings.flatMap((s) => {
		let parsedDate = parseLongFrenchDate(s);
		if (parsedDate == null) {
			// We try another french format
			parsedDate = parseFrenchDate(s);
		}
		if (parsedDate == null) {
			const tmpTimestamp = Date.parse(s);
			if (!isNaN(tmpTimestamp)) {
				parsedDate = DateTime.fromMillis(tmpTimestamp).toJSDate();
			} else {
				parsedDate = null;
			}
		}
		if (parsedDate == null) {
			console.log({ parsedDate, s });
			return [DateTime.invalid('invalid').toJSDate()];
		}
		return [parsedDate];
	});
	return result;
}

export const DateHelper = {
	parseDates,
	parseLongFrenchDate,
};
