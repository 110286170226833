import * as React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getPtpId } from "./helpers";
import { useDispatch, useSelector } from "react-redux";
import { BrochureGeneratorAppState, ReportType, reportTypeItems } from "Domain/report";
import { LoadingState } from "Models/LoadingState";
import HomePageSlice from "state/HomePage/HomePage.state";

import { SimpleSelectFormControl } from "components/Shared/FormControls/SimpleSelectFormControl";
import { ErrorMessage } from "./ErrorMessage";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

export const NewReportModal: React.FC = () => {
	const dispatcher = useDispatch();
	const { showNewReportsModal, xOneLoadingState, xOneErrorCode } = useSelector(
		(a: BrochureGeneratorAppState) => a.homepage,
	);
	const [xOneId, setXOneId] = React.useState<string>("");
	const [reportType, setReportType] = React.useState<ReportType>(ReportType.Athena);
	const isLoading = xOneLoadingState === LoadingState.Pending;
	const analyticsMonitoring = useAnalyticsMonitoring();

	const closeModal = React.useCallback(() => {
		dispatcher(HomePageSlice.actions.hideNewBrochureModal());
		analyticsMonitoring.functional("Brochure", "Close report creation modal");
	}, [analyticsMonitoring, dispatcher]);

	const onCreateReport = () => {
		const ptpId = getPtpId(xOneId);
		dispatcher(HomePageSlice.actions.initReport({ reportType: reportType, ptpId: ptpId }));
		analyticsMonitoring.functional("Brochure", "Create report", "", "", {
			reportType: reportType,
			ptpId: ptpId,
		});
	};

	const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			onCreateReport();
		}
	};

	return (
		<Modal isOpen={showNewReportsModal} size="md" backdrop={true} toggle={closeModal}>
			<ModalHeader wrapTag={"div"} tag="h3" toggle={closeModal}>
				Create a new report
			</ModalHeader>
			<ModalBody>
				{xOneLoadingState === LoadingState.Error && <ErrorMessage errorCode={xOneErrorCode} />}

				<div className="mb-4">
					<label className="form-label d-block" htmlFor="#">
						Report type
						<SimpleSelectFormControl
							initialValue={reportType}
							hideEmptyOption={true}
							items={Object.values(reportTypeItems)}
							onChange={(i) => {
								setReportType(i?.key as ReportType);
							}}
							hasValidationClassNames={false}
							isValid={true}
						/>
					</label>
				</div>
				<div className="mb-3">
					<label className="form-label d-block">
						X-One Id (optional)
						<div className="input-group mb-4">
							<span className="input-group-text">PTP-</span>
							<input
								type="text"
								className="form-control"
								disabled={isLoading}
								placeholder="46786097"
								onChange={(v) => setXOneId(v.currentTarget.value)}
								onKeyDown={onEnter}
								id="XoneId"
								value={xOneId}
							/>
						</div>
						<span className="text-muted">Provide an Id from SG Markets SP to prefill the form.</span>
					</label>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button disabled={isLoading} onClick={closeModal} size="lg" color="flat-secondary">
					Cancel
				</Button>
				<Button
					disabled={isLoading}
					onClick={onCreateReport}
					size="lg"
					color="primary"
					className={isLoading ? "btn-icon-end loading" : ""}
				>
					Create report
					{isLoading && (
						<>
							<span className="icon">close</span>
							<span className="loader"></span>
						</>
					)}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
