const hasProperty = <Obj, Prop extends PropertyKey>(obj: Obj, prop: Prop): obj is Obj & Record<Prop, unknown> => Object.prototype.hasOwnProperty.call(obj, prop);

export function getEnumValue_deprecated<Keys extends PropertyKey, Value, E extends Record<Keys, Value>, Key extends keyof E>(enm: E, key: Key): E[Key] | undefined {
	return hasProperty(enm, key) ? enm[key] : undefined;
}

type EnumValueType = string | number | symbol;
type EnumType = { [key in EnumValueType]: EnumValueType };
type ValueOf<T> = T[keyof T];

export function getEnumValue<T>(item: EnumType & T, key: string): ValueOf<T> | undefined;
export function getEnumValue<T>(item: EnumType & T, key: string, defaultValue: ValueOf<T>): ValueOf<T>;

export function getEnumValue<T>(item: EnumType & T, key: string, defaultValue?: ValueOf<T>): ValueOf<T> | undefined {
	const values = Object.values(item);
	const value = values.find((k) => k === key);

	if (value) {
		return value as ValueOf<T>;
	}

	return defaultValue;
}
