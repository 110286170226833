import { ValidationResult } from "Domain/report";
import { ValidationResultDto } from "Domain/reportDto";

function mapDtoToEntity(dto: ValidationResultDto): ValidationResult {
	return {
		errorMessage: dto.errorMessage,
		fields: dto.fields
	};
}

function mapDtosToEntities(dtos: ValidationResultDto[]): ValidationResult[] {
	return dtos.map(mapDtoToEntity);
}

export const validationResultMapper = {
	mapDtoToEntity,
	mapDtosToEntities,
};
