import React, { FC } from "react";
import { ReportInfoItem } from "Domain/report";
import { primeToaster } from "components/App/PrimeToasterContainer";

export const showBrochureToastSuccess = (report: ReportInfoItem) => {
	primeToaster.success(report.type, <BrochureMessage name={report.name} />, "");
};

export const BrochureMessage: FC<{ name: string }> = ({ name }) => {
	return (
		<>
			Brochure <strong>{name}</strong> is ready to download.
		</>
	);
};
