import React from "react";
import { useDispatch } from "react-redux";
import HomePageSlice from "state/HomePage/HomePage.state";
import { ImpersonatedUserInput } from "./ImpersonatedUserInput";
import { NewReportButton } from "./NewReportButton";
import { NewReportModal } from "./NewReportModal";
import { UserReports } from "./UserReports";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

export const HomePage: React.FC = () => {
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();

	const forceRefresh = () => {
		dispatcher(HomePageSlice.actions.loadReports());
		analyticsMonitoring.functional("Brochure", "Load Reports");
	};

	return (
		<div className="d-flex flex-column h-100">
			<div className="container-fluid">
				<div className="d-flex align-items-center">
					<h1 className="display-3 py-4 flex-fill">Brochure generator</h1>

					<NewReportButton className="btn-xl btn-icon-end" />
					<NewReportModal />
				</div>
			</div>
			<div className="flex-fill bg-lvl2 border-top">
				<div className="d-flex flex-column h-100 bg-lvl2 overflow-hidden">
					<div className="container-fluid align-items-center pt-3 pb-4">
						<div className="row">
							<div className="col">
								<div className="display-4">Brochures </div>
							</div>
							<div className="col-3">
								<ImpersonatedUserInput />
							</div>
							<div className="col-auto px-1">
								<button className="btn btn-lg btn-icon btn-discreet-primary" onClick={forceRefresh}>
									<i className="icon">refresh</i>
								</button>
							</div>
						</div>
					</div>
					<div className="flex-fill position-relative mb-4">
						<div className="position-absolute w-100 h-100 pl-3 " style={{ overflowY: "auto" }}>
							<UserReports />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
