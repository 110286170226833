import { UnderlyingIndexationType } from "Domain/report";
import { AthenaUnderlyingConfiguration } from "Domain/report.athena";
import { AthenaUnderlyingConfigurationDto } from "Domain/reportDto.athena";
import { athenaUnderlyingIdMapper } from "./athenaUnderlyingIdMapper";
import { athenaUnderlyingIndexationTypeMapper } from "./athenaUnderlyingIndexationTypeMapper";

function mapEntityToDto(entity: AthenaUnderlyingConfiguration): AthenaUnderlyingConfigurationDto {
	const underlyings = athenaUnderlyingIdMapper.mapEntitiesToDtos(entity.underlyings);
	let indexationType: UnderlyingIndexationType = entity.indexationType;

	if (underlyings.length > 1 && indexationType === UnderlyingIndexationType.None) {
		indexationType = UnderlyingIndexationType.Basket;
	} else {
		if (underlyings.length === 1 && indexationType !== UnderlyingIndexationType.None) {
			indexationType = UnderlyingIndexationType.None;
		}
	}

	const dto: AthenaUnderlyingConfigurationDto = {
		indexationType,
		underlyings,
	};
	return dto;
}

function mapDtoToEntity(dto: AthenaUnderlyingConfigurationDto): AthenaUnderlyingConfiguration {

	const entity: AthenaUnderlyingConfiguration = {
		indexationType: athenaUnderlyingIndexationTypeMapper.mapDtoToEntity(dto.indexationType),
		underlyings: athenaUnderlyingIdMapper.mapDtosToEntities(dto.underlyings),
	};
	return entity;
}

export const athenaUnderlyingConfigurationMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
