import { call, takeEvery, put, select } from "typed-redux-saga";
import { BrochureGeneratorApi } from "Api/brochureGeneratorApi";
import { isEmpty } from "lodash";
import { globalNavigate } from "components/App/GlobalHistory";
import { primeToaster } from "components/App/PrimeToasterContainer";
import { BrochureGeneratorAppState } from "Domain/report";
import PhoenixBearishSlice from "./PhoenixBearish.state";
import { phoenixBearishReportModelMapper } from "Mappers/PhoenixBearish/phoenixBearishReportModelMapper";

const api = new BrochureGeneratorApi();

export function* phoenixBearishSaga(): Generator {
	yield* takeEvery(PhoenixBearishSlice.actions.generateBrochure, onGenerateBrochure);
	yield* takeEvery(PhoenixBearishSlice.actions.generateBrochureSuccess, onGenerateBrochureSuccess);
	yield* takeEvery(PhoenixBearishSlice.actions.generateBrochureError, onGenerateBrochureError);
}

function* onGenerateBrochure(action: ReturnType<typeof PhoenixBearishSlice.actions.generateBrochure>) {
	const { currentReport, formState, tradeReference } = yield* select(
		(state: BrochureGeneratorAppState) => state.phoenixBearish,
	);

	// Form is invalid
	if (!isEmpty(formState.validationState)) {
		return;
	}

	try {
		const reportDto = phoenixBearishReportModelMapper.mapEntityToDto(currentReport, tradeReference);
		yield* call(api.createPhoenixBearishReport, reportDto);

		yield* put(PhoenixBearishSlice.actions.generateBrochureSuccess());
	} catch (err: any) {
		console.error(err);

		yield* put(PhoenixBearishSlice.actions.generateBrochureError());
	}
}

function onGenerateBrochureSuccess() {
	primeToaster.info("PhoenixBearish", "Brochure is being generated.", "info");
	globalNavigate("/");
}

function onGenerateBrochureError() {
	primeToaster.danger("Generate brochure", "Unable to generate the PhoenixBearish report.");
}
