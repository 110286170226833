
import React from 'react';
import { UnderlyingItemType } from 'state/Resources/Resources.state';


export const UnderlyingTypeBadge: React.FC<{ underlyingType: UnderlyingItemType, size?: string }> = ({ underlyingType, size }) => {
	let badgeClass: string = 'badge-puerto-rico';
	switch (underlyingType) {
		case 'Fund':
			badgeClass = 'badge-mars';
			break;
		case 'Indice':
			badgeClass = 'badge-buttercup';
			break;
	}
	return <span className={`badge badge-${size ?? "sm"} ${badgeClass}`}>{underlyingType}</span>;
};
