import React from "react";
import { MessageKey, FormatMessage } from "components/Shared/Intl/FormatMessage";


export const ErrorMessage: React.FC<{ errorCode: number | undefined }> = ({ errorCode }) => {
	let messageId: MessageKey = 'general.unexpectedError';

	switch (errorCode) {
		case 400:
			messageId = 'newReport.xone.error.400';
			break;

		case 404:
			messageId = 'newReport.xone.error.404';
	}

	return (
		<div className="mb-3 text-danger">
			<FormatMessage id={messageId} />
		</div>
	);
};
