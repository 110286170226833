import React, { FC, useState, useMemo, useCallback, useEffect } from "react";
import { ReportDocumentType, ReportInfoItem } from "Domain/report";
import HomePageSlice from "state/HomePage/HomePage.state";
import { Positionner } from "./Positionner";
import { useOutsideClickHandler } from "./useOutsideClickHandler";
import { chain } from "lodash";
import { useDispatch } from "react-redux";
import Loader from "components/Shared/Loader/Loader";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

const DownloadIcon: FC<{ isDownloading: boolean }> = ({ isDownloading }) => {
	if (isDownloading) {
		return <Loader size="sm" className="me-1" />;
	}

	return <i className="icon icon-xs me-1">download</i>;
};

type DocInfo = {
	title: string;
	order: number;
	icon: string;
	docType: ReportDocumentType;
};
const docNames: Record<ReportDocumentType, DocInfo> = {
	[ReportDocumentType.Unknown]: {
		title: "Unknown",
		order: 0,
		icon: "backup_table",
		docType: ReportDocumentType.Unknown,
	},
	[ReportDocumentType.Brochure]: {
		title: "Download PPT",
		order: 1,
		icon: "collections",
		docType: ReportDocumentType.Brochure,
	},
	[ReportDocumentType.BrochurePdf]: {
		title: "Download PDF",
		order: 2,
		icon: "picture_as_pdf",
		docType: ReportDocumentType.BrochurePdf,
	},
	[ReportDocumentType.TraTable]: {
		title: "Download TRA",
		order: 3,
		icon: "table_view",
		docType: ReportDocumentType.TraTable,
	},
};

interface DownloadBrochureButtonProps {
	disabled: boolean;
	reportInfo: ReportInfoItem;
}

const DownloadBrochureButton: FC<DownloadBrochureButtonProps> = ({ disabled, reportInfo }) => {
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();
	const [selectedDocInfo, setSelectedDocInfo] = useState(docNames[ReportDocumentType.Unknown]);
	const [isDownloading, setIsDownloading] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

	const isDisabled = useMemo(() => {
		return disabled || isDownloading;
	}, [disabled, isDownloading]);

	const availableDocuments = useMemo(() => {
		let tmpDocs = reportInfo.availableDocuments;
		if (tmpDocs.length === 0) {
			tmpDocs = [ReportDocumentType.Brochure];
		}
		return chain(tmpDocs)
			.orderBy([(dInfo) => docNames[dInfo].order], ["asc"])
			.value();
	}, [reportInfo.availableDocuments]);

	const onSelectDocumentType = useCallback(
		(documentType: ReportDocumentType) => {
			if (selectedDocInfo.docType !== documentType) {
				setSelectedDocInfo(docNames[documentType]);
			}
			setIsDropdownOpen(false);
		},
		[selectedDocInfo.docType],
	);

	useEffect(() => {
		if (selectedDocInfo.docType === ReportDocumentType.Unknown) {
			onSelectDocumentType(availableDocuments[0]);
		}
	}, [availableDocuments, onSelectDocumentType, selectedDocInfo.docType]);

	const hasMultipleDocs = useMemo(() => {
		return availableDocuments.length > 1;
	}, [availableDocuments]);
	const toggleDropdownMenu = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const downloadBrochure = (documentType: ReportDocumentType) => {
		new Promise<void>((resolve, reject) => {
			setIsDownloading(true);

			dispatcher(
				HomePageSlice.actions.downloadBrochure({
					report: reportInfo,
					documentType,
					success: () => {
						resolve();
					},
					error: () => {
						reject();
					},
				}),
			);

			analyticsMonitoring.functional("Brochure", "Download", "", "", {
				name: reportInfo.name,
			});
		}).finally(() => {
			setIsDownloading(false);
		});
	};
	const outsideHandlerRef = useOutsideClickHandler(() => setIsDropdownOpen(false));

	return (
		<div className="btn-group " style={{ width: 200 }} ref={setReferenceElement}>
			<button
				type="button"
				className={`btn btn-discreet-primary btn-icon-start align-baseline`}
				disabled={isDisabled}
				onClick={(e) => {
					downloadBrochure(selectedDocInfo.docType);
				}}
			>
				<DownloadIcon isDownloading={isDownloading} />
				{selectedDocInfo.title}
			</button>
			{hasMultipleDocs && (
				<>
					<button
						type="button"
						className={`btn btn-discreet-primary dropdown-toggle dropdown-toggle-split${isDropdownOpen ? " active" : ""}`}
						onClick={(e) => {
							if (!e.isDefaultPrevented()) {
								e.preventDefault();
								toggleDropdownMenu();
							}
						}}
						disabled={isDisabled}
					></button>
					<Positionner reference={referenceElement} visible={isDropdownOpen}>
						<div className="card bg-lvl1 border border-secondary" ref={outsideHandlerRef}>
							{availableDocuments.map((docType) => {
								const isSelected = selectedDocInfo.docType === docType;
								const docInfo = docNames[docType];
								return (
									<button
										className={`hstack dropdown-item px-3 py-2 border-bottom ${isSelected ? "active" : ""}`}
										key={`dt_${docType}`}
										onClick={() => {
											onSelectDocumentType(docInfo.docType);
										}}
									>
										<i className="icon icon-sm me-3">{docInfo.icon}</i>
										<span className="flex-fill">{docInfo.title}</span>
									</button>
								);
							})}
						</div>
					</Positionner>
				</>
			)}
		</div>
	);
};

export default DownloadBrochureButton;
