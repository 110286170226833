import * as React from "react";
import { cssHelper } from "../cssHelper";
import { DefaultInputProps } from "./DefaultInputProps";

export interface CheckboxProps extends DefaultInputProps<boolean> {
	id: string;
	label: string;
	indeterminate?: boolean;
	classNames?: string;
	tooltipId?: string;
}

export interface State {
	previousChecked: boolean;
	checked: boolean;
}

const DEFAULT_CHECKBOX_ID = "default_tooltip_id";

export const Checkbox: React.FC<CheckboxProps> = ({ label, id, disabled, classNames, tooltipId, onChange, initialValue, indeterminate, isValid }) => {
	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newCheckedValue = event.target.checked;
		if (newCheckedValue !== initialValue) {
			onChange(newCheckedValue)
		}
	}

	return (
		<div
			id={tooltipId ?? DEFAULT_CHECKBOX_ID}
			className={`form-check`}
		>
			<input
				id={id}
				name={label}
				className={`form-check-input  ${cssHelper.formValidationStateClassNames(isValid)}`}
				type="checkbox"
				checked={initialValue}
				onChange={handleOnChange}
				disabled={disabled ?? false}
				ref={(el) => el && (el.indeterminate = indeterminate ?? false)}
			/>
			<label className="form-check-label" htmlFor={id}>
				{label}
			</label>
		</div>
	);


}

