import { BrochureGeneratorAppState } from "Domain/report";
import { LoadingState } from "Models/LoadingState";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportFormSection } from "../ReportFormSection";
import PhoenixBearishFormControl from "./Form/PhoenixBearishFormControl";
import { ColorSelector } from "components/Shared/ColorSelector/ColorSelector";
import { PictureSelector } from "components/Shared/PictureSelector/PictureSelector";
import { usePhoenixBearishValidationStateForField } from "state/Reports/PhoenixBearish/usePhoenixBearishValidationStateForField";
import { LiquidityValuesHelper } from "../Athena/AthenaForm";
import { cssHelper } from "components/Shared/cssHelper";
import PhoenixBearishSlice, { editValueCreatorHelper } from "state/Reports/PhoenixBearish/PhoenixBearish.state";
import GeneratePhoenixBearishBrochureButton from "./GeneratePhoenixBearishBrochureButton";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";
import { PhoenixBearishScheduleLinesTable } from "./Form/PhoenixBearishScheduleLinesTable";
import PhoenixBearishUnderlyingSelector from "./Form/PhoenixBearishUnderlyingSelector";

export const PhoenixBearishReport: React.FC = () => {
	const PhoenixBearishState = useSelector((a: BrochureGeneratorAppState) => a.phoenixBearish);
	const dispatcher = useDispatch();
	const { brochureLoadingState, currentReport } = PhoenixBearishState;
	const isLoading = brochureLoadingState === LoadingState.Pending;
	const pictureValidationState = usePhoenixBearishValidationStateForField("transversalConfiguration", "pictureId");
	const colorValidationState = usePhoenixBearishValidationStateForField("transversalConfiguration", "colorPreset");
	const analyticsMonitoring = useAnalyticsMonitoring();
	const { hasMarketingPeriod, productEligibility, pictureId, colorPreset, isAutocallProgressive, isGaranteedCoupon } =
		currentReport.transversalConfiguration;

	const onReset = () => {
		dispatcher(PhoenixBearishSlice.actions.resetReport());
		analyticsMonitoring.functional("Phoenix Bearish", "Reset form");
	};

	return (
		<div className="d-flex flex-column h-100 overflow-hidden">
			<div className="p-3 d-flex align-items-center shadow border-bottom">
				<div className="display-4 flex-fill">Phoenix Bearish</div>
			</div>
			<div className="flex-fill position-relative bg-lvl3">
				{isLoading && <div className="prime-overlay"></div>}
				<div className="position-absolute w-100 h-100 px-3 py-4" style={{ overflowY: "auto" }}>
					<div className="row mb-4">
						<div className="col">
							<ReportFormSection title={"Product Information"} containerClassName="mb-1">
								<div className="row">
									<div className="col-6">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"productName"} />
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"productIssuer"} />
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"productGuarantor"} />
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"productCurrency"} />
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"productDenomination"} />
									</div>
									<div className="col-6">
										<div className="row">
											<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"issuePrice"} />
											<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"priceRate"} />
										</div>
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"minimumInvestment"} />
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"issueDate"} />
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"maturityDate"} />
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"productDuration"} />
									</div>
								</div>
							</ReportFormSection>
						</div>
						<div className="col">
							<div className={`card card-bordered bg-lvl2 h-100 mb-4`}>
								<div className="card-body pb-0">
									<div className="d-flex flex-column h-100">
										<div>
											<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"isInfine"} />
										</div>
										<div className="flex-fill">
											<div className="vstack h-100 pb-3">
												<div>
													<div className="form-label">Schedule Lines</div>
												</div>
												<div className="flex-fill">
													<PhoenixBearishScheduleLinesTable />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<ReportFormSection title={"PayOff Information"} containerClassName="mb-4">
								<div className="row">
									<div className="col-12">
										<PhoenixBearishUnderlyingSelector />
									</div>
								</div>
								<div className="row">
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"autocallBarrier"} />
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"couponBarrier"} />
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"conditionalCoupon"} />
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"below"} />
								</div>
								<div className="row">
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"totalPeriods"} />
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"observationStartPeriod"} />
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"observationPeriodicity"} />
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"feesPercent"} />
								</div>
								<div className="row">
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"isAutocallProgressive"} />
									<PhoenixBearishFormControl
										contextKey="transversalConfiguration"
										field={"progressivityStep"}
										fieldConfigOverrides={{ disabled: !isAutocallProgressive }}
									/>
									<PhoenixBearishFormControl
										contextKey="transversalConfiguration"
										field={"progressivityPeriod"}
										fieldConfigOverrides={{ disabled: !isAutocallProgressive }}
									/>
								</div>
								<div className="row">
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"isGaranteedCoupon"} />
									<PhoenixBearishFormControl
										contextKey="transversalConfiguration"
										field={"unconditionalCoupon"}
										fieldConfigOverrides={{ disabled: !isGaranteedCoupon }}
									/>
									<PhoenixBearishFormControl
										contextKey="transversalConfiguration"
										field={"numberOfUnconditionalCoupons"}
										fieldConfigOverrides={{ disabled: !isGaranteedCoupon }}
									/>
								</div>
							</ReportFormSection>
						</div>
						<div className="col">
							<ReportFormSection title={"Deal Information"} containerClassName="mb-4">
								<div className="row">
									<div className="col-4">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"productCotation"} />
									</div>
									<div className="col-4">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"productLaw"} />
									</div>
									<div className="col-4">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"bidOffer"} />
									</div>
								</div>
								<div className="row">
									<div className="col-8">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"productEligibility"} />
									</div>
									<div className="col-4">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"commission"} />
									</div>
								</div>
								<div className="row">
									<div className="col-3">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field="hasMarketingPeriod" />
									</div>
									<div className="col-5">
										<PhoenixBearishFormControl
											contextKey="transversalConfiguration"
											field="marketingPeriod"
											fieldConfigOverrides={{ hidden: !hasMarketingPeriod }}
										/>
									</div>
									<div className="col-4">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field="doubleValuation" />
									</div>
								</div>
								<div className="row">
									<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"mtmPublication"} />
									<div className={cssHelper.colSizeClassNames(4, "mb-3")} style={{ maxWidth: 500 }}>
										<div className="form-label">&nbsp;</div>
										<LiquidityValuesHelper
											onSelect={(val) =>
												dispatcher(editValueCreatorHelper("transversalConfiguration", "mtmPublication", val))
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-4">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"isin"} />
									</div>
									<div className="col-4">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"calculationAgent"} />
									</div>
									<div className="col-4">
										<PhoenixBearishFormControl contextKey="transversalConfiguration" field={"client"} />
									</div>
								</div>
								<div className="row">
									<PhoenixBearishFormControl
										contextKey="transversalConfiguration"
										field={"initialValuationDate"}
										size={4}
									/>
									<PhoenixBearishFormControl
										contextKey="transversalConfiguration"
										field="ftDate"
										size={{ defaultSize: 12, lg: 4 }}
										fieldConfigOverrides={{ hidden: productEligibility == null }}
									/>
								</div>
								<div className="row">
									<div className="col-6">
										<div className="form-label">Cover Picture</div>
										<PictureSelector
											errorMessage={pictureValidationState}
											selectedPictureId={pictureId}
											onChange={(id) => {
												dispatcher(editValueCreatorHelper("transversalConfiguration", "pictureId" as any, id));
											}}
										/>
									</div>
									<div className="col-6">
										<div className="form-label">Client Theme</div>
										<ColorSelector
											errorMessage={colorValidationState}
											headerTitle="Client Theme"
											selectedPreset={colorPreset}
											onChange={(preset) => {
												dispatcher(editValueCreatorHelper("transversalConfiguration", "colorPreset" as any, preset));
											}}
										/>
									</div>
								</div>
							</ReportFormSection>
						</div>
					</div>
				</div>
			</div>

			<div className="p-3 shadow border-top">
				<div className="container-fluid p-0">
					<div className="row">
						<div className="col-auto ms-auto">
							<button className="btn btn-xl btn-outline-secondary" disabled={isLoading} onClick={onReset}>
								Reset
							</button>
						</div>
						<div className="col-auto">
							<GeneratePhoenixBearishBrochureButton />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
