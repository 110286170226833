import { useEffect } from 'react';
import { sgBootstrapStyleSheet, sgBootstrapGridStyleSheet } from 'Utilities/config';
import { AppTheme } from 'Utilities/theme';
import { useSelector } from 'react-redux';
import { BrochureGeneratorAppState } from 'Domain/report';

export function useThemeToggler() {
	const theme: AppTheme = useSelector((state: BrochureGeneratorAppState) => state.homepage.currentTheme);

	useEffect(() => {
		const stylesheet = document.getElementById('sg-bootstrap-theme') as any;
		const gridStylesheetCondensed = document.getElementById('sg-bootstrap-grid-theme-condensed') as any;
		const gridStylesheetNormal = document.getElementById('sg-bootstrap-grid-theme-normal') as any;
		if (stylesheet) {
			stylesheet.href = sgBootstrapStyleSheet[theme];
		}
		const baseAgGridUrl = sgBootstrapGridStyleSheet[theme];
		const agGridNormalThemeUrl = baseAgGridUrl.replace('{condensed}', '');
		const agGridCondensedThemeUrl = baseAgGridUrl.replace('{condensed}', '-condensed');
		if (gridStylesheetNormal) {
			gridStylesheetNormal.href = agGridNormalThemeUrl;
		}
		if (gridStylesheetCondensed) {
			gridStylesheetCondensed.href = agGridCondensedThemeUrl;
		}
	}, [theme]);
}
