import { isNil, isEmpty, forEach } from "lodash";
import React, { PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AthenaUnderlyingId } from "Domain/report.athena";
import { TarnReportConfiguration } from "Domain/report.tarn";
import { ButtonGroupFormControl } from "components/Shared/FormControls/ButtonGroupFormControl";
import DateListFormControl from "components/Shared/FormControls/DateListFormControl";
import DatePickerFormControl from "components/Shared/FormControls/DatePickerFormControl";
import DateRangePickerFormControl from "components/Shared/FormControls/DateRangePickerFormControl";
import DurationInfoFormControl from "components/Shared/FormControls/DurationInfoFormControl";
import { AllFieldsConfigurationTypes, BaseFieldConfiguration } from "components/Shared/FormControls/FieldConfiguration";
import NumericFormControl from "components/Shared/FormControls/NumericFormControl";
import PercentFormControl from "components/Shared/FormControls/PercentFormControl";
import { PeriodFormControl } from "components/Shared/FormControls/PeriodFormControl";
import { RadioButtonGroup } from "components/Shared/FormControls/RadioButtonGroup";
import { RadioList } from "components/Shared/FormControls/RadioList";
import { SimpleSelectFormControl } from "components/Shared/FormControls/SimpleSelectFormControl";
import UnderlyingSelector from "components/Shared/FormControls/UnderlyingSelector";
import { Sizes, cssHelper } from "components/Shared/cssHelper";
import TarnSlice, { editValueCreatorHelper } from "state/Reports/Tarn/tarn.state";
import { useTarnValidationStateForField } from "state/Reports/Tarn/useTarnValidationStateForField";
import { BrochureGeneratorAppState, PeriodicInfo } from "Domain/report";
import { Checkbox } from "components/Shared/FormControls/Checkbox";

interface TarnFormControlProps<TReportConfigKey extends keyof TarnReportConfiguration> {
	size?: number | Sizes;
	contextKey: TReportConfigKey;
	field: keyof TarnReportConfiguration[TReportConfigKey];
	fieldConfigOverrides?: Partial<AllFieldsConfigurationTypes>;
}

export default function TarnFormControl<TReportConfigKey extends keyof TarnReportConfiguration>(
	props: PropsWithChildren<TarnFormControlProps<TReportConfigKey>>,
) {
	const dispatcher = useDispatch();
	const context = useSelector((s: BrochureGeneratorAppState) => {
		if (s.tarn.currentReport) {
			return s.tarn.currentReport[props.contextKey];
		}
		return undefined;
	});

	const errorMessage: string | undefined = useTarnValidationStateForField(props.contextKey, props.field);
	const isValid = isNil(errorMessage) || isEmpty(errorMessage);

	let fieldConfig = useSelector((s: BrochureGeneratorAppState) => {
		const contextConfig = s.tarn.formState.fieldConfiguration[props.contextKey] as any;
		if (contextConfig) {
			return contextConfig[props.field] as AllFieldsConfigurationTypes | undefined;
		}
	});
	if (props.fieldConfigOverrides && fieldConfig) {
		forEach(props.fieldConfigOverrides, (value, property) => {
			const fieldName = property as keyof BaseFieldConfiguration;
			if (value) {
				fieldConfig = { ...fieldConfig, [fieldName]: value } as any;
			}
		});
	}

	const triggerValueChange = (value: any) => {
		// TODO: To remove when all the config & fields are available
		if (props.field) {
			dispatcher(editValueCreatorHelper(props.contextKey, props.field as any, value));

			switch (props.field) {
				case "fixedCoupon":
				case "observationPeriodicity":
					dispatcher(TarnSlice.actions.updateScheduleLinesFixedCoupon());
					break;
				case "leverageAmount":
					dispatcher(TarnSlice.actions.updateScheduleLinesLeverage());
					break;
				case "fixedCouponPeriods":
				case "totalPeriods":
					dispatcher(TarnSlice.actions.recomputeScheduleLines());
					break;
			}
		}
	};
	let value: any;
	// TODO: Same here
	if (context && props.field) {
		value = context[props.field];
	}

	let $control: React.ReactNode | undefined;
	if (fieldConfig) {
		switch (fieldConfig.type) {
			case "Text":
				if (fieldConfig.multiline) {
					$control = (
						<textarea
							className="form-control"
							onChange={(e) => {
								triggerValueChange(e.currentTarget.value);
							}}
							value={(value ?? "") as string}
							disabled={fieldConfig.disabled}
						/>
					);
				} else {
					$control = (
						<input
							type="text"
							className={`form-control ${cssHelper.formValidationStateClassNames(isValid)}`}
							value={value ?? ""}
							onChange={(e) => triggerValueChange(e.currentTarget.value)}
							disabled={fieldConfig.disabled}
						/>
					);
				}
				break;
			case "Checkbox":
				$control = (
					<Checkbox
						initialValue={value}
						onChange={triggerValueChange}
						label={fieldConfig.valueLabel}
						id={props.field as string}
						disabled={fieldConfig.disabled}
						isValid={isValid}
					/>
				);
				break;
			case "RadioList":
				$control = (
					<RadioList
						isValid={isValid}
						initialValue={value ?? ""}
						onChange={(e) => triggerValueChange(e.key)}
						items={Object.values(fieldConfig.datasource)}
						disabled={fieldConfig.disabled}
					/>
				);
				break;
			case "RadioButtonGroup":
				$control = (
					<RadioButtonGroup
						isValid={isValid}
						initialValue={value ?? ""}
						onChange={(e) => triggerValueChange(e.key)}
						items={Object.values(fieldConfig.datasource)}
						disabled={fieldConfig.disabled}
						className={fieldConfig.className}
					/>
				);
				break;
			case "Number":
				$control = (
					<NumericFormControl
						isValid={isValid}
						initialValue={value}
						onChange={triggerValueChange}
						formatOptions={fieldConfig.format ?? { thousandSeparated: true }}
						disabled={fieldConfig.disabled}
						showClearButton={true}
					/>
				);
				break;
			case "List":
				$control = (
					<SimpleSelectFormControl
						isValid={isValid}
						initialValue={value ?? ""}
						onChange={(e) => triggerValueChange(e?.key)}
						items={Object.values(fieldConfig.datasource)}
						disabled={fieldConfig.disabled}
					/>
				);
				break;
			case "ButtonList":
				$control = (
					<ButtonGroupFormControl
						isValid={isValid}
						initialValue={value ?? ""}
						onChange={(e) => triggerValueChange(e?.key)}
						items={Object.values(fieldConfig.datasource)}
						disabled={fieldConfig.disabled}
					/>
				);
				break;
			case "Percent":
				$control = (
					<PercentFormControl
						isValid={isValid}
						initialValue={value as number}
						onChange={triggerValueChange}
						disabled={fieldConfig.disabled}
					/>
				);
				break;
			case "Period":
				$control = (
					<PeriodFormControl
						isValid={isValid}
						initialValue={value as PeriodicInfo}
						onChange={triggerValueChange}
						enablePeriodicitySelection={fieldConfig.disableUnitEdit === false}
						disabled={fieldConfig.disabled}
					/>
				);
				break;
			case "Underlying":
				$control = (
					<UnderlyingSelector
						initialValue={value as AthenaUnderlyingId[]}
						onChange={triggerValueChange}
						isValid={undefined}
					/>
				);
				break;

			case "Date":
				$control = <DatePickerFormControl initialValue={value} onChange={triggerValueChange} isValid={isValid} />;
				break;
			case "DateRange":
				$control = (
					<DateRangePickerFormControl
						initialValue={value}
						onChange={triggerValueChange}
						isValid={isValid}
						disabled={fieldConfig.disabled}
					/>
				);
				break;
			case "DateList":
				$control = (
					<DateListFormControl
						initialValue={value ?? []}
						onChange={triggerValueChange}
						disabled={fieldConfig.disabled}
						isValid={undefined}
					/>
				);
				break;
			case "Duration":
				$control = (
					<DurationInfoFormControl
						initialValue={value ?? { days: 0, months: 0, years: 0 }}
						onChange={triggerValueChange}
						disabled={fieldConfig.disabled}
						isValid={isValid}
					/>
				);
				break;
			default:
				$control = (
					<div className="input-group">
						<span className="text-danger">Unknown Form Control</span>
					</div>
				);
				break;
		}
	}

	return (
		<div
			className={`${cssHelper.getSizeClass(props.size, "col", "col mb-3")} ${fieldConfig?.hidden === true ? "invisible" : ""}`}
		>
			{fieldConfig?.displayLabel !== undefined && (
				<label
					className={`form-label d-block ${["RadioList", "ScheduleLines"].includes(fieldConfig.type) ? "mb-1" : ""}`}
				>
					{fieldConfig?.displayLabel ?? "\u00A0"}
				</label>
			)}
			{$control}
			{!errorMessage && fieldConfig?.description !== undefined && (
				<div className={`form-text fst-italic ${fieldConfig.disabled === true ? "invisible" : ""}`}>
					{fieldConfig.description}
				</div>
			)}
			{errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>}
		</div>
	);
}
