import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UnderlyingForm } from "./UnderlyingForm";
import { RowClassRules } from "ag-grid-community";
import { UnderlyingTypeBadge } from "./UnderlyingTypeBadge";
import { isNil } from "lodash";
import ResourcesSlice, { UnderlyingItem, createNewUnderlyingItem } from "state/Resources/Resources.state";
import { BrochureGeneratorAppState } from "Domain/report";
import { useDispatch, useSelector } from "react-redux";
import { TextInput } from "components/Shared/FormControls/TextInput";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

const TypedGrid = AgGridReact<UnderlyingItem>;

const UnderlyingBadgeCellRenderer = (e: CustomCellRendererProps<UnderlyingItem>) => {
	return <UnderlyingTypeBadge underlyingType={e.data!.underlyingType} />;
};

export const UnderlyingSection: React.FC = () => {
	const [selectedUnderlying, setSelectedUnderlying] = useState<UnderlyingItem | undefined>(undefined);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();
	const loadUnderlyings = useCallback(() => {
		dispatcher(ResourcesSlice.actions.loadUnderlyings());
	}, [dispatcher]);

	useEffect(() => {
		loadUnderlyings();
	}, [loadUnderlyings]);

	const allUnderlyings = useSelector((s: BrochureGeneratorAppState) => s.resources.allUnderlyings);

	const onCreateNewClick = () => {
		setSelectedUnderlying(createNewUnderlyingItem());
		analyticsMonitoring.functional("Underlyings", "Create new underlying");
	};

	const rowRules = useMemo(() => {
		const rules: RowClassRules<UnderlyingItem> = {
			"text-bg-info": (e) => e.data?.id === selectedUnderlying?.id,
		};
		return rules;
	}, [selectedUnderlying]);

	return (
		<div className="row h-100 g-0">
			<div className="col-4 h-100 position-relative bg-lvl1 border-end border-primary">
				<div className="vstack h-100">
					<div className="row m-0 py-2">
						<div className="col">
							<TextInput value={searchTerm ?? ""} onChange={setSearchTerm} placeholder="Search Underlying ..." />
						</div>
						<div className="col-auto">
							<button className="btn btn-primary" onClick={onCreateNewClick}>
								Create new
							</button>
						</div>
						<div className="col-auto">
							<button className="btn btn btn-icon btn-discreet-primary" onClick={loadUnderlyings}>
								<i className="icon">refresh</i>
							</button>
						</div>
					</div>

					<div className="flex-fill">
						<div className="ag-theme-sg-bootstrap h-100">
							<TypedGrid
								onFirstDataRendered={(e) => {
									e.api.sizeColumnsToFit();
								}}
								rowData={allUnderlyings}
								columnDefs={[
									{
										field: "underlyingType",
										maxWidth: 150,
										headerName: "Type",
										cellRenderer: UnderlyingBadgeCellRenderer,
										initialSort: "asc",
										initialSortIndex: 1,
									},
									{ field: "ticker", headerName: "Ticker", initialSort: "asc", initialSortIndex: 2, maxWidth: 220 },
									{ field: "name" },
									{
										field: "lastStrike",
										headerName: "Last Close",
										maxWidth: 120,
										type: "numericColumn",
										valueFormatter: (params) => {
											if (params.value) {
												return Intl.NumberFormat(undefined, { minimumFractionDigits: 3 }).format(params.value);
											}
											return "-";
										},
									},
								]}
								defaultColDef={{ flex: 1, sortable: true }}
								rowHeight={42}
								headerHeight={42}
								rowModelType="clientSide"
								onSelectionChanged={(e) => {
									const selection = e.api.getSelectedRows();
									if (selection.length === 1) {
										setSelectedUnderlying(selection[0]);
									}
								}}
								rowSelection="single"
								enableRangeSelection={false}
								quickFilterText={searchTerm}
								rowClassRules={rowRules}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="col h-100 position-relative">
				{selectedUnderlying && (
					<UnderlyingForm
						underlying={selectedUnderlying}
						isNew={isNil(selectedUnderlying.id)}
						key={`underlying_form_${selectedUnderlying.id}`}
						onClose={() => {
							setSelectedUnderlying(undefined);
							analyticsMonitoring.functional("Underlyings", "Cancel creation");
						}}
						onDuplicate={() => setSelectedUnderlying({ ...selectedUnderlying, id: undefined, ticker: "" })}
					/>
				)}
			</div>
		</div>
	);
};
