import { ReportDocumentType } from "Domain/report";
import { ReportDocumentTypeDto } from "Domain/reportDto";
import { getEnumValue } from "Utilities/enum";


function mapDtoToEntity(key: string): ReportDocumentType {
	return getEnumValue(ReportDocumentType, key, ReportDocumentType.Unknown);
}
function mapEntityToDto(key: string): ReportDocumentTypeDto {
	return getEnumValue(ReportDocumentTypeDto, key, ReportDocumentTypeDto.Unknown);
}

export const reportDocumentTypeMapper = {
	mapDtoToEntity,
	mapEntityToDto
};
