import {
	BelowChoices,
	ColorPreset,
	DurationInfo,
	Guid,
	PeriodicInfo,
	Periodicity,
	ProductClient,
	ProductCurrency,
	productCurrencyItems,
	ProductEligibility,
	productEligibilityItems,
	ProductLaw,
	productLawItems,
} from "Domain/report";
import {
	PhoenixBearishReportConfiguration,
	PhoenixBearishTransversalConfiguration,
	PhoenixBearishUnderlyingInfo,
} from "Domain/report.phoenixBearish";
import { PhoenixBearishScheduleLine } from "Domain/reportDto.phoenixBearish";
import * as yup from "yup";

export const transversalConfigurationSchema = (): yup.Schema<PhoenixBearishTransversalConfiguration> =>
	yup.object({
		productName: yup.string().defined().min(1),
		productIssuer: yup.string().defined().min(1),
		hasMarketingPeriod: yup.bool().defined(),
		marketingPeriod: yup
			.object({
				startDate: yup.date().required(),
				endDate: yup.date().required(),
			})
			.when("hasMarketingPeriod", { is: true, then: (s) => s.required() }),
		issuePrice: yup.number().defined(),
		priceRate: yup.number().defined(),
		productDenomination: yup.number().defined(),
		productGuarantor: yup.string().optional(),
		issueDate: yup.date().defined(),
		maturityDate: yup.date().defined(),
		productDuration: yup.mixed<DurationInfo>().defined(),
		productCurrency: yup
			.mixed<ProductCurrency>()
			.oneOf(Object.values(productCurrencyItems).map((e) => e.key))
			.defined(),
		minimumInvestment: yup.number().defined(),

		productCotation: yup.string().defined().min(1),
		calculationAgent: yup.string().defined().min(1),
		isin: yup.string().optional(),
		mtmPublication: yup.string().required().min(1),
		productLaw: yup
			.mixed<ProductLaw>()
			.oneOf(Object.values(productLawItems).map((e) => e.key))
			.defined(),
		pictureId: yup.mixed<Guid>().defined(),
		commission: yup.number().defined(),
		client: yup.mixed<ProductClient>().defined(),
		productEligibility: yup
			.mixed<ProductEligibility>()
			.required()
			.oneOf(Object.values(productEligibilityItems).map((e) => e.key)),
		initialValuationDate: yup.date().defined(),
		doubleValuation: yup.string().defined(),
		bidOffer: yup.number().defined(),
		colorPreset: yup.mixed<ColorPreset>().defined(),
		scheduleLines: yup
			.array<PhoenixBearishScheduleLine>()
			.of(
				yup.object({
					observationDate: yup.date().required(),
					paymentDate: yup.date().required(),
					periodNumber: yup.number().required(),
					hasConditionalCoupon: yup.bool().required(),
					conditionalCoupon: yup.number().optional(),
					hasUnconditionalCoupon: yup.bool().required(),
					unconditionalCoupon: yup.number().optional(),
					isAutocallable: yup.bool().required(),
				}),
			)
			.defined(),
		underlying: yup.mixed<PhoenixBearishUnderlyingInfo>().defined(),
		autocallBarrier: yup.number().defined(),
		couponBarrier: yup.number().defined(),
		observationPeriodicity: yup.mixed<Periodicity>().required(),
		totalPeriods: yup.number().defined(),
		feesPercent: yup.number().defined(),
		observationStartPeriod: yup.number().defined(),
		isGaranteedCoupon: yup.bool().defined(),
		conditionalCoupon: yup.number().required(),
		unconditionalCoupon: yup.number().optional(),
		numberOfUnconditionalCoupons: yup.number().defined(),
		// Progressivity
		isAutocallProgressive: yup.bool().defined(),
		progressivityPeriod: yup
			.object<PeriodicInfo>()
			.shape({
				duration: yup.number().defined(),
				period: yup.mixed<Periodicity>().defined(),
			})
			.optional()
			.when("isAutocallProgressive", {
				is: true,
				then: (s) =>
					s.shape({
						duration: yup.number().min(1).defined(),
						period: yup.mixed<Periodicity>().defined(),
					}),
			}),
		progressivityStep: yup
			.number()
			.optional()
			.when("isAutocallProgressive", { is: true, then: (s) => s.required().min(0) }),
		below: yup.mixed<BelowChoices>().required(),
		isInfine: yup.bool().defined(),
	});

export function getPhoenixBearishValidationSchema(): yup.Schema<PhoenixBearishReportConfiguration> {
	const schema: yup.Schema<PhoenixBearishReportConfiguration> = yup.object().shape({
		transversalConfiguration: transversalConfigurationSchema(),
	});

	return schema;
}
