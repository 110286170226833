import React from 'react';

interface Props {
	className?: string;
	type?: 'blinker' | 'spinner';
	size?: 'sm' | 'md' | 'lg' | 'xl';
}

const Loader: React.FC<Props> = ({ className, type = 'spinner', size = 'sm' }) => {
	const classNames = `${type} ${type}-${size} ${className ?? ''}`;
	return <div className={classNames}></div>;
};

export default Loader;
