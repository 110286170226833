import { PhoenixBearishUnderlyingInfo } from "Domain/report.phoenixBearish";
import { PhoenixBearishUnderlyingInfoDto } from "Domain/reportDto.phoenixBearish";

function mapEntityToDto(entity: PhoenixBearishUnderlyingInfo): PhoenixBearishUnderlyingInfoDto {
	const dto: PhoenixBearishUnderlyingInfoDto = {
		code: entity.code,
		displayName: entity.displayName,
		maturity: entity.maturity,
		ticker: entity.ticker,
		maturityYears: entity.maturityYears,
	};
	return dto;
}
function mapDtoToEntity(dto: PhoenixBearishUnderlyingInfoDto): PhoenixBearishUnderlyingInfo {
	const entity: PhoenixBearishUnderlyingInfo = {
		code: dto.code,
		displayName: dto.displayName,
		maturity: dto.maturity,
		ticker: dto.ticker,
		maturityYears: dto.maturityYears,
	};
	return entity;
}
export const PhoenixBearishUnderlyingInfoMappers = {
	mapDtoToEntity,
	mapEntityToDto,
};
