import { AthenaScheduleLine } from "Domain/report.athena";
import { AthenaScheduleLineDto } from "Domain/reportDto.athena";
import { toDateOnly, toJSDate } from "Utilities/date";

function mapEntityToDto(entity: AthenaScheduleLine): AthenaScheduleLineDto {
	const dto: AthenaScheduleLineDto = {
		autocall: entity.autocall,
		coupon: entity.coupon,
		observationDate: toDateOnly(entity.observationDate),
		periodNumber: entity.periodNumber,
		paymentDate: toDateOnly(entity.paymentDate),
		redemptionDate: toDateOnly(entity.redemptionDate),
	};
	return dto;
}

function mapDtoToEntity(dto: AthenaScheduleLineDto): AthenaScheduleLine {
	const entity: AthenaScheduleLine = {
		autocall: dto.autocall,
		coupon: dto.coupon,
		observationDate: toJSDate(dto.observationDate, new Date()),
		periodNumber: dto.periodNumber,
		paymentDate: toJSDate(dto.paymentDate),
		redemptionDate: toJSDate(dto.redemptionDate),
	};
	return entity;
}

function mapEntitiesToDtos(entities: AthenaScheduleLine[]): AthenaScheduleLineDto[] {
	return entities.map(mapEntityToDto);
}

function mapDtosToEntities(dtos: AthenaScheduleLineDto[]): AthenaScheduleLine[] {
	return dtos.map(mapDtoToEntity);
}

export const athenaScheduleLineMapper = {
	mapDtoToEntity,
	mapEntityToDto,
	mapEntitiesToDtos,
	mapDtosToEntities,
};
