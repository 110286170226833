import { ColDef } from "ag-grid-community";
import { EditableGrid } from "components/Shared/FormControls/EditableGrid";
import { BrochureGeneratorAppState } from "Domain/report";
import { PhoenixBearishScheduleLine } from "Domain/reportDto.phoenixBearish";
import { isNil, isEmpty } from "lodash";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editValueCreatorHelper } from "state/Reports/PhoenixBearish/PhoenixBearish.state";
import { usePhoenixBearishValidationStateForField } from "state/Reports/PhoenixBearish/usePhoenixBearishValidationStateForField";

export const PhoenixBearishScheduleLinesTable: FC = () => {
	const errorMessage: string | undefined = usePhoenixBearishValidationStateForField(
		"transversalConfiguration",
		"scheduleLines",
	);
	const isValid = isNil(errorMessage) || isEmpty(errorMessage);
	const lines = useSelector(
		(s: BrochureGeneratorAppState) => s.phoenixBearish.currentReport.transversalConfiguration.scheduleLines,
	);
	const dispatcher = useDispatch();
	const coldef: Array<ColDef<PhoenixBearishScheduleLine>> = [
		{ field: "periodNumber", headerName: "#", maxWidth: 70, type: "numericColumn", editable: false },
		{
			field: "observationDate",
			headerName: "Observation Date",
			type: ["editableDate"],
		},
		{
			field: "autocallBarrier",
			headerName: "Autocall",
			type: ["numericColumn", "editablePercentage"],
		},
		{
			field: "couponBarrier",
			headerName: "Coupon Barrier",
			type: ["numericColumn", "editablePercentage"],
		},
		{
			field: "paymentDate",
			headerName: "Payment Date",
			type: ["editableDate"],
		},
		{
			field: "unconditionalCoupon",
			headerName: "Unconditional Coupon",
			type: ["numericColumn", "editablePercentage"],
		},
		{
			field: "conditionalCoupon",
			headerName: "Conditional Coupon",
			type: ["numericColumn", "editablePercentage"],
		},
	];

	const onLinesChange = (lines: PhoenixBearishScheduleLine[]) => {
		dispatcher(editValueCreatorHelper("transversalConfiguration", "scheduleLines" as any, lines));
	};

	return (
		<>
			<EditableGrid
				initialValue={lines}
				rowIdAccessor={(d) => d.periodNumber.toString()}
				coldef={coldef}
				onChange={onLinesChange}
				containerStyle={{ height: "275px" }}
			/>
			{!isValid && <div className="invalid-feedback d-block">{errorMessage}</div>}
		</>
	);
};
