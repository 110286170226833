import { isNil } from 'lodash';
import numbro from 'numbro';
import * as React from 'react';
import { cssHelper } from '../cssHelper';
import { DefaultInputProps } from './DefaultInputProps';

interface NumericFormControlProps extends DefaultInputProps<number | undefined> {
	formatOptions?: numbro.Format;
	showClearButton?: boolean;
}

const NumericFormControl: React.FC<NumericFormControlProps> = ({
	initialValue,
	onChange,
	formatOptions,
	disabled,
	showClearButton,
	isValid,
}) => {
	const formatNumber = React.useCallback((value: number | undefined) => (!isNil(value) ? numbro(value).format(formatOptions) : ''), [formatOptions]);
	const [localValue, setLocalValue] = React.useState<string>(formatNumber(initialValue));
	React.useEffect(() => {
		setLocalValue(formatNumber(initialValue));
	}, [formatNumber, initialValue, setLocalValue]);

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			validateInputValue();
		}
	};

	const validateInputValue = () => {
		const result = numbro.unformat(localValue);

		if (result === undefined && localValue.length > 0) {
			setLocalValue(formatNumber(initialValue));
		} else if (result !== initialValue) {
			onChange(result);
		}
	};

	const clearInput = () => {
		setLocalValue('');
		onChange(undefined);
	};

	const $input = (
		<input
			type="text"
			className={`form-control ${cssHelper.formValidationStateClassNames(isValid)}`}
			value={localValue}
			onBlur={validateInputValue}
			onKeyDown={onKeyDown}
			onChange={(e) => setLocalValue(e.currentTarget.value)}
			disabled={disabled}
		/>
	);

	if (showClearButton === true && !isNil(initialValue)) {
		return (
			<div className="input-group">
				{$input}
				<button className="btn btn-discreet-primary btn-icon" onClick={clearInput} disabled={disabled}>
					<i className="icon">cancel</i>
				</button>
			</div>
		);
	}

	return $input;
};

export default NumericFormControl;
