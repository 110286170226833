import { ReportTypeKeys, ReportType } from "Domain/report";
import { getEnumValue } from "Utilities/enum";

function mapDtoToEntity(dto: ReportTypeKeys): ReportType {
	const model = getEnumValue(ReportType, dto);

	if (!model) {
		throw new Error(`"${dto}" is not a valid Report type.`);
	}

	return model;
}

export const reportTypeMapper = {
	mapDtoToEntity,
};
