import { find, isEqual } from "lodash";
import { Button, ButtonGroup } from "reactstrap";
import { DefaultInputProps } from "./DefaultInputProps";
import { KeyLabel, KeyLabelItems } from "./FieldConfiguration";
import { cssHelper } from "../cssHelper";
import React, { FC } from "react";

interface RadioButtonGroupProps extends DefaultInputProps<string | undefined, KeyLabel> {
	items: KeyLabelItems;
	className?: string;
}

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
	initialValue,
	onChange,
	items,
	className,
	disabled,
	isValid,
}) => {
	const handleSelection = (selected: string) => {
		const selectedItem = find(items, ({ key }) => isEqual(key, selected));
		if (selectedItem) {
			onChange(selectedItem);
		}
	};
	const classNames = `${className ?? ""} ${cssHelper.formValidationStateClassNames(isValid)}`;

	return (
		<ButtonGroup className={classNames}>
			{items.map((item, i) => (
				<Button
					key={i}
					disable={disabled}
					color="primary"
					outline
					onClick={() => handleSelection(item.key)}
					active={initialValue === item.key}
				>
					{item.label}
				</Button>
			))}
		</ButtonGroup>
	);
};
