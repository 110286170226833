import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as immer from 'immer';
import { Schema } from 'yup';
import { createDefaultTauxFixeReport } from './createDefaultTauxFixeReport';
import { getTauxFixeValidationSchema } from './getTauxFixeValidationSchema';
import { getTauxFixeFormFieldConfiguration } from './getTauxFixeFormFieldConfiguration';
import { assign, cloneDeep, groupBy, range } from 'lodash';
import { ReportInfoItem, Periodicity } from 'Domain/report';
import { TauxFixeReportConfiguration, TauxFixeTransversalConfiguration, TauxFixeScheduleLine } from 'Domain/report.tauxfixe';
import { validateModel } from 'Helpers/validationHelpers';
import { computeDuration } from 'Mappers/Tarn/tarnTransversalConfigurationMapper';
import { LoadingState } from 'Models/LoadingState';
import { FieldConfigurationFromModel } from 'components/Shared/FormControls/FieldConfiguration';


export type TauxFixeState = {
	formState: TauxFixeFormState;
	currentReport: TauxFixeReportConfiguration;
	initialReport: TauxFixeReportConfiguration;
	brochureLoadingState: LoadingState;
	tradeReference: string | undefined;
};

export type TauxFixeReportValidationSchema = Schema<TauxFixeReportConfiguration>;

export type TauxFixeFormState = {
	fieldConfiguration: FieldConfigurationFromModel<TauxFixeReportConfiguration>;
	validationSchema: TauxFixeReportValidationSchema;
	validationState: Record<string, string>;
};

const defaultState: TauxFixeState = {
	formState: {
		fieldConfiguration: getTauxFixeFormFieldConfiguration(),
		validationSchema: getTauxFixeValidationSchema(),
		validationState: {},
	},
	initialReport: createDefaultTauxFixeReport(),
	currentReport: createDefaultTauxFixeReport(),
	brochureLoadingState: LoadingState.Idle,
	tradeReference: undefined,
};

interface EditReportConfigurationValuePayload<
	TReportConfigKey extends keyof TauxFixeReportConfiguration,
	TFieldKey extends keyof TauxFixeReportConfiguration[TReportConfigKey]
> {
	contextKey: TReportConfigKey;
	field: TFieldKey;
	value: TauxFixeReportConfiguration[TReportConfigKey][TFieldKey];
}

const TauxFixeSlice = createSlice({
	initialState: defaultState,
	name: 'TauxFixeReport',
	reducers: {
		editValue: <TReportConfigKey extends keyof TauxFixeReportConfiguration, TFieldKey extends keyof TauxFixeReportConfiguration[TReportConfigKey]>(
			state: immer.Draft<TauxFixeState>,
			action: PayloadAction<EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey>>
		) => {
			const { contextKey, field, value } = action.payload;
			if (state.currentReport) {
				state.currentReport[contextKey][field] = value;
				const durationToComputeKeys: Array<keyof TauxFixeTransversalConfiguration> = ['issueDate', 'maturityDate'];
				if (contextKey === 'transversalConfiguration' && durationToComputeKeys.includes(field as keyof TauxFixeTransversalConfiguration)) {
					state.currentReport.transversalConfiguration.productDuration = computeDuration(
						state.currentReport.transversalConfiguration.issueDate,
						state.currentReport.transversalConfiguration.maturityDate
					);
				}
				if (contextKey === 'transversalConfiguration') {
					const typedField = field as keyof TauxFixeReportConfiguration['transversalConfiguration'];
					const fieldsThatRecompute: Array<keyof TauxFixeTransversalConfiguration> = [
						'fixedCoupon',
						'observationPeriodicity',
						'totalPeriods',
						'isCallable',
						'isInfine',
					];

					if (fieldsThatRecompute.includes(typedField)) {
						const currentTransversalConfig = immer.current(state.currentReport.transversalConfiguration);
						state.currentReport.transversalConfiguration.scheduleLines = recomputeScheduleLines(currentTransversalConfig);
					}
				}
			}
		},

		generateBrochure: (state) => {
			const validationState = validateModel(state.currentReport, state.formState.validationSchema as any as Schema<TauxFixeReportConfiguration>);
			if (validationState) {
				state.formState.validationState = validationState;
			} else {
				state.formState.validationState = {};
				state.brochureLoadingState = LoadingState.Pending;
			}
		},
		generateBrochureSuccess: (state) => {
			state.brochureLoadingState = LoadingState.Resolved;
		},
		generateBrochureError: (state) => {
			state.brochureLoadingState = LoadingState.Error;
		},
		downloadBrochure: (_state, _action: PayloadAction<{ report: ReportInfoItem; success?: () => void; error?: () => void }>) => { },
		initReport: (_state) => { },
		resetReport: (state) => {
			state.currentReport = cloneDeep(state.initialReport);
			state.formState.validationState = {};
		},
		initReportSuccess: (state, action: PayloadAction<{ reportConfiguration: TauxFixeReportConfiguration; tradeReference: string | undefined }>) => {
			state.currentReport = cloneDeep(action.payload.reportConfiguration);
			state.currentReport.transversalConfiguration.totalPeriods = action.payload.reportConfiguration.transversalConfiguration.scheduleLines.length;
			state.initialReport = cloneDeep(state.currentReport);
			state.tradeReference = action.payload.tradeReference;
		},
		updateScheduleLinesFixedCoupon: (state) => {
			state.currentReport.transversalConfiguration.scheduleLines.map(
				(x) =>
				(x.fixedCoupon = getPeriodicFixedCoupon(
					state.currentReport.transversalConfiguration.fixedCoupon,
					state.currentReport.transversalConfiguration.observationPeriodicity
				))
			);
		},
	},
});

function recomputeScheduleLines(transversalConfig: TauxFixeTransversalConfiguration): TauxFixeScheduleLine[] {
	const { fixedCoupon, observationPeriodicity, totalPeriods, isCallable, isInfine, scheduleLines } = transversalConfig;

	if (isCallable === false && isInfine === true) {
		return [];
	}
	const nbPeriods = totalPeriods ?? 1;
	const periodicCoupon = getPeriodicFixedCoupon(fixedCoupon, observationPeriodicity);
	const totalCoupon = periodicCoupon * nbPeriods;
	const isCallableInfine = isCallable === true && isInfine === true;
	const oldLines = groupBy(scheduleLines, (sl) => sl.periodNumber);

	const newLines: Partial<TauxFixeScheduleLine>[] = range(1, nbPeriods + 1).map((periodNumber) => {
		const newItem: Partial<TauxFixeScheduleLine> = {
			periodNumber,
		};
		if (!isCallableInfine) {
			newItem.fixedCoupon = periodNumber * periodicCoupon;
		} else {
			newItem.fixedCoupon = undefined;
			if (periodNumber === totalPeriods) {
				newItem.fixedCoupon = totalCoupon;
			}
		}
		if (oldLines[periodNumber] !== undefined) {
			return assign({}, oldLines[periodNumber][0], newItem);
		}
		return newItem;
	});

	return newLines as TauxFixeScheduleLine[];
}

const getPeriodicFixedCoupon = (coupon: number | undefined, observationPeriodicity: Periodicity | undefined): number => {
	const yearlyCoupon = coupon ?? 0;
	switch (observationPeriodicity) {
		case Periodicity.Years:
			return yearlyCoupon;
		case Periodicity.Semesters:
			return yearlyCoupon / 2;
		case Periodicity.Quarters:
			return yearlyCoupon / 4;
		case Periodicity.Months:
			return yearlyCoupon / 12;
		default:
			return yearlyCoupon;
	}
};

export const editValueCreatorHelper = <
	TReportConfigKey extends keyof TauxFixeReportConfiguration,
	TFieldKey extends keyof TauxFixeReportConfiguration[TReportConfigKey]
>(
	key: TReportConfigKey,
	field: TFieldKey,
	value: TauxFixeReportConfiguration[TReportConfigKey][TFieldKey]
) => {
	const payload: EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey> = {
		contextKey: key,
		field,
		value,
	};
	return TauxFixeSlice.actions.editValue(payload as any);
};

export default TauxFixeSlice;
