import { map } from 'lodash';
import * as React from 'react';
import { cssHelper } from '../cssHelper';
import { DefaultInputProps } from './DefaultInputProps';
import { KeyLabel, KeyLabelItems } from './FieldConfiguration';

interface ButtonGroupFormControlProps extends DefaultInputProps<string | undefined, KeyLabel | undefined> {
	items: KeyLabelItems;
	className?: string;
}

export const ButtonGroupFormControl: React.FC<ButtonGroupFormControlProps> = ({ initialValue, onChange, items, className, disabled, isValid }) => {
	const onValueSelect = (e: React.MouseEvent, value: KeyLabel) => {
		if (disabled) {
			e.preventDefault();
			return;
		}
		if (value.key !== initialValue) {
			onChange(value);
		}
	};

	const displayedItems = items.filter((i) => {
		if (i.hidden === true) return false;
		return true;
	});
	return (
		<div className={`btn-group btn-group-toggle ${className ?? ''} ${cssHelper.formValidationStateClassNames(isValid)}`}>
			{map(displayedItems, (i) => {
				const isSelected = i.key === initialValue;
				return (
					<button
						key={i.key}
						disabled={disabled}
						className={`btn btn-toggle-primary ${isSelected ? 'active' : ''}`}
						onClick={(e) => onValueSelect(e, i)}
					>
						{i.label}
					</button>
				);
			})}
		</div>
	);
};
