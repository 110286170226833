import { isEmpty, isNil } from 'lodash';
import { Schema, ValidationError, setLocale } from 'yup';

export function setValidationDefaultLocale() {
	setLocale({
		mixed: {
			required: 'This field is required.',
			default: 'This field is invalid.',
			defined: 'This field must be defined.',
		},
	});
}

export const GLOBAL_VALIDATION_FIELD = "global";

export function convertToValidationState(validationError: ValidationError): Record<string, string> | null {
	if (!isEmpty(validationError.inner)) {
		const validationState: Record<string, string> = validationError.inner.reduce((acc: Record<string, string>, inner) => {
			if (!isNil(inner.path) && !isEmpty(inner.path)) {
				acc[inner.path] = inner.message.replace(inner.path, '').trim();
			} else {
				acc[GLOBAL_VALIDATION_FIELD] = (acc[GLOBAL_VALIDATION_FIELD] ?? "") + inner.message;
			}
			return acc;
		}, {});
		return validationState;
	}
	return null;
}
export function validateModel<TModel>(currentReport: TModel, validationSchema: Schema<TModel>): Record<string, string> | null {
	try {
		validationSchema.validateSync(currentReport, { recursive: true, abortEarly: false, context: currentReport, strict: true });
	} catch (e: unknown) {
		const validationError = e as ValidationError;
		console.log({ validationError });
		return convertToValidationState(validationError);
	}
	return null;
}
