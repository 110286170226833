
import { useMemo } from 'react';
import { isEmpty, startsWith, chain } from 'lodash';
import { TarnReportConfiguration } from 'Domain/report.tarn';
import { BrochureGeneratorAppState } from 'Domain/report';
import { useSelector } from 'react-redux';

export function useTarnValidationStateForField<TReportConfigKey extends keyof TarnReportConfiguration, TFieldKey extends keyof TarnReportConfiguration[TReportConfigKey]>(
	key: TReportConfigKey,
	field: TFieldKey
): string | undefined {
	const validationState = useSelector((s: BrochureGeneratorAppState) => s.tarn.formState.validationState);
	const fullPath = useMemo(() => `${key}.${field as string}`, [field, key]);
	const result: string[] = chain(validationState)
		.reduce((acc: string[], value, key) => {
			if (startsWith(key, fullPath)) {
				acc.push(value);
			}
			return acc;
		}, [])
		.uniq()
		.value();
	if (!isEmpty(result)) {
		return result.join(', ');
	}
	return undefined;
}
