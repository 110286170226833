/* eslint-disable @typescript-eslint/no-explicit-any */
import { reduce, kebabCase } from "lodash";

export type BaseWidgetProps = {
	id?: string;
};
export function convertOptionsToWIdgetAttributes(options: any): any {
	const widgetOptions: any = reduce(
		options,
		(acc: Record<string, any>, value, key) => {
			const currentValueType = typeof value;
			if (currentValueType === "object" || currentValueType === "boolean") {
				acc[kebabCase(key)] = JSON.stringify(value);
			} else {
				acc[kebabCase(key)] = value;
			}
			return acc;
		},
		{}
	);
	return widgetOptions;
}
