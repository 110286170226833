import { KeyLabel } from "components/Shared/FormControls/FieldConfiguration";
import {
	BaseReportModel,
	BelowChoices,
	ColorPreset,
	DateRange,
	DurationInfo,
	Guid,
	PeriodicInfo,
	Periodicity,
	ProductEligibility,
	ProductLaw,
	ReportType,
} from "./report";
import { PhoenixBearishScheduleLine } from "./reportDto.phoenixBearish";

export type PhoenixBearishReportConfiguration = {
	transversalConfiguration: PhoenixBearishTransversalConfiguration;
};

export type PhoenixBearishTransversalConfiguration = {
	productName: string;
	issuePrice?: number;
	priceRate?: number;
	productIssuer: string;
	minimumInvestment: number;
	productGuarantor?: string;
	issueDate?: Date;
	productCurrency: string;
	maturityDate?: Date;
	productDenomination?: number;
	productDuration: DurationInfo;

	productCotation: string;
	productLaw: ProductLaw;
	bidOffer: number;
	productEligibility?: ProductEligibility;
	commission: number;
	doubleValuation?: string;
	mtmPublication: string;
	isin?: string;
	calculationAgent: string;
	client?: string;
	ftDate?: Date;
	pictureId?: Guid;
	marketingPeriod?: DateRange;
	initialValuationDate: Date;
	hasMarketingPeriod: boolean;
	colorPreset: ColorPreset;

	scheduleLines: PhoenixBearishScheduleLine[];
	totalPeriods: number;
	observationStartPeriod: number;

	underlying: PhoenixBearishUnderlyingInfo;

	autocallBarrier: number;
	couponBarrier: number;
	observationPeriodicity: Periodicity;
	feesPercent: number;
	conditionalCoupon: number;
	isGaranteedCoupon: boolean;
	numberOfUnconditionalCoupons: number;
	unconditionalCoupon?: number;

	// Autocall Progressivity
	isAutocallProgressive: boolean;
	progressivityPeriod?: PeriodicInfo;
	progressivityStep?: number;

	below: BelowChoices;
	isInfine: boolean;
};

export interface PhoenixBearishReportModel extends BaseReportModel {
	type: ReportType.PhoenixBearish;
	configuration: PhoenixBearishReportConfiguration;
}

export type PhoenixBearishUnderlyingInfo = {
	code: string;
	displayName: string;
	maturity: string;
	ticker: string;
	maturityYears: number;
};

export const phoenixBearishUnderlyings: PhoenixBearishUnderlyingInfo[] = [
	{
		code: "CMS.EUR.30Y",
		displayName: "CMS EUR 30 ans",
		maturity: "30Y",
		ticker: "EUAMDB30 Index",
		maturityYears: 30,
	},
	{
		code: "CMS.EUR.20Y",
		displayName: "CMS EUR 20 ans",
		maturity: "20Y",
		ticker: "EUAMDB20 Index",
		maturityYears: 20,
	},
	{
		code: "CMS.EUR.10Y",
		displayName: "CMS EUR 10 ans",
		maturity: "10Y",
		ticker: "EUAMDB10 Index",
		maturityYears: 10,
	},
	{
		code: "CMS.EUR.5Y",
		displayName: "CMS EUR 5 ans",
		maturity: "5Y",
		ticker: "EUAMDB05 Index",
		maturityYears: 5,
	},
	{
		code: "CMS.EUR.2Y",
		displayName: "CMS EUR 2 ans",
		maturity: "2Y",
		ticker: "EUAMDB02 Index",
		maturityYears: 2,
	},
];

export const phoenixBearishUnderlyingItems: KeyLabel[] = phoenixBearishUnderlyings.map((uInfo) => {
	const item: KeyLabel = {
		key: uInfo.code,
		label: uInfo.code,
	};
	return item;
});
