import React, { useRef, memo, useCallback, useEffect, useState, CSSProperties } from "react";
import { type SgPickerCustomEvent } from "@sg-bootstrap/components";
import { SgPicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import _ from "lodash";

export type Props = {
    name: string;
    placeholder?: string;
    items?: { key: string | number; label: string }[];
    /**
     * Uncontrolled
     */
    initialValue?: string[];
    /**
     * Controlled
     */
    value?: string[];
    onChange: (val: string[]) => void;
    createTag?: boolean;
    warning?: boolean;
    singleSelect?: boolean;
    summary?: boolean;
    isReadOnly?: boolean;
    style?: CSSProperties;
    noclear?: boolean;
};

const emptyObject = {};

export const Picker = memo(function Picker(props: Props) {
    const [ready, setReady] = useState(false);
    const ref = useRef<HTMLSgPickerElement>(null);
    const {
        name,
        items = [],
        createTag = false,
        onChange,
        placeholder = "Add a tag",
        initialValue,
        value,
        warning = false,
        singleSelect = false,
        summary = true,
        isReadOnly = false,
        style = emptyObject,
        noclear = false,
    } = props;

    function onReady() {
        if ((value && initialValue) || (value == null && initialValue == null)) {
            throw new Error("Picker can't both be controlled and uncontrolled, but it needs do be one of them");
        }

        // controlled & uncontrolled init
        // @ts-expect-error: We have a sanity check above to know there's at least one of them
        const currentValue: string[] = value || initialValue;

        const items_ = _.uniqBy(
            [...items, ...currentValue.map((val) => ({ key: val, label: items.find((x) => x.key === val)?.label }))],
            "key",
        );
        ref.current?.setItems(items_);
        if (currentValue.length !== 0) {
            initialValue?.forEach((key) => {
                ref.current?.selectItemByKey(key);
            });
        }
        setReady(true);
    }

    // controlled effect
    useEffect(() => {
        if (ready && value) {
            ref.current?.unselectAllItems();
            if (value.length > 0) {
                value?.forEach((key) => ref.current?.selectItemByKey(key));
            }
        }
    }, [ready, value]);

    const internalOnChange = useCallback(
        function internalOnChange(e: SgPickerCustomEvent<unknown>) {
            e.target
                .getSelection()
                .then((selection) => selection.map((x) => x.key))
                .then((val) => {
                    // to avoid infinite loop in controlled mode, assumption: when we change something, the number of selected items change
                    if (value) {
                        if (val.length !== value.length) {
                            onChange(val);
                        } else if (val.length === 1 && value.length === 1 && val[0] !== value[0]) {
                            onChange(val);
                        }
                    } else {
                        onChange(val);
                    }
                });
        },
        [onChange, value],
    );

    return (
        <SgPicker
            keepSearchAfterSelection={!singleSelect}
            summary={summary}
            noIcon
            placeholder={placeholder}
            ref={ref}
            id={name}
            onReady={onReady}
            onItemsSelected={internalOnChange}
            onItemsUnselected={internalOnChange}
            onClear={internalOnChange}
            createTag={createTag}
            state={warning ? "warning" : undefined}
            singleSelect={singleSelect}
            disabled={isReadOnly}
            style={style}
            noClear={noclear}
        />
    );
});
