import { ColDef } from "ag-grid-community";
import { isNil, isEmpty } from "lodash";
import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TarnScheduleLine } from "Domain/report.tarn";
import { EditableGrid } from "components/Shared/FormControls/EditableGrid";
import { BrochureGeneratorAppState } from "Domain/report";
import { useTarnValidationStateForField } from "state/Reports/Tarn/useTarnValidationStateForField";
import { editValueCreatorHelper } from "state/Reports/Tarn/tarn.state";

export const TarnScheduleLinesTable: FC = () => {
	const errorMessage: string | undefined = useTarnValidationStateForField('transversalConfiguration', 'scheduleLines');
	const isValid = isNil(errorMessage) || isEmpty(errorMessage);
	const lines = useSelector((s: BrochureGeneratorAppState) => s.tarn.currentReport.transversalConfiguration.scheduleLines);

	const dispatcher = useDispatch();

	const onLinesChange = (lines: TarnScheduleLine[]) => {
		dispatcher(editValueCreatorHelper('transversalConfiguration', 'scheduleLines' as any, lines));
	};

	const coldef: Array<ColDef<TarnScheduleLine>> = [
		{ field: 'periodNumber', headerName: '#', maxWidth: 70, type: 'numericColumn', editable: false },
		{
			field: 'paymentDate',
			headerName: 'Payment Date',
			type: ['editableDate'],
		},
		{
			field: 'leverage',
			headerName: 'Leverage',
			type: ['numericColumn', 'editablePercentage'],
		},
		{
			field: 'fixedCoupon',
			headerName: 'Coupon',
			type: ['numericColumn', 'editablePercentage'],

		},
	];

	return (
		<>
			<EditableGrid
				initialValue={lines}
				rowIdAccessor={(d) => d.periodNumber.toString()}
				coldef={coldef}
				onChange={onLinesChange}
				containerStyle={{ height: '275px' }}
			/>
			{!isValid && <div className="invalid-feedback d-block">{errorMessage}</div>}
		</>
	);
};
