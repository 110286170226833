import { find, isEmpty, isEqual, isNil, map } from 'lodash';
import * as React from 'react';
import { cssHelper } from "../cssHelper";
import { DefaultInputProps } from './DefaultInputProps';
import { KeyLabel, KeyLabelItems } from './FieldConfiguration';

interface SimpleSelectProps extends DefaultInputProps<string | undefined, KeyLabel | undefined> {
	items: KeyLabelItems;
	className?: string;
	hideEmptyOption?: boolean;
	hasValidationClassNames?: boolean;
}

export const SimpleSelectFormControl: React.FC<SimpleSelectProps> = ({ initialValue, placeHolder, onChange, items, className, disabled, isValid, hideEmptyOption, hasValidationClassNames }) => {
	const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const newValue = e.currentTarget.value;
		const isEmptyValue = isNil(newValue) || isEmpty(newValue);

		const selectedItem = find(items, ({ key }) => isEqual(key, newValue));
		if (selectedItem || (isEmptyValue && newValue !== initialValue)) {
			onChange(selectedItem);
		}
	};
	return (
		<select disabled={disabled} className={`form-select ${className ?? ''} ${hasValidationClassNames == true || hasValidationClassNames == undefined ? cssHelper.formValidationStateClassNames(isValid) : ''}`} value={initialValue ?? ''} onChange={handleSelection}>
			{hideEmptyOption !== true && <option value={''}>{placeHolder ?? "   Select an option   "}</option>}
			{map(items, (i) => {
				return (
					<option key={i.key} value={i.key}>
						{i.label}
					</option>
				);
			})}
		</select>
	);
};
