import { AppTheme } from './theme';

// for E2E tests, they run with CommonJS so no import.meta available
export const sgBootstrapStyleSheet: Record<AppTheme, string> = {
	dark: "https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/5.25.0/core/css/sg-bootstrap-dark.min.css",
	standard: "https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/5.25.0/core/css/sg-bootstrap-standard.min.css",
};
export const sgBootstrapGridStyleSheet: Record<AppTheme, string> = {
	dark: "https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/5.25.0/ag-grid-theme/31-dark{condensed}.min.css",
	standard: "https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/5.25.0/ag-grid-theme/31-standard{condensed}.min.css",
};
