
import { times } from 'lodash';
import { DateTime } from 'luxon';
import { Periodicity, StrikeFeature, ProductCurrency, ProductLaw, UnderlyingIndexationType, ReportType, ProductClient, DefaultColorPreset, UnderlyingType } from 'Domain/report';
import { AthenaReportModel, AthenaReportConfiguration, AthenaScheduleLine } from 'Domain/report.athena';

export function createEmptyAthenaReport(): AthenaReportModel {
	const reportConfig: AthenaReportConfiguration = {
		clientConfiguration: {
			client: undefined,
			pictureId: undefined,
			colorPreset: undefined,
		},
		redemptionConfiguration: {
			observationStartPeriod: 0,
			observationPeriodicity: Periodicity.Years,
			autocallBarrier: 0,
			capitalProtectionBarrier: 0,
			coupon: 0,
			isOxygen: false,
			oxygenBarrier: undefined,
			isAutocallDegressive: false,
			degressivityPeriod: undefined,
			degressivityStep: undefined,
			isLeveraged: false,
			leverageAmount: undefined,
			scheduleLines: [],
			totalPeriods: 0,
			strikeFeature: StrikeFeature.None,
			strikeDates: [],
			strikeInitialPaiementDate: undefined,
			capitalPaymentBusinessDays: undefined,
			couponPaymentBusinessDays: undefined,
			feesPercent: 0,
			isCapitalGuarantee: false,
		},
		transversalConfiguration: {
			productName: '',
			productCotation: 'Euronext Paris',
			calculationAgent: 'Société Générale',
			productIssuer: 'SG ISsuer',
			hasMarketingPeriod: false,
			marketingPeriod: undefined,
			minimumInvestment: 1_000,
			productGuarantor: 'Société Générale',
			bidOfferPercent: 0.01,
			productCurrency: ProductCurrency.EUR,
			isin: '',
			mtmPublication: '',
			issuePrice: 1,
			priceRate: 0,
			ftDate: undefined,
			doubleValuation: 'Finalyse',
			productDenomination: 1_000,
			productLaw: ProductLaw.French,
			commission: 0.01,
			productEligibility: undefined,
			initialValuationDate: DateTime.now().toJSDate(),
			issueDate: DateTime.now().toJSDate(),
			maturityDate: DateTime.now().toJSDate(),
			productDuration: undefined,
		},
		underlyingConfiguration: {
			indexationType: UnderlyingIndexationType.None,

			underlyings: [],
		},
	};
	return {
		configuration: reportConfig,
		type: ReportType.Athena,
		tradeReference: undefined,
	};
}
export function createDefaultAthenaReport(): AthenaReportModel {
	// Options basées sur la termsheet  : TS - Athena Oxygène Action Michelin Avril 2023 .pdf
	// Echéancier(0) : 16 mars 2023, 23 mars 2023, 30 mars 2023, 6 avril 2023 et 13 avril 2023
	// Date d'Evaluation(i):
	// (i de 4 à 20)
	// 15 avril 2024, 15 juillet 2024, 14 octobre 2024, 13 janvier 2025, 14 avril 2025, 14
	// juillet 2025, 13 octobre 2025, 13 janvier 2026, 13 avril 2026, 13 juillet 2026, 13
	// octobre 2026, 13 janvier 2027, 13 avril 2027, 13 juillet 2027, 13 octobre 2027, 13
	// janvier 2028 et 13 avril 2028

	// const observationsDatesRaw = '16 mars 2023, 14 juillet 2023, 13 octobre 2023, 13 janvier 2024, 15 avril 2024, 15 juillet 2024, 14 octobre 2024, 13 janvier 2025, 14 avril 2025, 14 juillet 2025, 13 octobre 2025, 13 janvier 2026, 13 avril 2026, 13 juillet 2026, 13 octobre 2026, 13 janvier 2027, 13 avril 2027, 13 juillet 2027, 13 octobre 2027, 13 janvier 2028 et 13 avril 2028';
	const observationStart = 4;
	const baseCoupon = 0.0025;
	const baseObservationDate = DateTime.fromJSDate(new Date(2023, 6, 1));
	const initialAutocall = 0.9;
	const degresStep = 0.002;
	const totalPeriods = 120;
	const scheduleLines: AthenaScheduleLine[] = times(totalPeriods, (periodNumber) => {
		const period = periodNumber + 1;

		let autocallValue: number | undefined = undefined;
		if (period >= observationStart && period < totalPeriods) {
			autocallValue = initialAutocall - (period - observationStart) * degresStep;
		}

		return {
			periodNumber: period,
			autocall: autocallValue,
			coupon: baseCoupon * period,
			observationDate: baseObservationDate.plus({ month: period }).toJSDate(),
			paymentDate: baseObservationDate.plus({ month: period, day: 1 }).toJSDate(),
			redemptionDate: baseObservationDate.plus({ month: period, day: 2 }).toJSDate(),
			// paymentDate: new Date(2023, 6, 8),
			// redemptionDate: new Date(2023, 6, 2),
		};
	});

	const defaultPeriodUnit: Periodicity = Periodicity.Months;

	const reportConfig: AthenaReportConfiguration = {
		clientConfiguration: {
			client: ProductClient.SwissLife,
			pictureId: 'd0be143f-c42c-4178-96a5-5544fcce0642',
			colorPreset: DefaultColorPreset,
		},

		redemptionConfiguration: {
			observationStartPeriod: observationStart,
			observationPeriodicity: defaultPeriodUnit,
			autocallBarrier: 1,
			capitalProtectionBarrier: 0.3,
			coupon: baseCoupon,
			isOxygen: false,
			oxygenBarrier: undefined,
			isAutocallDegressive: true,
			degressivityPeriod: {
				duration: 1,
				period: defaultPeriodUnit,
			},
			degressivityStep: degresStep,
			isLeveraged: true,
			leverageAmount: 0.1,
			scheduleLines,
			totalPeriods: scheduleLines.length,
			strikeFeature: StrikeFeature.None,
			strikeDates: [],
			strikeInitialPaiementDate: undefined,
			capitalPaymentBusinessDays: undefined,
			couponPaymentBusinessDays: undefined,
			feesPercent: 0.03,
			isCapitalGuarantee: false,
		},
		transversalConfiguration: {
			productName: 'Athena Oxygène Action Michelin Avril 2023',
			productCotation: 'Bourse de Luxembourg',
			calculationAgent: 'Société Générale',
			productIssuer: 'SG Issuer',
			hasMarketingPeriod: true,
			marketingPeriod: {
				startDate: DateTime.fromObject({ day: 10, month: 3, year: 2023 }).toJSDate(),
				endDate: DateTime.fromObject({ day: 13, month: 4, year: 2023 }).toJSDate(),
			},
			minimumInvestment: 1_000,
			productGuarantor: 'Société Générale',
			bidOfferPercent: 0.01,
			productCurrency: ProductCurrency.EUR,
			isin: 'FRSG00013GG7',
			mtmPublication: "",
			issuePrice: 1,
			priceRate: 0.005,
			ftDate: undefined,
			doubleValuation: 'Finalyse',
			productDenomination: 30_000_000,
			productLaw: ProductLaw.French,
			commission: 0.01,
			productEligibility: undefined,
			initialValuationDate: DateTime.now().plus({ year: 1 }).toJSDate(),
			issueDate: DateTime.now().plus({ year: 1 }).toJSDate(),
			maturityDate: DateTime.now().plus({ year: 1 }).toJSDate(),
			productDuration: {
				years: 0,
				months: 0,
				days: 0,
			},
		},
		underlyingConfiguration: {
			indexationType: UnderlyingIndexationType.None,

			underlyings: [
				{
					bloombergTickerCode: 'ML FP',
					type: UnderlyingType.Stocks,
					underlyingName: '',
					isinCode: '',
					isValid: true,
					lastClose: undefined,
				},
			],
		},
	};
	return {
		configuration: reportConfig,
		type: ReportType.Athena,
		tradeReference: undefined,
	};
}
