import { isEmpty } from "lodash";
import { takeEvery, select, put, call } from "typed-redux-saga";
import { BrochureGeneratorApi } from "Api/brochureGeneratorApi";
import { globalNavigate } from "components/App/GlobalHistory";
import { primeToaster } from "components/App/PrimeToasterContainer";
import { athenaReportModelMapper } from "Mappers/Athena/athenaReportModelMapper";
import AthenaSlice from "./Athena.state";
import { BrochureGeneratorAppState } from "Domain/report";

const api = new BrochureGeneratorApi();

export function* athenaSaga(): Generator {
	yield* takeEvery(AthenaSlice.actions.generateBrochure, onGenerateBrochure);
	yield* takeEvery(AthenaSlice.actions.generateBrochureSuccess, onGenerateBrochureSuccess);
	yield* takeEvery(AthenaSlice.actions.generateBrochureError, onGenerateBrochureError);
	yield* takeEvery(AthenaSlice.actions.loadAvailableUnderlyings, onLoadUnderlyings);
}

function* onGenerateBrochure(action: ReturnType<typeof AthenaSlice.actions.generateBrochure>) {
	const { currentReport, formState, tradeReference } = yield* select(
		(state: BrochureGeneratorAppState) => state.athena,
	);

	// Form is invalid
	if (!isEmpty(formState.validationState)) {
		return;
	}

	try {
		const reportDto = athenaReportModelMapper.mapEntityToDto(currentReport, tradeReference);
		yield* call(api.createAthenaReport, reportDto);

		yield* put(AthenaSlice.actions.generateBrochureSuccess());
	} catch (err: any) {
		console.error(err);

		yield* put(AthenaSlice.actions.generateBrochureError());
	}
}

function onGenerateBrochureSuccess() {
	primeToaster.info("Athena", "Brochure is being generated.", "info");
	globalNavigate("/");
}

function onGenerateBrochureError() {
	primeToaster.danger("Generate brochure", "Unable to generate the report.");
}

function* onLoadUnderlyings() {
	try {
		const res = yield* call(api.getAthenaAvailableUnderlyings);
		yield* put(AthenaSlice.actions.loadAvailableUnderlyingsSuccess({ underlyings: res }));
	} catch {
		primeToaster.info("Athena", "Unable to retrieve Athena Underlyings informations.", "info");
	}
}
