import { pullAllWith, isEqual, chain } from "lodash";
import * as React from "react";
import { DefaultInputProps } from "./DefaultInputProps";
import { CategoryPicker, CategoryPickerItem } from "./CategoryPicker";
import NumericFormControl from "./NumericFormControl";
import { AthenaUnderlyingId } from "Domain/report.athena";
import { useSelector } from "react-redux";
import { BrochureGeneratorAppState, StrikeFeature } from "Domain/report";

interface UnderlyingSelectorProps extends DefaultInputProps<AthenaUnderlyingId[]> {
	minSelection?: number;
	maxSelection?: number;
}

const UnderlyingSelector: React.FC<UnderlyingSelectorProps> = ({ initialValue, onChange }) => {
	const availableUnderlyings = useSelector((state: BrochureGeneratorAppState) => state.athena.availableUnderlyings);
	const hasStrikeFeature = useSelector(
		(state: BrochureGeneratorAppState) =>
			state.athena.currentReport.redemptionConfiguration.strikeFeature !== StrikeFeature.None,
	);

	const items: CategoryPickerItem[] = React.useMemo(() => {
		return chain(availableUnderlyings)
			.groupBy((u) => u.type)
			.map((catItems, underType) => {
				const item: CategoryPickerItem = {
					key: "",
					label: underType,
					children: catItems.map((c) => {
						const i: CategoryPickerItem = {
							key: c.bloombergTickerCode,
							label: c.underlyingName,
						};
						return i;
					}),
				};
				return item;
			})
			.value();
	}, [availableUnderlyings]);

	const [selectedUnderlying, setSelectedUnderlying] = React.useState<AthenaUnderlyingId | undefined>(undefined);

	const onRemoveUnderlying = (id: AthenaUnderlyingId) => () => {
		const newValue = pullAllWith([...initialValue], [id], isEqual);
		onChange(newValue);
	};
	function onUnderlyingValueChange<T extends keyof AthenaUnderlyingId>(property: T) {
		return (index: number, value: AthenaUnderlyingId[T]) => {
			const newValue = (initialValue ?? []).map((i) => ({ ...i }));
			newValue[index][property] = value;
			onChange(newValue);
		};
	}

	const onUnderlyngNameChange = onUnderlyingValueChange("underlyingName");

	const onAddToSelection = () => {
		if (selectedUnderlying) {
			const newValue: AthenaUnderlyingId[] = [...initialValue, selectedUnderlying];
			setSelectedUnderlying(undefined);
			onChange(newValue);
		}
	};
	const onUnderlyingSelectionChange = (value: string | undefined) => {
		if (value) {
			const newUnderlyingSelected = availableUnderlyings.find((u) => u.bloombergTickerCode === value);
			if (newUnderlyingSelected) {
				setSelectedUnderlying(newUnderlyingSelected);
			}
		}
	};

	const hasSelection = initialValue.length > 0;

	return (
		<div className="vstack gap-2">
			<div className="hstack gap-3">
				<div className="flex-fill">
					<CategoryPicker
						items={items}
						singleSelect
						noCategorySelect
						onItemSelected={onUnderlyingSelectionChange}
						closeAfterSelect={true}
						size="md"
						placeholder="Search underlying ..."
					/>
				</div>
				<button
					className="btn btn-outline-secondary btn-icon-end"
					onClick={onAddToSelection}
					disabled={selectedUnderlying === undefined}
				>
					Add to selection<i className="icon">add</i>
				</button>
			</div>
			<table className="table table-sm table-striped table-hover mb-0">
				<thead>
					<tr>
						<th>Type</th>
						<th colSpan={!hasStrikeFeature ? 2 : undefined}>Underlying</th>
						{hasStrikeFeature && <th colSpan={2}>Strike</th>}
					</tr>
				</thead>
				<tbody>
					{initialValue.map((currentId, index) => {
						return (
							<tr
								key={currentId.bloombergTickerCode}
								style={{ verticalAlign: "middle" }}
								className={!currentId.isValid ? "bg-opacity-20 bg-danger" : ""}
							>
								<td>
									<span className="badge bg-secondary">{currentId.type}</span>
								</td>
								<td className={hasStrikeFeature ? "w-auto" : "w-100"}>
									<input
										type="text"
										className="form-control"
										value={currentId.underlyingName}
										onChange={(e) => {
											onUnderlyngNameChange(index, e.currentTarget.value);
										}}
									/>
								</td>
								{hasStrikeFeature && (
									<td width={200}>
										<NumericFormControl
											initialValue={currentId.lastClose}
											onChange={(v) => onUnderlyingValueChange("lastClose")(index, v)}
											isValid={hasStrikeFeature && (currentId.lastClose ?? -1) > 0}
										/>
									</td>
								)}
								<td width="48" className="text-center">
									<button className="btn btn-sm btn-discreet-danger btn-icon" onClick={onRemoveUnderlying(currentId)}>
										<i className="icon">delete</i>
									</button>
								</td>
							</tr>
						);
					})}
					{!hasSelection && (
						<tr>
							<td colSpan={2}>
								<div className="alert alert-info">
									No underlying selected. Please pick an underlying and add it to the selection.
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default UnderlyingSelector;
