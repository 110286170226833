import { ReportType } from "Domain/report";
import { AthenaReportConfiguration, AthenaReportModel } from "Domain/report.athena";
import { AthenaReportModelDto, AthenaReportConfigurationDto } from "Domain/reportDto.athena";
import { athenaClientConfigurationMapper } from "./athenaClientConfigurationMapper";
import { athenaRedemptionConfigurationMapper } from "./athenaRedemptionConfigurationMapper";
import { athenaTransversalConfigurationMapper } from "./athenaTransversalConfigurationMapper";
import { athenaUnderlyingConfigurationMapper } from "./athenaUnderlyingConfigurationMapper";

function mapEntityToDto(entity: AthenaReportConfiguration, tradeReference: string | undefined): AthenaReportModelDto {
	const configdto: AthenaReportConfigurationDto = {
		clientConfiguration: athenaClientConfigurationMapper.mapEntityToDto(entity.clientConfiguration),
		redemptionConfiguration: athenaRedemptionConfigurationMapper.mapEntityToDto(entity.redemptionConfiguration),
		transversalConfiguration: athenaTransversalConfigurationMapper.mapEntityToDto(entity.transversalConfiguration),
		underlyingConfiguration: athenaUnderlyingConfigurationMapper.mapEntityToDto(entity.underlyingConfiguration),
	};
	const dto: AthenaReportModelDto = {
		reportConfiguration: configdto,
		type: ReportType.Athena,
		tradeReference
	};
	return dto;
}
function mapDtoToEntity(dto: AthenaReportModelDto): AthenaReportModel {
	const reportConfig: AthenaReportConfiguration = {
		clientConfiguration: athenaClientConfigurationMapper.mapDtoToEntity(dto.reportConfiguration.clientConfiguration),
		redemptionConfiguration: athenaRedemptionConfigurationMapper.mapDtoToEntity(dto.reportConfiguration.redemptionConfiguration),
		transversalConfiguration: athenaTransversalConfigurationMapper.mapDtoToEntity(dto.reportConfiguration.transversalConfiguration),
		underlyingConfiguration: athenaUnderlyingConfigurationMapper.mapDtoToEntity(dto.reportConfiguration.underlyingConfiguration),
	};
	const model: AthenaReportModel = {
		type: ReportType.Athena,
		configuration: reportConfig,
		tradeReference: dto.tradeReference
	};
	return model;
}

export const athenaReportModelMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
