import { fork, put, take } from "typed-redux-saga";
import { resourcesSaga } from "state/Resources/resourcesSaga";
import HomePageSlice from "state/HomePage/HomePage.state";
import { homepageSaga } from "state/HomePage/homepageSaga";
import AthenaSlice from "state/Reports/Athena/Athena.state";
import { athenaSaga } from "state/Reports/Athena/athenaSaga";
import { pictureSaga } from "state/Reports/Athena/pictureSaga";
import { tarnSaga } from "state/Reports/Tarn/tarnSaga";
import { tauxFixeSaga } from "state/Reports/TauxFixe/tauxFixeSaga";
import { authorizationSlice } from "state/authorization/authorizationReducer";
import { authorizationSaga } from "state/authorization/authorizationSaga";
import { monitoringSaga } from "@analytics-web-core/core";
import { phoenixBearishSaga } from "state/Reports/PhoenixBearish/phoenixBearishSaga";

export function* saga() {
	yield* fork(authorizationSaga);
	const action: ReturnType<typeof authorizationSlice.actions.setAuthorized> = yield* take(
		authorizationSlice.actions.setAuthorized,
	);

	if (action.payload.base === true) {
		yield* fork(homepageSaga);
		yield* fork(athenaSaga);
		yield* fork(tarnSaga);
		yield* fork(pictureSaga);
		yield* fork(tauxFixeSaga);
		yield* fork(phoenixBearishSaga);
		yield* fork(resourcesSaga);
		yield* fork(loadInitialData);
		yield* fork(monitoringSaga);
	}
}

function* loadInitialData(): Generator {
	yield* put(AthenaSlice.actions.loadAvailableUnderlyings());
	yield* put(HomePageSlice.actions.loadImages());
}
