import { isEmpty } from "lodash";
import { takeEvery, select, put, call } from "typed-redux-saga";
import { BrochureGeneratorApi } from "Api/brochureGeneratorApi";
import { globalNavigate } from "components/App/GlobalHistory";
import { primeToaster } from "components/App/PrimeToasterContainer";
import { tarnReportModelMapper } from "Mappers/Tarn/tarnReportModelMapper";
import TarnSlice from "./tarn.state";
import { BrochureGeneratorAppState } from "Domain/report";

const api = new BrochureGeneratorApi();

export function* tarnSaga(): Generator {
	yield* takeEvery(TarnSlice.actions.generateBrochure, onGenerateBrochure);
	yield* takeEvery(TarnSlice.actions.generateBrochureSuccess, onGenerateBrochureSuccess);
	yield* takeEvery(TarnSlice.actions.generateBrochureError, onGenerateBrochureError);
}

function* onGenerateBrochure(action: ReturnType<typeof TarnSlice.actions.generateBrochure>) {
	const { currentReport, formState, tradeReference } = yield* select((state: BrochureGeneratorAppState) => state.tarn);

	// Form is invalid
	if (!isEmpty(formState.validationState)) {
		return;
	}

	try {
		const reportDto = tarnReportModelMapper.mapEntityToDto(currentReport, tradeReference);
		yield* call(api.createTarnReport, reportDto);

		yield* put(TarnSlice.actions.generateBrochureSuccess());
	} catch (err: any) {
		console.error(err);

		yield* put(TarnSlice.actions.generateBrochureError());
	}
}

function onGenerateBrochureSuccess() {
	primeToaster.info("Tarn", "Brochure is being generated.", "info");
	globalNavigate("/");
}

function onGenerateBrochureError() {
	primeToaster.danger("Generate brochure", "Unable to generate the tarn report.");
}
