import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrochureGeneratorAppState } from "Domain/report";
import { authorizationSlice } from "state/authorization/authorizationReducer";

export type Props = {
	children?: React.ReactNode;
};

export function AuthorizedApplication({ children }: Props) {
	const dispatch = useDispatch();
	const { base } = useSelector((state: BrochureGeneratorAppState) => state.authorization);

	useEffect(() => {
		if (base !== true) {
			dispatch(authorizationSlice.actions.checkAuthorization());
		}
	}, [dispatch, base]);

	if (base === true) {
		return children;
	} else if (base === false) {
		return "Unauthorized";
	}
	// if null
	return (<div className="service-loader d-flex flex-column">
		<div className="service-loader-logo"></div>
		<div className="progress progress-sm progress-container">
			<div className="progress-bar animated-progress" role="progressbar"></div>
		</div>
	</div>);
}
