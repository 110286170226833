import { productClientItems, productCurrencyItems, productLawItems, productEligibilityItems, underlyingIndexationTypeItems, periodicityItems, strikeFeatureItems } from "Domain/report";
import { AthenaReportConfiguration } from "Domain/report.athena";
import { FieldConfigurationFromModel } from "components/Shared/FormControls/FieldConfiguration";


export function getAthenaFormFieldConfiguration(): FieldConfigurationFromModel<AthenaReportConfiguration> {
	const config: FieldConfigurationFromModel<AthenaReportConfiguration> = {
		clientConfiguration: {
			client: { type: 'List', displayLabel: 'Client *', datasource: productClientItems },
			pictureId: { type: 'Text', displayLabel: 'Photo' },
			colorPreset: 'Preset',
		},
		transversalConfiguration: {
			productName: { type: 'Text', displayLabel: 'Product Name *' },
			productCotation: { type: 'Text', displayLabel: 'Cotation *' },
			calculationAgent: { type: 'Text', displayLabel: 'Calculation *' },
			productIssuer: { type: 'Text', displayLabel: 'Issuer *' },
			hasMarketingPeriod: { type: 'Checkbox', displayLabel: null, valueLabel: 'Offer Period' },
			marketingPeriod: { type: 'DateRange', displayLabel: 'Marketing Dates (Optional)' },
			minimumInvestment: { type: 'Number', displayLabel: 'Minimum Investment *' },
			productGuarantor: { type: 'Text', displayLabel: 'Guarantor *' },
			bidOfferPercent: { type: 'Percent', displayLabel: 'Bid/Offer Spread *' },
			productCurrency: { type: 'List', displayLabel: 'Currency *', datasource: productCurrencyItems },
			isin: { type: 'Text', displayLabel: 'ISIN Code (Optional)' },
			mtmPublication: { type: 'Text', multiline: true, displayLabel: 'Publication Of Liquidative Value *' },
			issuePrice: { type: 'Percent', displayLabel: 'Issue Price *' },
			priceRate: { type: 'Percent', displayLabel: 'Price Rate *' },
			ftDate: { type: 'Date', displayLabel: 'FT Date' },
			doubleValuation: { type: 'Text', displayLabel: 'Double Valuation' },
			productDenomination: { type: 'Number', displayLabel: 'Nominal Value *', format: { thousandSeparated: true } },
			productLaw: { type: 'List', displayLabel: 'Product Law *', datasource: productLawItems },
			commission: { type: 'Percent', displayLabel: 'Commission *' },
			productEligibility: { type: 'List', displayLabel: 'Product Eligibility *', datasource: productEligibilityItems },
			initialValuationDate: { type: 'Date', displayLabel: 'Initial Valuation Date *' },
			issueDate: { type: 'Date', displayLabel: 'Issue Date *' },
			maturityDate: { type: 'Date', displayLabel: 'Maturity Date *' },
			productDuration: { type: 'Duration', displayLabel: 'Product Duration *' },
		},
		underlyingConfiguration: {
			underlyings: { type: 'Underlying', displayLabel: undefined, minSelection: 1, maxSelection: 4 },
			indexationType: { type: 'List', displayLabel: 'Indexation', datasource: underlyingIndexationTypeItems },
		},

		redemptionConfiguration: {
			autocallBarrier: {
				type: 'Percent',
				displayLabel: 'Autocall Barrier *',
			},
			capitalProtectionBarrier: {
				type: 'Percent',
				displayLabel: 'Capital Protection Barrier *',
				hideClearButton: true

			},
			coupon: {
				type: 'Percent',
				displayLabel: 'Coupon *',
			},
			observationStartPeriod: {
				type: 'Number',
				displayLabel: 'Autocall Start Period *',
			},
			observationPeriodicity: {
				type: 'List',
				displayLabel: 'Observation Periodicity *',
				datasource: periodicityItems,
			},
			isLeveraged: {
				type: 'Checkbox',
				displayLabel: null,
				valueLabel: 'Leveraged',
			},
			leverageAmount: {
				type: 'Number',
				displayLabel: 'Leverage Amount (optional)',
			},
			isOxygen: {
				type: 'Checkbox',
				displayLabel: null,
				valueLabel: 'Oxygen',
			},
			oxygenBarrier: {
				type: 'Percent',
				displayLabel: 'Oxygen Barrier (optional)',
			},
			isAutocallDegressive: {
				type: 'Checkbox',
				displayLabel: null,
				valueLabel: 'Autocall Degressive',
			},
			degressivityPeriod: {
				type: 'Period',
				displayLabel: 'Degressivity Period',
				disableUnitEdit: false,
			},
			degressivityStep: {
				type: 'Percent',
				displayLabel: 'Degressivity Step',
			},
			scheduleLines: 'Schedule Lines',
			totalPeriods: { type: 'Number', displayLabel: 'Total Number Of Periods *' },

			strikeFeature: { type: 'ButtonList', displayLabel: 'Strike Feature', datasource: strikeFeatureItems },
			strikeDates: { type: 'DateList', displayLabel: 'Strike Dates', description: 'Dates must be separated by a semicolon (;)' },
			strikeInitialPaiementDate: { type: "Date", displayLabel: "Initial Interest Paiement Date" },
			capitalPaymentBusinessDays: {
				type: 'Number',
				displayLabel: 'Capital Payment Occurs (optional)',
				description: 'Business Days after the corresponding observation date',
			},
			couponPaymentBusinessDays: {
				type: 'Number',
				displayLabel: 'Coupon Payment Occurs (optional)',
				description: 'Business Days after the corresponding observation date',
			},
			feesPercent: { type: 'Percent', displayLabel: 'Fees *', description: 'Used to compute the Net IRR' },
			isCapitalGuarantee: { type: 'Checkbox', valueLabel: 'Is Capital Guarantee ?', displayLabel: null },
		},
	};
	return config;
}
