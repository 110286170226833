import * as React from 'react';
import { DateTime } from 'luxon';
import { SgDaterangepicker } from '@sg-bootstrap/components/dist/react-library/src/components';
import { DefaultInputProps } from './DefaultInputProps';
import { BreakPointsName } from '../cssHelper';
import { DateRange } from 'Domain/report';


interface DateRangePickerFormControlProps extends DefaultInputProps<DateRange | undefined> {
	size?: BreakPointsName;
}

const DateRangePickerFormControl: React.FC<DateRangePickerFormControlProps> = ({ initialValue, onChange, size, isValid, disabled }) => {
	const id = React.useId();
	const key = `${id}_${initialValue ? DateTime.fromJSDate(initialValue.startDate).toMillis() : 'undef'}_${initialValue ? DateTime.fromJSDate(initialValue.endDate).toMillis() : 'undef'
		}`;
	return (
		<SgDaterangepicker
			key={key}
			state={isValid ? undefined : "invalid"}
			valid={isValid}
			disabled={disabled}
			onDateChanged={(e) => {
				if (e.detail.from?.date && e.detail.to?.date) {
					onChange({
						startDate: e.detail.from.date,
						endDate: e.detail.to.date,
					});
				}
			}}
			onInputCleared={(e) => {
				onChange(undefined);
			}}
			startDate={initialValue?.startDate ? DateTime.fromJSDate(initialValue.startDate).valueOf() : undefined}
			endDate={initialValue?.endDate ? DateTime.fromJSDate(initialValue.endDate).valueOf() : undefined}
			size={size as any ?? "md"}
			yearControllers={true}
		/>
	);
};

export default DateRangePickerFormControl;
