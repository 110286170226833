import { Guid, Periodicity, ReportStatus, ReportType } from './report';

export type PeriodicInfoDto = {
	duration: number;
	period: Periodicity;
};

export type ColorPresetDto = {
	name: string;
	accent1?: ColorProfileDto;
	accent2?: ColorProfileDto;
	accent3?: ColorProfileDto;
};

export type ColorProfileDto = {
	name?: string;
	r: number;
	g: number;
	b: number;
	a?: number;
};

export type ValidationResultDto = {
	errorMessage: string;
	fields: string[];
};
export interface ReportInfoItemDto {
	id: Guid;
	name: string;
	isin?: string;
	tradeReference?: string;
	type: ReportType;
	ownerId: string;
	status: ReportStatus;
	creationDate: string;
	processingStartDate?: string;
	processingEndDate?: string;
	availableDocuments: ReportDocumentTypeDto[];
	errors?: ValidationResultDto[];
}

export interface BaseReportModelDto {
	type: ReportType;
	tradeReference: string | undefined;
}

export interface ReportStatusDto {
	id: Guid;
	status: ReportStatus;
	creationDate: string;
	processingDate: string;
	errors?: any[];
}

export type DurationInfoDto = {
	years: number;
	months: number;
	days: number;
};


export enum ReportDocumentTypeDto {
	Unknown = 'Unknown',
	Brochure = 'Brochure',
	BrochurePdf = 'BrochurePdf',
	TraTable = 'TraTable',

}