import { find, isEqual } from "lodash";
import { FormGroup, Input, Label } from "reactstrap";
import { DefaultInputProps } from "./DefaultInputProps";
import { KeyLabel, KeyLabelItems } from "./FieldConfiguration";
import { cssHelper } from "../cssHelper";
import React, { FC } from "react";

interface RadioListProps extends DefaultInputProps<string | undefined, KeyLabel> {
	items: KeyLabelItems;
	className?: string;
}

export const RadioList: FC<RadioListProps> = ({ initialValue, onChange, items, className, disabled, isValid }) => {
	const handleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedItem = find(items, ({ key }) => isEqual(key, e.currentTarget.value));
		if (selectedItem) {
			onChange(selectedItem);
		}
	};
	const classNames = `${className ?? ""} ${cssHelper.formValidationStateClassNames(isValid)}`;

	return (
		<>
			{items.map((item, i) => {
				return (
					<FormGroup key={i} check={true} inline={true} disable={disabled} className={classNames}>
						<Label check>
							<Input
								type="radio"
								name={"strikeFeature"}
								value={item.key}
								onChange={handleSelection}
								checked={initialValue === item.key}
							/>
							{item.label}
						</Label>
					</FormGroup>
				);
			})}
		</>
	);
};
