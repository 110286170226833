import * as React from 'react';
import { DefaultInputProps } from './DefaultInputProps';
import NumericFormControl from './NumericFormControl';
import { isNil } from 'lodash';

interface PercentFormControlProps extends DefaultInputProps<number | undefined> {
	showClearButton?: boolean;
}

const PercentFormControl: React.FC<PercentFormControlProps> = ({ initialValue, onChange, disabled, isValid, showClearButton}) => {
	const onNumberValueChange = (result: number | undefined) => {
		onChange(!isNil(result) ? result / 100 : undefined);
	};
	const clearInput = () => {
		onNumberValueChange(undefined);
	};
	return (
		<div className="input-group input-group-md">
			<NumericFormControl
				initialValue={!isNil(initialValue) ? initialValue * 100 : undefined}
				onChange={onNumberValueChange}
				disabled={disabled}
				isValid={isValid}
			/>
			{showClearButton === true && !isNil(initialValue) && (
				<button className="input-group-text" onClick={clearInput} disabled={disabled}>
					<i className="icon icon-xs">cancel</i>
				</button>
			)}
			<span className="input-group-text">%</span>
		</div>
	);
};

export default PercentFormControl;
