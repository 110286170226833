import "./App.scss";
import React from "react";
import { AuthenticatedApplication, useSgConnect } from "@analytics-web-core/core";
import { ErrorBoundary, SGWTFooter } from "@analytics-web-core/components";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { HomePage } from "Pages/HomePage/HomePage";
import { ResourcesPage } from "Pages/Resources/ResourcesPage";
import { ReportPage } from "Pages/Reports/Report";
import { hasAdminRoleSelector } from "state/authorization/authorizationSelectors";
import { NavLink } from "react-router-dom";
import { AuthorizedApplication } from "components/AuthorizedApplication";
import { NavBar } from "components/NavBar";

const links = [{ path: "/home", label: "Home" }];

export function App() {
	useSgConnect();
	const { pathname } = useLocation();
	const isAdmin = useSelector(hasAdminRoleSelector);
	if (isAdmin && links.find((o) => o.label === "Resources") === undefined) {
		links.push({ path: "/resources", label: "Resources" });
	}

	return (
		<ErrorBoundary fallbackContent={<div>An error happened :/</div>}>
			<AuthenticatedApplication>
				<AuthorizedApplication>
					<div className="d-flex flex-column vh-100">
						<NavBar
							showSgMarketsLogo={false}
							applicationName="Brochure Generator"
							helpCenterApplicationId="brochure-generator"
						>
							{links.map((link) => (
								<li key={link.path} className="navbar-item">
									<NavLink
										to={link.path}
										className={`navbar-link ${pathname === link.path ? "active" : ""}`}
										aria-current="page"
									>
										{link.label}
									</NavLink>
								</li>
							))}
						</NavBar>
						<Routes>
							<Route path="/home" Component={HomePage} />
							<Route path="/reports/*" Component={ReportPage} />
							{isAdmin && <Route path="/resources/*" Component={ResourcesPage} />}
							<Route path="*" element={<Navigate to="/home" />} />
						</Routes>
						<SGWTFooter noBorder contactUsByHelpCenter="sgwt-help-center" />
					</div>
				</AuthorizedApplication>
			</AuthenticatedApplication>
		</ErrorBoundary>
	);
}
