
import { ColorPreset, DefaultColorPreset } from 'Domain/report';
import { ColorPresetDto } from 'Domain/reportDto';
import { colorProfileMapper } from './colorProfileMapper';

function mapEntityToDto(entity: ColorPreset): ColorPresetDto {
	const dto: ColorPresetDto = {
		name: entity.name,
		accent1: entity.accent1,
		accent2: entity.accent2,
		accent3: entity.accent3,
	};
	return dto;
}

function mapDtoToEntity(dto: ColorPresetDto): ColorPreset {
	return {
		name: dto.name,
		accent1: dto.accent1 ? colorProfileMapper.mapDtoToEntity(dto.accent1) : DefaultColorPreset.accent1,
		accent2: dto.accent2 ? colorProfileMapper.mapDtoToEntity(dto.accent2) : DefaultColorPreset.accent2,
		accent3: dto.accent3 ? colorProfileMapper.mapDtoToEntity(dto.accent3) : DefaultColorPreset.accent3,
	};
}

export const colorPresetMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
