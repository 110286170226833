export enum AppTheme {
	Standard = 'standard',
	Dark = 'dark',
}
export enum AgGridTheme {
	Standard = 'standard',
	Dark = 'dark',
}


