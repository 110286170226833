import * as React from 'react';
import { PeriodicInfo, Periodicity, periodicityItems } from 'Domain/report';
import { DefaultInputProps } from './DefaultInputProps';
import { KeyLabel } from './FieldConfiguration';
import NumericFormControl from './NumericFormControl';
import { SimpleSelectFormControl } from './SimpleSelectFormControl';

const periodicityLabels = Object.values(periodicityItems);
interface Props extends DefaultInputProps<PeriodicInfo | undefined> {
	enablePeriodicitySelection: boolean;
}

export const PeriodFormControl: React.FC<Props> = ({ enablePeriodicitySelection, initialValue, onChange, disabled, isValid }) => {
	const currentPeriodicity = initialValue ? initialValue.period : undefined;
	const currentDuration = initialValue ? initialValue.duration : 0;

	const onPeriodicityUnitChange = (newVal: KeyLabel | undefined) => {
		const periodicity = newVal as KeyLabel<Periodicity>;
		onChange({ duration: currentDuration, period: periodicity.key });
	};
	const onDurationChange = (newDuration: number | undefined) => {
		onChange({ duration: newDuration ?? 0, period: currentPeriodicity ?? Periodicity.Years });
	};
	return (
		<div className="row g-1">
			<div className="col">
				<NumericFormControl initialValue={currentDuration} onChange={onDurationChange} disabled={disabled} isValid={isValid} />
			</div>
			<div className="col-auto">
				<SimpleSelectFormControl
					isValid={isValid}
					placeHolder={disabled ? '' : undefined}
					disabled={disabled === true || enablePeriodicitySelection === false}
					initialValue={currentPeriodicity ?? ''}
					items={periodicityLabels}
					onChange={onPeriodicityUnitChange}
				/>
			</div>
		</div>
	);
};
