import * as React from 'react';
import { NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { DisclaimerSection } from './Disclaimers/DisclaimerSection';
import { UnderlyingSection } from './Underlyings/UnderlyingSection';

const RedirectToDisclaimers = () => {
	const nav = useNavigate();
	React.useEffect(() => {
		nav('/resources/disclaimers');
	}, [nav]);

	return null;
};

export const ResourcesPage: React.FC = () => {
	const params = useParams();
	const resourceName = params['*'];
	console.log('Route Params', resourceName);
	return (
		<div className="d-flex flex-column h-100">
			<div className="container-fluid">
				<div className="d-flex align-items-center">
					<h1 className="display-3 py-4 flex-fill">Tool Ressources</h1>
				</div>
				<ul className="nav nav-tabs nav-lg mx-n4 border-primary">
					<li className="nav-item">
						<NavLink to={'/resources/disclaimers'} className="nav-link">
							Disclaimers
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to={'/resources/underlyings'} className="nav-link">
							Underlyings
						</NavLink>
					</li>
				</ul>
			</div>
			<div className="flex-fill bg-lvl2">
				<div className="d-flex flex-column h-100 bg-lvl2 overflow-hidden">
					<div className="flex-fill position-relative">
						<div className="position-absolute w-100 h-100">
							<Routes>
								<Route path={'underlyings'} Component={UnderlyingSection} />
								<Route path={'disclaimers'} Component={DisclaimerSection} />
								<Route index Component={RedirectToDisclaimers} />
							</Routes>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
