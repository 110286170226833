import { ColDef } from "ag-grid-community";
import { isNil, isEmpty } from "lodash";
import React, { FC } from "react";
import { TauxFixeScheduleLine } from "Domain/report.tauxfixe";
import { EditableGrid } from "components/Shared/FormControls/EditableGrid";
import { useTauxFixeValidationStateForField } from "state/Reports/TauxFixe/useTauxFixeValidationStateForField";
import { BrochureGeneratorAppState } from "Domain/report";
import { useSelector, useDispatch } from "react-redux";
import { editValueCreatorHelper } from "state/Reports/TauxFixe/tauxFixe.state";

export const TauxFixeScheduleLinesTable: FC = () => {
	const errorMessage: string | undefined = useTauxFixeValidationStateForField('transversalConfiguration', 'scheduleLines');
	const isValid = isNil(errorMessage) || isEmpty(errorMessage);
	const lines = useSelector((s: BrochureGeneratorAppState) => s.tauxFixe.currentReport.transversalConfiguration.scheduleLines);
	const dispatcher = useDispatch();
	const coldef: Array<ColDef<TauxFixeScheduleLine>> = [
		{ field: 'periodNumber', headerName: '#', maxWidth: 70, type: 'numericColumn', editable: false },
		{
			field: 'paymentDate',
			headerName: 'Payment Date',
			type: ['editableDate'],
		},
		{
			field: 'fixedCoupon',
			headerName: 'Coupon',
			type: ['numericColumn', 'editablePercentage'],
		},
	];

	const onLinesChange = (lines: TauxFixeScheduleLine[]) => {
		dispatcher(editValueCreatorHelper('transversalConfiguration', 'scheduleLines' as any, lines));
	};

	return (
		<>
			<EditableGrid
				initialValue={lines}
				rowIdAccessor={(d) => d.periodNumber.toString()}
				coldef={coldef}
				onChange={onLinesChange}
				containerStyle={{ height: '275px' }}
			/>
			{!isValid && <div className="invalid-feedback d-block">{errorMessage}</div>}
		</>
	);
};
