import { DateTime } from 'luxon';
export const DATE_ONLY_FORMAT = 'yyyy-MM-dd';
export function toJSDate(value: string | undefined): Date | undefined;
export function toJSDate(value: string | undefined, defaultDate: Date): Date;
export function toJSDate(value: string | undefined, defaultDate?: Date): Date | undefined {
	if (!value) {
		if (defaultDate) {
			return defaultDate;
		}

		return undefined;
	}

	const date = DateTime.fromFormat(value, DATE_ONLY_FORMAT, { zone: 'utc' });

	if (date.invalidReason && defaultDate) {
		return defaultDate;
	}

	if (date.invalidReason) {
		return undefined;
	}

	return date.toJSDate();
}

export function toISO(date: Date): string;
export function toISO(date: undefined): undefined;
export function toISO(date: Date | undefined): string | undefined;
export function toISO(date: Date | undefined): string | undefined {
	if (!date) {
		return undefined;
	}

	const formattedDate = DateTime.fromJSDate(date, { zone: 'utc' });

	if (formattedDate.invalidReason) {
		return undefined;
	}

	return formattedDate.toISO() || undefined;
}

export function toDateOnly(date: Date): string;
export function toDateOnly(date: undefined): undefined;
export function toDateOnly(date: Date | undefined): string | undefined;
export function toDateOnly(date: Date | undefined): string | undefined {
	if (!date) {
		return undefined;
	}

	const formattedDate = DateTime.fromJSDate(date);

	if (formattedDate.invalidReason) {
		return undefined;
	}
	return formattedDate.toFormat(DATE_ONLY_FORMAT) || undefined;
}
