import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as immer from 'immer';
import { Schema } from 'yup';
import { createDefaultTarnReport } from './createDefaultTarnReport';
import { getTarnValidationSchema } from './getTarnValidationSchema';
import { getTarnFormFieldConfiguration } from './getTarnFormFieldConfiguration';
import { cloneDeep } from 'lodash';
import { ReportInfoItem, Periodicity } from 'Domain/report';
import { TarnReportConfiguration, TarnTransversalConfiguration, TarnScheduleLine } from 'Domain/report.tarn';
import { validateModel } from 'Helpers/validationHelpers';
import { computeDuration } from 'Mappers/Tarn/tarnTransversalConfigurationMapper';
import { LoadingState } from 'Models/LoadingState';
import { FieldConfigurationFromModel } from 'components/Shared/FormControls/FieldConfiguration';


export type TarnState = {
	formState: TarnFormState;
	initialReport: TarnReportConfiguration;
	currentReport: TarnReportConfiguration;
	brochureLoadingState: LoadingState;
	tradeReference: string | undefined;
};

export type TarnReportValidationSchema = Schema<TarnReportConfiguration>;

export type TarnFormState = {
	fieldConfiguration: FieldConfigurationFromModel<TarnReportConfiguration>;
	validationSchema: TarnReportValidationSchema;
	validationState: Record<string, string>;
};

const defaultState: TarnState = {
	formState: {
		fieldConfiguration: getTarnFormFieldConfiguration(),
		validationSchema: getTarnValidationSchema(),
		validationState: {},
	},
	initialReport: createDefaultTarnReport(false),
	currentReport: createDefaultTarnReport(false),
	brochureLoadingState: LoadingState.Idle,
	tradeReference: undefined,
};

interface EditReportConfigurationValuePayload<
	TReportConfigKey extends keyof TarnReportConfiguration,
	TFieldKey extends keyof TarnReportConfiguration[TReportConfigKey]
> {
	contextKey: TReportConfigKey;
	field: TFieldKey;
	value: TarnReportConfiguration[TReportConfigKey][TFieldKey];
}

const TarnSlice = createSlice({
	initialState: defaultState,
	name: 'tarnReport',
	reducers: {
		editValue: <TReportConfigKey extends keyof TarnReportConfiguration, TFieldKey extends keyof TarnReportConfiguration[TReportConfigKey]>(
			state: immer.Draft<TarnState>,
			action: PayloadAction<EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey>>
		) => {
			const { contextKey, field, value } = action.payload;
			if (state.currentReport) {
				state.currentReport[contextKey][field] = value;
				const durationToComputeKeys: Array<keyof TarnTransversalConfiguration> = ['issueDate', 'maturityDate'];
				if (contextKey === 'transversalConfiguration' && durationToComputeKeys.includes(field as keyof TarnTransversalConfiguration)) {
					state.currentReport.transversalConfiguration.productDuration = computeDuration(
						state.currentReport.transversalConfiguration.issueDate,
						state.currentReport.transversalConfiguration.maturityDate
					);
				}
			}
			if (Object.keys(state.formState.validationState).length > 0) {
				state.formState.validationState =
					validateModel(state.currentReport, state.formState.validationSchema as any as Schema<TarnReportConfiguration>) ?? {};
			}
		},
		recomputeScheduleLines: (state) => {
			const { fixedCouponPeriods, totalPeriods } = state.currentReport.transversalConfiguration;
			if (totalPeriods && fixedCouponPeriods)
				state.currentReport.transversalConfiguration.scheduleLines = generateScheduleLines(state.currentReport.transversalConfiguration);
		},
		generateBrochure: (state) => {
			const validationState = validateModel(state.currentReport, state.formState.validationSchema as any as Schema<TarnReportConfiguration>);
			if (validationState) {
				state.formState.validationState = validationState;
			} else {
				state.formState.validationState = {};
				state.brochureLoadingState = LoadingState.Pending;
			}
		},
		generateBrochureSuccess: (state) => {
			state.brochureLoadingState = LoadingState.Resolved;
		},
		generateBrochureError: (state) => {
			state.brochureLoadingState = LoadingState.Error;
		},
		downloadBrochure: (_state, _action: PayloadAction<{ report: ReportInfoItem; success?: () => void; error?: () => void }>) => { },
		initReport: (_state) => { },
		resetReport: (state) => {
			state.currentReport = cloneDeep(state.initialReport);
			state.formState.validationState = {};
		},
		initReportSuccess: (state, action: PayloadAction<{ reportConfiguration: TarnReportConfiguration; tradeReference: string | undefined }>) => {
			state.currentReport = cloneDeep(action.payload.reportConfiguration);
			state.currentReport.transversalConfiguration.totalPeriods = action.payload.reportConfiguration.transversalConfiguration.scheduleLines.length;
			state.currentReport.transversalConfiguration.fixedCouponPeriods =
				action.payload.reportConfiguration.transversalConfiguration.scheduleLines.filter((x) => x.isFixedCouponApplicable).length;
			state.initialReport = cloneDeep(state.currentReport);
			state.tradeReference = action.payload.tradeReference;
		},
		updateScheduleLinesFixedCoupon: (state) => {
			state.currentReport.transversalConfiguration.scheduleLines
				.filter((x) => x.isFixedCouponApplicable)
				.map(
					(x) =>
					(x.fixedCoupon = computeFixedCoupon(
						state.currentReport.transversalConfiguration.fixedCoupon,
						state.currentReport.transversalConfiguration.observationPeriodicity
					))
				);
		},
		updateScheduleLinesLeverage: (state) => {
			state.currentReport.transversalConfiguration.scheduleLines
				.filter((x) => x.isFloatingCouponApplicable)
				.map((x) => (x.leverage = state.currentReport.transversalConfiguration.leverageAmount ?? 1));
		},
	},
});

const computeFixedCoupon = (fixedCoupon: number | undefined, observationPeriodicity: Periodicity | undefined): number => {
	switch (observationPeriodicity) {
		case Periodicity.Years:
			return fixedCoupon ?? 0;
		case Periodicity.Semesters:
			return fixedCoupon ? fixedCoupon / 2 : 0;
		case Periodicity.Quarters:
			return fixedCoupon ? fixedCoupon / 4 : 0;
		case Periodicity.Months:
			return fixedCoupon ? fixedCoupon / 12 : 0;
	}
	return fixedCoupon ?? 0;
};

const generateScheduleLines = (tarnConfiguration: TarnTransversalConfiguration): TarnScheduleLine[] => {
	const result: TarnScheduleLine[] = [];
	const { fixedCouponPeriods, fixedCoupon, autocallBarrier, totalPeriods, leverageAmount, observationPeriodicity } = tarnConfiguration;

	for (let periodNumber = 1; periodNumber <= fixedCouponPeriods!; periodNumber++) {
		const scheduleLine: TarnScheduleLine = {
			fixedCoupon: computeFixedCoupon(fixedCoupon, observationPeriodicity),
			paymentDate: undefined,
			periodNumber,
			isFixedCouponApplicable: true,
			autocallBarrier,
			isAutocallable: false,
			isFloatingCouponApplicable: false,
			isFloatingCouponLeveraged: false,
			leverage: 0,
		};
		result.push(scheduleLine);
	}

	for (let periodNumber = 1; periodNumber <= totalPeriods! - fixedCouponPeriods!; periodNumber++) {
		const scheduleLine: TarnScheduleLine = {
			fixedCoupon: 0,
			paymentDate: undefined,
			periodNumber: fixedCouponPeriods! + periodNumber,
			isFixedCouponApplicable: false,
			autocallBarrier,
			isAutocallable: true,
			isFloatingCouponApplicable: true,
			isFloatingCouponLeveraged: true,
			leverage: leverageAmount ?? 1,
		};
		result.push(scheduleLine);
	}
	result[result.length - 1].isAutocallable = false; // Last element is never autocallable

	return result;
};

export const editValueCreatorHelper = <
	TReportConfigKey extends keyof TarnReportConfiguration,
	TFieldKey extends keyof TarnReportConfiguration[TReportConfigKey]
>(
	key: TReportConfigKey,
	field: TFieldKey,
	value: TarnReportConfiguration[TReportConfigKey][TFieldKey]
) => {
	const payload: EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey> = {
		contextKey: key,
		field,
		value,
	};
	return TarnSlice.actions.editValue(payload as any);
};

export default TarnSlice;
