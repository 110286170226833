import { AthenaUnderlyingId } from "Domain/report.athena";
import { AthenaUnderlyingIdDto } from "Domain/reportDto.athena";
import { athenaUnderlyingTypeMapper } from "./athenaUnderlyingTypeMapper";

function mapEntityToDto(entity: AthenaUnderlyingId): AthenaUnderlyingIdDto {
	return {
		type: entity.type,
		underlyingName: entity.underlyingName,
		bloombergTickerCode: entity.bloombergTickerCode,
		isinCode: entity.isinCode,
		isValid: entity.isValid,
		lastCLose: entity.lastClose,
	};
}

function mapEntitiesToDtos(entities: AthenaUnderlyingId[]): AthenaUnderlyingIdDto[] {
	return entities.map(mapEntityToDto);
}

function mapDtoToEntity(dto: AthenaUnderlyingIdDto): AthenaUnderlyingId {
	return {
		type: athenaUnderlyingTypeMapper.mapDtoToEntity(dto.type),
		underlyingName: dto.underlyingName,
		bloombergTickerCode: dto.bloombergTickerCode,
		isinCode: dto.isinCode,
		isValid: dto.isValid,
		lastClose: dto.lastCLose,
	};
}

function mapDtosToEntities(dtos: AthenaUnderlyingIdDto[]): AthenaUnderlyingId[] {
	return dtos.map(mapDtoToEntity);
}

export const athenaUnderlyingIdMapper = {
	mapDtoToEntity,
	mapDtosToEntities,
	mapEntityToDto,
	mapEntitiesToDtos,
};
