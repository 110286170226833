import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createRoot } from "react-dom/client";
import { App } from "./components/App/App";
import React from "react";
import { Provider } from "react-redux";
import { LicenseManager } from "ag-grid-enterprise";
import { reducer } from "./state/root/rootReducer";
import { saga } from "./state/root/rootSaga";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { GlobalHistory } from "./components/App/GlobalHistory";

LicenseManager.setLicenseKey(
	"CompanyName=SOCIETE GENERALE,LicensedApplication=SG Markets Prime,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-026866,ExpiryDate=11_May_2025_[v2]_MTc0NjkxODAwMDAwMA==bfaf57d532d7ec519b1adbc3e5f07f1e",
);

const sagaMW = createSagaMiddleware();

const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
		thunk: false,
	}).concat(sagaMW),
	devTools: process.env.NODE_ENV !== "production",
});

const root = createRoot(document.querySelector("#root")!);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function render(Component: any, strict = false) {
	const inner = (
		<>
			<Provider store={store}>
				<BrowserRouter basename="/">
					<GlobalHistory />
					<Component />
				</BrowserRouter>
			</Provider>
			<ToastContainer />
		</>
	);
	if (strict) {
		console.warn("REACT STRICT MODE IS ON");
		console.warn(
			`/** FOR THOSE WHO ARE DEBUGGING:
	DON'T FORGET THAT IN STRICT MODE COMPONENT A IMMEDIATELY MOUNTED, THEN UNMOUNTED, THEN MOUNTED AGAIN
	MOST HOOKS ARE CALLED MULTIPLE TIMES**/`,
		);
		return root.render(<React.StrictMode>{inner}</React.StrictMode>);
	}
	return root.render(inner);
}

sagaMW.run(saga);

render(App, !!module.hot);

/**
 * Live reload section
 */
if (module.hot) {
	module.hot.accept("components/App/App", () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const NextAppContainer = require("components/App/App").App;
		render(NextAppContainer, true);
	});
}
