import React from "react";
import { ReportStatus } from "Domain/report";

interface StatusBadgeProps {
	status: ReportStatus;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
	const colors: Record<ReportStatus, string> = {
		[ReportStatus.Created]: 'primary',
		[ReportStatus.Error]: 'danger',
		[ReportStatus.Processed]: 'success',
		[ReportStatus.Processing]: 'info',
		[ReportStatus.Unknown]: 'outline-primary',
	};

	if (status === ReportStatus.Processing) {
		return (
			<button className={`btn btn-sm btn-${colors[status]} btn-icon-start loading`}>
				<span className="spinner spinner-sm"></span> {status}
			</button>
		);
	}

	return <div className={`badge badge-${colors[status]} mt-2`}>{status}</div>;
};
