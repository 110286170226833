import { FC, ReactNode, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import React from 'react';
export type PositionnerProps = {
	reference: HTMLElement | null;
	children?: ReactNode | undefined;
	visible: boolean;
};

export const Positionner: FC<PositionnerProps> = ({ children, reference, visible }) => {
	const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
	const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
		placement: 'bottom-start',
		strategy: 'absolute',
		modifiers: [
			{
				name: 'flip',
				options: {},
			},
		],
	});

	useEffect(() => {
		setReferenceElement(reference);
		if (update) {
			setTimeout(() => {
				update();
			}, 0);
		}
	}, [reference, update]);

	if (!visible) return null;

	return ReactDOM.createPortal(
		<div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
			{children}
		</div>,
		document.body
	);
};
