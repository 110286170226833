
import React from 'react';
import { DurationInfo } from 'Domain/report';
import { DefaultInputProps } from './DefaultInputProps';
import NumericFormControl from './NumericFormControl';

interface DurationInfoFormControlProps extends DefaultInputProps<DurationInfo> { }

const DurationInfoFormControl: React.FC<DurationInfoFormControlProps> = ({ initialValue, onChange, disabled, isValid }) => {
	const { years, months, days } = initialValue;

	const onFieldValueChange = (field: keyof DurationInfo) => (newValue: number | undefined) => {
		const newDuration: DurationInfo = { ...initialValue, [field]: newValue ?? 0 };
		onChange(newDuration);
	};

	return (
		<div>
			<div className="input-group">
				<span className="input-group-text">Years</span>
				<NumericFormControl
					isValid={undefined}
					initialValue={years}
					onChange={onFieldValueChange('years')}
					disabled={disabled}
					formatOptions={{ mantissa: 0 }}
				/>

				<span className="input-group-text">Months</span>
				<NumericFormControl
					isValid={undefined}
					initialValue={months}
					onChange={onFieldValueChange('months')}
					disabled={disabled}
					formatOptions={{ mantissa: 0 }}
				/>

				<span className="input-group-text">Days</span>
				<NumericFormControl
					isValid={undefined}
					initialValue={days}
					onChange={onFieldValueChange('days')}
					disabled={disabled}
					formatOptions={{ mantissa: 0 }}
				/>
			</div>
		</div>
	);
};

export default DurationInfoFormControl;
