import { ProductCurrency } from "Domain/report";
import { getEnumValue } from "Utilities/enum";


function mapDtoToEntity(key: string): ProductCurrency {
	return getEnumValue(ProductCurrency, key, ProductCurrency.USD);
}

export const productCurrencyMapper = {
	mapDtoToEntity,
};
