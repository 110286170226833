import { ReportType } from "Domain/report";
import { TauxFixeReportConfiguration, TauxFixeReportModel } from "Domain/report.tauxfixe";
import { TauxFixeReportModelDto, TauxFixeReportConfigurationDto } from "Domain/reportDto.tauxfixe";
import { TauxFixeTransversalConfigurationMapper } from "./tauxFixeTransversalConfigurationMapper";

function mapEntityToDto(entity: TauxFixeReportConfiguration, tradeReference: string | undefined): TauxFixeReportModelDto {
	const configdto: TauxFixeReportConfigurationDto = {
		transversalConfiguration: TauxFixeTransversalConfigurationMapper.mapEntityToDto(entity.transversalConfiguration),

	};
	const dto: TauxFixeReportModelDto = {
		reportConfiguration: configdto,
		type: ReportType.TauxFixe,
		tradeReference
	};
	return dto;
}
function mapDtoToEntity(dto: TauxFixeReportModelDto): TauxFixeReportModel {
	const reportConfig: TauxFixeReportConfiguration = {
		transversalConfiguration: TauxFixeTransversalConfigurationMapper.mapDtoToEntity(dto.reportConfiguration.transversalConfiguration),
	};
	const model: TauxFixeReportModel = {
		type: ReportType.TauxFixe,
		configuration: reportConfig,
		tradeReference: dto.tradeReference
	};
	return model;
}

export const tauxFixeReportModelMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};

