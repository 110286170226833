import React from "react";
import { TarnTransversalConfiguration, tarnUnderlyings, underlyingItems } from "Domain/report.tarn";
import { KeyLabel } from "components/Shared/FormControls/FieldConfiguration";
import { SimpleSelectFormControl } from "components/Shared/FormControls/SimpleSelectFormControl";
import { BrochureGeneratorAppState } from "Domain/report";
import { useDispatch, useSelector } from "react-redux";
import { editValueCreatorHelper } from "state/Reports/Tarn/tarn.state";


const TarnRow: React.FC<{
	udlField: keyof Pick<TarnTransversalConfiguration, 'firstUnderlying' | 'secondUnderlying'>;
}> = ({ udlField }) => {
	const dispatcher = useDispatch();
	const underlyingInfo = useSelector((s: BrochureGeneratorAppState) => {
		if (s.tarn.currentReport) {
			return s.tarn.currentReport.transversalConfiguration[udlField];
		}
		return undefined;
	});

	const onChange = (value: KeyLabel<string> | undefined) => {
		const selectedUnderlyingInfo = tarnUnderlyings.find((uInfo) => uInfo.code === value?.key);
		if (selectedUnderlyingInfo) {
			dispatcher(editValueCreatorHelper('transversalConfiguration', udlField as any, selectedUnderlyingInfo));
		}
	};

	return (
		<tr>
			<td>
				<SimpleSelectFormControl isValid={undefined} initialValue={underlyingInfo?.code || ''} onChange={onChange} items={underlyingItems} />
			</td>
			<td>{udlField === 'firstUnderlying' ? 'Taux long' : 'Taux court'}</td>
			<td>{underlyingInfo!.maturity}</td>
			<td>{underlyingInfo!.ticker}</td>
		</tr>
	);
};

const TarnUnderlyingSelector: React.FC = () => {
	return (
		<table className="table table-sm table-striped border align-middle">
			<thead>
				<tr>
					<th>Code</th>
					<th>Display Name</th>
					<th>Maturity</th>
					<th>Ticker</th>
				</tr>
			</thead>
			<tbody>
				<TarnRow udlField={'firstUnderlying'} />
				<TarnRow udlField={'secondUnderlying'} />
			</tbody>
		</table>
	);
};

export default TarnUnderlyingSelector;
