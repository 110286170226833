import React from "react";
import { KeyLabel } from "components/Shared/FormControls/FieldConfiguration";
import { SimpleSelectFormControl } from "components/Shared/FormControls/SimpleSelectFormControl";
import { BrochureGeneratorAppState } from "Domain/report";
import { useDispatch, useSelector } from "react-redux";
import {
	PhoenixBearishTransversalConfiguration,
	phoenixBearishUnderlyingItems,
	phoenixBearishUnderlyings,
} from "Domain/report.phoenixBearish";
import { editValueCreatorHelper } from "state/Reports/PhoenixBearish/PhoenixBearish.state";

const PhoenixBearishRow: React.FC<{
	udlField: keyof Pick<PhoenixBearishTransversalConfiguration, "underlying">;
}> = ({ udlField }) => {
	const dispatcher = useDispatch();
	const underlyingInfo = useSelector((s: BrochureGeneratorAppState) => {
		if (s.phoenixBearish.currentReport) {
			return s.phoenixBearish.currentReport.transversalConfiguration[udlField];
		}
		return undefined;
	});

	const onChange = (value: KeyLabel<string> | undefined) => {
		const selectedUnderlyingInfo = phoenixBearishUnderlyings.find((uInfo) => uInfo.code === value?.key);
		if (selectedUnderlyingInfo) {
			dispatcher(editValueCreatorHelper("transversalConfiguration", udlField as any, selectedUnderlyingInfo));
		}
	};

	return (
		<tr>
			<td>
				<SimpleSelectFormControl
					isValid={undefined}
					initialValue={underlyingInfo?.code || ""}
					onChange={onChange}
					items={phoenixBearishUnderlyingItems}
				/>
			</td>
			<td>{underlyingInfo!.maturity}</td>
			<td>{underlyingInfo!.ticker}</td>
		</tr>
	);
};

const PhoenixBearishUnderlyingSelector: React.FC = () => {
	return (
		<table className="table table-sm table-striped border align-middle">
			<thead>
				<tr>
					<th>Code</th>
					<th>Maturity</th>
					<th>Ticker</th>
				</tr>
			</thead>
			<tbody>
				<PhoenixBearishRow udlField={"underlying"} />
			</tbody>
		</table>
	);
};

export default PhoenixBearishUnderlyingSelector;
