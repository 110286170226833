import { UnderlyingType } from "Domain/report";
import { getEnumValue } from "Utilities/enum";

function mapDtoToEntity(key: string): UnderlyingType {
	return getEnumValue(UnderlyingType, key, UnderlyingType.Stocks);
}

export const athenaUnderlyingTypeMapper = {
	mapDtoToEntity,
};
