import { TarnUnderlyingInfo } from "Domain/report.tarn";
import { TarnUnderlyingInfoDto } from "Domain/reportDto.tarn";

function mapEntityToDto(entity: TarnUnderlyingInfo): TarnUnderlyingInfoDto {
	const dto: TarnUnderlyingInfoDto = {
		code: entity.code,
		displayName: entity.displayName,
		maturity: entity.maturity,
		ticker: entity.ticker,
		maturityYears: entity.maturityYears,
	};
	return dto;
}
function mapDtoToEntity(dto: TarnUnderlyingInfoDto): TarnUnderlyingInfo {
	const entity: TarnUnderlyingInfo = {
		code: dto.code,
		displayName: dto.displayName,
		maturity: dto.maturity,
		ticker: dto.ticker,
		maturityYears: dto.maturityYears,
	};
	return entity;
}
export const TarnUnderlyingInfoMappers = {
	mapDtoToEntity,
	mapEntityToDto,
};
