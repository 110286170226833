import React, { createElement, createRef, useEffect } from 'react';
import { BrochureGeneratorAppState } from 'Domain/report';
import { useDispatch, useSelector } from 'react-redux';
import HomePageSlice from 'state/HomePage/HomePage.state';

interface Props {
	className?: string;
}

export const ImpersonatedUserInput: React.FC<Props> = () => {
	const dispatcher = useDispatch();
	const globalId = useSelector((a: BrochureGeneratorAppState) => a.homepage.impersonatedUserId);

	const theRef = createRef<HTMLElement>();

	const $elemnt = createElement('ic-contact-single-picker', {
		size: 'lg',
		placeholder: 'search contact',
		'internal-only': true,
		scope: 'internal',
		'selected-id': globalId,
		key: 'plop',
		ref: theRef,
	});

	useEffect(() => {
		theRef.current?.addEventListener('ic-contact-single-picker--changed', (e) => {
			const eData = e as CustomEvent<{ contact?: { email: string; id: string } }>;
			const email = eData.detail.contact?.email;
			const userId = eData.detail.contact?.id;

			dispatcher(HomePageSlice.actions.setImpersonatedUserEmail({ email, userId }));
		});
	}, [dispatcher, theRef]);

	return $elemnt;
};
