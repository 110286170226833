import { FieldConfigurationFromModel } from "components/Shared/FormControls/FieldConfiguration";
import {
	belowItems,
	periodicityItems,
	productClientItems,
	productCurrencyItems,
	productEligibilityItems,
	productLawItems,
} from "Domain/report";
import { PhoenixBearishReportConfiguration } from "Domain/report.phoenixBearish";

export function getPhoenixBearishFormFieldConfiguration(): FieldConfigurationFromModel<PhoenixBearishReportConfiguration> {
	const config: FieldConfigurationFromModel<PhoenixBearishReportConfiguration> = {
		transversalConfiguration: {
			productName: { type: "Text", displayLabel: "Product Name *" },
			issuePrice: { type: "Percent", displayLabel: "Issue Price *" },
			priceRate: { type: "Percent", displayLabel: "Price Rate *" },
			productIssuer: { type: "Text", displayLabel: "Issuer *" },
			minimumInvestment: { type: "Number", displayLabel: "Minimum Investment *" },
			productGuarantor: { type: "Text", displayLabel: "Guarantor (optional)" },
			issueDate: { type: "Date", displayLabel: "Issue Date *" },
			productCurrency: { type: "List", displayLabel: "Currency *", datasource: productCurrencyItems },
			maturityDate: { type: "Date", displayLabel: "Maturity Date *" },
			productDenomination: { type: "Number", displayLabel: "Nominal Value *" },
			productDuration: { type: "Duration", displayLabel: "Product Duration *" },

			productCotation: { type: "Text", displayLabel: "Cotation *" },
			productLaw: { type: "List", displayLabel: "Product Law *", datasource: productLawItems },
			bidOffer: { type: "Percent", displayLabel: "Bid/Offer Spread *" },
			productEligibility: { type: "List", displayLabel: "Product Eligibility *", datasource: productEligibilityItems },
			commission: { type: "Percent", displayLabel: "Commission *" },
			marketingPeriod: { type: "DateRange", displayLabel: "Marketing Dates (optional)" },
			doubleValuation: { type: "Text", displayLabel: "Double Valuation *" },
			mtmPublication: { type: "Text", displayLabel: "Publication Of Liquidative Value *", multiline: false },
			isin: { type: "Text", displayLabel: "ISIN Code (optional)" },
			calculationAgent: { type: "Text", displayLabel: "Calculation *" },
			client: { type: "List", displayLabel: "Client *", datasource: productClientItems },
			initialValuationDate: { type: "Date", displayLabel: "Initial Valuation Date" },
			ftDate: { type: "Date", displayLabel: "FT Date (optional)" },
			hasMarketingPeriod: { type: "Checkbox", displayLabel: null, valueLabel: "Offer Period" },
			colorPreset: "Preset *",

			scheduleLines: "Schedule Lines",
			totalPeriods: { type: "Number", displayLabel: "Total Number Of Periods *" },
			observationStartPeriod: { type: "Number", displayLabel: "Autocall Start Period" },

			underlying: "Underlying *",

			autocallBarrier: { type: "Percent", displayLabel: "Autocall Barrier" },
			couponBarrier: { type: "Percent", displayLabel: "Conditional Coupon Barrier" },
			observationPeriodicity: { type: "List", displayLabel: "Observation Periodicity", datasource: periodicityItems },
			conditionalCoupon: { type: "Percent", displayLabel: "Coupon" },
			feesPercent: { type: "Percent", displayLabel: "Fees", description: "Used to compute the Net IRR" },

			isAutocallProgressive: { type: "Checkbox", displayLabel: null, valueLabel: "Autocall Progressive" },
			progressivityPeriod: { type: "Period", displayLabel: "Progressivity Period *", disableUnitEdit: false },
			progressivityStep: { type: "Percent", displayLabel: "Progressivity Step *" },

			isGaranteedCoupon: { type: "Checkbox", displayLabel: null, valueLabel: "Has fixed coupon" },
			unconditionalCoupon: { type: "Percent", displayLabel: "Fixed Coupons *" },
			numberOfUnconditionalCoupons: { type: "Number", displayLabel: "Number Of Fixed Periods *" },
			below: { type: "List", displayLabel: "Below", datasource: belowItems },
			isInfine: { type: "Checkbox", valueLabel: "Is in fine ?", displayLabel: "" },
		},
	};
	return config;
}
