import { AthenaRedemptionConfiguration } from "Domain/report.athena";
import { AthenaRedemptionConfigurationDto } from "Domain/reportDto.athena";
import { toDateOnly, toJSDate } from "Utilities/date";
import { athenaScheduleLineMapper } from "./athenaScheduleLineMapper";

function mapEntityToDto(entity: AthenaRedemptionConfiguration): AthenaRedemptionConfigurationDto {
	const dto: AthenaRedemptionConfigurationDto = {
		autocallBarrier: entity.autocallBarrier,
		capitalProtectionBarrier: entity.capitalProtectionBarrier,
		coupon: entity.coupon,
		observationPeriodicity: entity.observationPeriodicity,
		observationStartPeriod: entity.observationStartPeriod,
		isOxygen: entity.isOxygen,
		oxygenBarrier: entity.isOxygen ? entity.oxygenBarrier : undefined,
		isAutocallDegressive: entity.isAutocallDegressive,
		degressivityStep: entity.isAutocallDegressive ? entity.degressivityStep : undefined,
		degressivityPeriod: entity.isAutocallDegressive ? entity.degressivityPeriod : undefined,
		isLeveraged: entity.isLeveraged,
		leverageAmount: entity.leverageAmount,
		scheduleLines: athenaScheduleLineMapper.mapEntitiesToDtos(entity.scheduleLines),
		strikeFeature: entity.strikeFeature,
		strikeDates: entity.strikeDates.map((d) => toDateOnly(d)),
		strikeInitialPaiementDate: entity.strikeInitialPaiementDate
			? toDateOnly(entity.strikeInitialPaiementDate)
			: undefined,
		capitalPaymentBusinessDays: entity.capitalPaymentBusinessDays,
		couponPaymentBusinessDays: entity.couponPaymentBusinessDays,
		feesPercent: entity.feesPercent,
		isCapitalGuarantee: entity.isCapitalGuarantee,
	};
	return dto;
}
function mapDtoToEntity(dto: AthenaRedemptionConfigurationDto): AthenaRedemptionConfiguration {
	const entity: AthenaRedemptionConfiguration = {
		observationPeriodicity: dto.observationPeriodicity,
		observationStartPeriod: dto.observationStartPeriod,
		autocallBarrier: dto.autocallBarrier,
		coupon: dto.coupon,
		capitalProtectionBarrier: dto.capitalProtectionBarrier,
		isLeveraged: dto.isLeveraged,
		leverageAmount: dto.leverageAmount,
		isOxygen: dto.isOxygen,
		oxygenBarrier: dto.oxygenBarrier,
		isAutocallDegressive: dto.isAutocallDegressive,
		degressivityPeriod: dto.degressivityPeriod,
		degressivityStep: dto.degressivityStep,
		scheduleLines: athenaScheduleLineMapper.mapDtosToEntities(dto.scheduleLines),
		totalPeriods: dto.scheduleLines.length,
		strikeFeature: dto.strikeFeature,
		strikeDates: dto.strikeDates.map((d) => toJSDate(d, new Date())),
		strikeInitialPaiementDate: dto.strikeInitialPaiementDate
			? toJSDate(dto.strikeInitialPaiementDate, new Date())
			: undefined,
		capitalPaymentBusinessDays: dto.capitalPaymentBusinessDays,
		couponPaymentBusinessDays: dto.couponPaymentBusinessDays,
		feesPercent: dto.feesPercent,
		isCapitalGuarantee: dto.isCapitalGuarantee,
	};
	return entity;
}

export const athenaRedemptionConfigurationMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
