import React, { useEffect, useRef } from "react";
import {
	SGWTHelpCenter,
	SgMarketsLogo,
	SgLogo,
	NavigateAsItem,
	SGWTSplashScreen,
} from "@analytics-web-core/components";
import HomePageSlice from "state/HomePage/HomePage.state";
import { useThemeToggler } from "./App/useThemeToggler";
import { useSelector, useDispatch } from "react-redux";

import { SGWTAccountCenter } from "./SGWT/SGWTAccountCenter";
import { BrochureGeneratorAppState } from "Domain/report";

export type Props = {
	applicationName?: string;
	showSgMarketsLogo?: boolean;
	helpCenterApplicationId?: string;
	bootstrapTheme?: string;
	children?: React.ReactNode;
};

export function NavBar({
	applicationName,
	helpCenterApplicationId,
	bootstrapTheme = "standard",
	showSgMarketsLogo = true,
	children,
}: Props) {

	useThemeToggler();
	const widgetRef = useRef<HTMLElement>();
	const impersonatedUserEmail = useSelector((state: BrochureGeneratorAppState) => state.homepage.impersonatedUserEmail);
	const currentTheme = useSelector((state: BrochureGeneratorAppState) => state.homepage.currentTheme);
	const dispatcher = useDispatch();

	useEffect(() => {
		widgetRef.current?.addEventListener('sgwt-account-center--stop-navigation-as', () => {
			dispatcher(HomePageSlice.actions.setImpersonatedUserEmail({ email: undefined, userId: undefined }));
		});
		widgetRef.current?.addEventListener('sgwt-account-center--theme-changed', (e) => {
			dispatcher(HomePageSlice.actions.setApplicationTheme({ theme: (e as any).detail.theme }));
		});
	}, [widgetRef, dispatcher]);

	useEffect(() => {
		if (widgetRef.current) {
			const navAsUser: NavigateAsItem | undefined = impersonatedUserEmail
				? {
					name: impersonatedUserEmail,
				}
				: undefined;
			(widgetRef.current as any).setNavigateAsUser(navAsUser);
		}
	}, [impersonatedUserEmail, widgetRef]);

	return (
		<div className="navbar pe-3">
			<div className="navbar-title">
				<a href="/" className="navbar-title-link">
					<SgLogo bootstrapTheme={currentTheme} />
					<div className="navbar-title-divider" />
					<div className="navbar-service-name">{applicationName}</div>
				</a>
				{/** @fixme: finish mobile nav ? */}
				{/* <button className="navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center" type="button">
					<em className="icon">menu</em>
				</button> */}
			</div>
			<div className="navbar-content">
				<div className="navbar-navigation">{children}</div>
			</div>
			<div className="navbar-toolbar">
				<SGWTSplashScreen applicationId={"brochure-generator"} />
				<SGWTAccountCenter language="en" ref={widgetRef} showSignInButton={true} themeSwitcher />
				{helpCenterApplicationId ? (
					<SGWTHelpCenter applicationId={helpCenterApplicationId} />
				) : null}
				{showSgMarketsLogo && (
					<div className="d-none d-xl-block ml-4">
						<SgMarketsLogo bootstrapTheme={bootstrapTheme} />
						<div className="font-weight-medium" style={{ paddingLeft: 5 }}>
							{applicationName}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
