export const invokeOnKeyPress =
	(keys: string[]) =>
	(callback: Function, preventDefault: boolean = true) =>
	(e: React.KeyboardEvent<HTMLElement>) => {
		if (keys.includes(e.key)) {
			callback();
			if (preventDefault === true) {
				e.preventDefault();
			}
		}
	};

export const onKeyEnterPressed = invokeOnKeyPress(['Enter']);
export const onKeyEnterOrSpacePressed = invokeOnKeyPress(['Enter', ' ']);
