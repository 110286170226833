import * as React from 'react';
import { Offcanvas } from 'reactstrap';
import { AllColorPresets, ColorPreset, ColorProfile } from 'Domain/report';
import { isEmpty, isNil } from 'lodash';
import { onKeyEnterOrSpacePressed } from 'Helpers/keyboardEventHelper';
interface ColorSelectorProps {
	headerTitle: string;
	selectedPreset: ColorPreset | undefined;
	onChange: (preset: ColorPreset) => void;
	errorMessage: string | undefined;
}

export const ColorSelector: React.FC<ColorSelectorProps> = ({ selectedPreset, onChange, headerTitle, errorMessage }) => {
	const [presetSelectorVisible, setPresetSelectorVisible] = React.useState(false);

	const hidePresetSelector = () => setPresetSelectorVisible(false);
	const showPresetSelector = () => setPresetSelectorVisible(true);
	const isValid = isNil(errorMessage) || isEmpty(errorMessage);
	const hasSelectedPreset = !isNil(selectedPreset);

	return (
		<>
			<div
				className={`input-group input-group-lg ${isValid === false ? 'custom-form-invalid' : ''}`}
				onClick={showPresetSelector}
				onKeyDown={onKeyEnterOrSpacePressed(showPresetSelector)}
				role="button"
				tabIndex={0}
			>
				{hasSelectedPreset && <span className="input-group-text text-truncate">{selectedPreset?.name}</span>}
				<div className={`form-control d-flex align-items-center pe-none ${hasSelectedPreset ? "justify-content-evenly" : ""}`}>
					{!hasSelectedPreset && (<span className='text-muted'>Please choose a preset ...</span>)}
					{hasSelectedPreset && (
						<>
							<ColorProfileBadge color={selectedPreset.accent1} badgeOnly={true} />
							<ColorProfileBadge color={selectedPreset.accent2} badgeOnly={true} />
							<ColorProfileBadge color={selectedPreset.accent3} badgeOnly={true} />
						</>
					)}

				</div>
				<div className="btn btn-discreet-primary">Select</div>
			</div>
			{errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>}

			<ColorPresetSelector
				headerTitle={headerTitle}
				selectedPreset={selectedPreset?.name ?? undefined}
				visible={presetSelectorVisible}
				onClose={hidePresetSelector}
				onChange={(p) => {
					onChange(p);
					hidePresetSelector();
				}}
			/>
		</>
	);
};

type ColorPresetSelectorProps = {
	headerTitle?: string;
	selectedPreset: string | undefined;
	visible: boolean;
	onClose: () => void;
	onChange: (preset: ColorPreset) => void;
};
const ColorPresetSelector: React.FC<ColorPresetSelectorProps> = ({ selectedPreset, visible, onClose, onChange, headerTitle }) => {
	const availablePresetColors = [...AllColorPresets];
	return (
		<Offcanvas backdrop={true} isOpen={visible} direction="end" toggle={onClose}>
			{visible && (
				<div className="d-flex h-100 flex-column">
					<div className="d-flex p-3">
						<h2 className="flex-fill">{headerTitle ?? 'Select your preset'}</h2>
						<button className="btn-close" onClick={onClose}></button>
					</div>
					<div className="flex-fill ">
						<div className="list-group list-group-flush" role={'list'}>
							{availablePresetColors.map((p) => {
								const onPresetClick = () => onChange(p);
								const isActive = p.name === selectedPreset;
								return (
									<div
										key={p.name}
										className={`list-group-item list-group-item-action list-group-item-primary cursor-pointer border ${isActive ? 'active' : ''
											}`}
										onClick={onPresetClick}
										tabIndex={0}
										onKeyDown={onKeyEnterOrSpacePressed(onPresetClick)}
										role="listbox"
									>
										<h3 className="mb-2">{p.name}</h3>
										<div className="d-flex align-items-center  ">
											<ColorProfileBadge color={p.accent1} label={'Accent1'} className="me-3" />
											<ColorProfileBadge color={p.accent2} label={'Accent2'} className="me-3" />
											<ColorProfileBadge color={p.accent3} label={'Accent3'} />
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="p-3">
						<button className="btn btn-primary btn-block" onClick={onClose}>
							Close
						</button>
					</div>
				</div>
			)}
		</Offcanvas>
	);
};
export const ColorProfileBadge: React.FC<{ color: ColorProfile; label?: string; className?: string; badgeOnly?: true }> = ({
	color,
	label,
	className,
	badgeOnly,
}) => {
	const $badge = (
		<span
			className={`badge rounded-pill text-light border border-black ${className ?? ""}`}
			style={{
				backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a ?? 255})`,
			}}
		>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span>
	);
	if (badgeOnly === true) {
		return $badge;
	}
	return (
		<div className={`d-flex ${className ?? ''}`}>
			<span className="pe-3">{label}</span>
			{$badge}
		</div>
	);
};
