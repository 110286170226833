import { AgGridReact } from "ag-grid-react";
import { DisclaimerForm } from "./DisclaimerForm";
import { isNil } from "lodash";
import { RowClassParams, RowClassRules } from "ag-grid-community";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResourcesSlice, { DisclaimerItem } from "state/Resources/Resources.state";
import { BrochureGeneratorAppState } from "Domain/report";
import { TextInput } from "components/Shared/FormControls/TextInput";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

const TypedGrid = AgGridReact<DisclaimerItem>;

export const DisclaimerSection: React.FC = () => {
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();
	const [selectedDisclaimer, setSelectedDisclaimer] = useState<DisclaimerItem | undefined>(undefined);
	const [searchTerm, setSearchTerm] = useState<string>("");

	useEffect(() => {
		dispatcher(ResourcesSlice.actions.loadDisclaimers());
	}, [dispatcher]);
	const isSelectedRowCallback = useCallback(
		(row: RowClassParams<DisclaimerItem>) => {
			return row.data?.id === selectedDisclaimer?.id;
		},
		[selectedDisclaimer],
	);

	const rowRules = useMemo(() => {
		const rules: RowClassRules<DisclaimerItem> = {
			"text-bg-info": isSelectedRowCallback,
		};
		return rules;
	}, [isSelectedRowCallback]);

	const onCreateNewClick = () => {
		setSelectedDisclaimer({ disclaimerId: "", content: "", description: "", id: undefined });
		analyticsMonitoring.functional("Disclaimers", "Create new disclaimer");
	};
	const disclaimers = useSelector((s: BrochureGeneratorAppState) => s.resources.allDisclaimers);
	return (
		<div className="row h-100 g-0">
			<div className="col-4 h-100 position-relative bg-lvl1 border-end border-primary">
				<div className="vstack h-100">
					<div className="row m-0 py-2">
						<div className="col">
							<TextInput value={searchTerm ?? ""} onChange={setSearchTerm} placeholder="Search Underlying ..." />
						</div>
						<div className="col-auto">
							<button className="btn btn-primary ms-auto" onClick={onCreateNewClick}>
								Create new
							</button>
						</div>
					</div>

					<div className="flex-fill">
						<div className="ag-theme-sg-bootstrap h-100">
							<TypedGrid
								rowData={disclaimers}
								onFirstDataRendered={(e) => {
									e.api.sizeColumnsToFit();
								}}
								columnDefs={[{ field: "disclaimerId", initialSort: "asc" }, { field: "description" }]}
								defaultColDef={{ flex: 1 }}
								rowHeight={42}
								headerHeight={42}
								rowSelection="single"
								quickFilterText={searchTerm}
								enableRangeSelection={false}
								onSelectionChanged={(e) => {
									const selection = e.api.getSelectedRows();
									if (selection.length === 1) {
										setSelectedDisclaimer(selection[0]);
									}
								}}
								suppressCopySingleCellRanges={true}
								rowClassRules={rowRules}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="col h-100">
				{selectedDisclaimer && (
					<DisclaimerForm
						disclaimer={selectedDisclaimer}
						isNew={isNil(selectedDisclaimer.id)}
						key={`disclaimer_form_${selectedDisclaimer.id}`}
						onClose={() => {
							setSelectedDisclaimer(undefined);
							analyticsMonitoring.functional("Disclaimers", "Cancel creation");
						}}
					/>
				)}
			</div>
		</div>
	);
};
