import { DurationInfo } from "Domain/report";
import { DurationInfoDto } from "Domain/reportDto";

function mapEntityToDto(entity: DurationInfo | undefined): DurationInfoDto {
	const dto: DurationInfoDto = {
		years: entity?.years ?? 0,
		months: entity?.months ?? 0,
		days: entity?.days ?? 0,
	};
	return dto;
}
function mapDtoToEntity(dto: DurationInfoDto | undefined): DurationInfo | undefined {
	if (dto) {
		const entity: DurationInfo = {
			years: dto.years ?? 0,
			months: dto.months ?? 0,
			days: dto.days ?? 0,
		};
		return entity;
	}
	return undefined;
}
export const DurationInfoMappers = {
	mapDtoToEntity,
	mapEntityToDto,
};
