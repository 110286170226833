import { isEqual } from 'lodash';
import * as React from 'react';
import { DefaultInputProps } from './DefaultInputProps';
import { DateTime } from 'luxon';
import { DateHelper } from './DateHelper';
interface DateListFormControlProps extends DefaultInputProps<Date[]> {}

const DateListFormControl: React.FC<DateListFormControlProps> = ({ initialValue, onChange, disabled, isValid }) => {
	const [localValue, setLocalValue] = React.useState<string>('');
	const [isValidated, setIsValidated] = React.useState<boolean>(true);
	const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter') {
			validateInputValue();
		}
	};

	const validateInputValue = () => {
		if (isValidated) return;
		const result = DateHelper.parseDates(localValue);
		if (!isEqual(result, initialValue)) {
			setLocalValue('');
			onChange(result);
		}
		setIsValidated(true);
	};

	const hasValue = initialValue.length > 0;
	return (
		<div className="d-flex flex-column">
			<input
				type={'text'}
				className="form-control"
				value={localValue}
				onBlur={validateInputValue}
				onKeyDown={onKeyDown}
				onChange={(e) => {
					setLocalValue(e.currentTarget.value);
					setIsValidated(false);
				}}
				disabled={disabled}
			/>

			{hasValue && (
				<div className="position-relative mt-3">
					<span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger me-n2 mt-n1">{initialValue.length}</span>
					<div className="d-flex flex-wrap bg-light-alpha-xl border px-2 pb-2 align-items-center" style={{ maxHeight: 100, overflowY: 'auto' }}>
						{initialValue.map((d, i) => {
							const dateFormatted = DateTime.fromJSDate(d).toLocaleString(DateTime.DATE_MED);
							return (
								<span className="badge bg-primary mt-2 me-2" key={`date${i}`}>
									{dateFormatted}
								</span>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default DateListFormControl;
